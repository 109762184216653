import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import Cell from "../../../components/layout/Cell";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import KorocanThumbnailIcerikId from "../../../components/business/KorocanThumbnailIcerikId";
import { MyText } from "../../../components/common/MyText";
import GenericGroupBox from "../../../components/common/GenericGroupBox";
import VatanNotaSlider from "../../../components/vatan/VatanNotaSlider";
import VatanSecmePuanSlider from "../../../components/vatan/VatanSecmePuanSlider";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { useScreenWidth } from "../../../components/core/useScreenWidth";
import StringHelper from "../../../helpers/StringHelper";

interface ISecmeSonucBilgiPopupDisplayerProps {
    secmeBasvuruModel: SecmeBasvuruModel,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: boolean | null) => void,
}

function TitlePair(_props: { title: string, value: string | number | null | undefined }) {
    const valueFormatted = StringHelper.emptyToDash(_props.value);
    return <Columns><MyText bold>{_props.title}:</MyText><MyText style={{ marginLeft: "5px" }}>{valueFormatted}</MyText></Columns>
}

function SecmeSonucBilgiPopupDisplayer(props: ISecmeSonucBilgiPopupDisplayerProps) {
    const { secmeBasvuruModel, closer, genelDurumHelper } = props;

    const secmeBasvuruSonucModel = secmeBasvuruModel.sonucModel!;

    const isVerySmall = useScreenWidth() === "Max600"

    // others

    const sliderWidth = isVerySmall ? 250 : 375;
    const bigTextBoxWidth = isVerySmall ? 250 : 300;

    const tayinEdilenSesGrubu = secmeBasvuruSonucModel.sonucSesGrup === null ? "" : genelDurumHelper.SeciliKoroModel.tumSesGruplari.single(e => e.id === secmeBasvuruSonucModel.sonucSesGrup).isim;

    return (
        <Rows style={{ display: "flex" }}>
            <GenericGroupBox label="Başvuru Bilgileri">
                <Columns wrap dontGrowForChild style={{ marginTop: 8 }}>
                    <Columns>
                        <KorocanThumbnailIcerikId
                            title={`${secmeBasvuruModel.ad} ${secmeBasvuruModel.soyad}`}
                            icerikId={secmeBasvuruModel.resimIcerikId}
                            saveToStorage="None"
                            genelDurumHelper={genelDurumHelper}
                            size="large" />
                        <Rows style={{ maxWidth: "250px", minWidth: "200px", marginLeft: "10px" }}>
                            <MyText header>{`${secmeBasvuruModel.ad} ${secmeBasvuruModel.soyad}`}</MyText>
                            <MyText note>{`${secmeBasvuruModel.eposta}`}</MyText>
                            <MyText>{`${EnmCinsiyet[secmeBasvuruModel.cinsiyet]} / ${secmeBasvuruModel.dogumYili}`}</MyText>
                            <TitlePair title="Ses Grubu" value={secmeBasvuruModel.bilinenSesGrubu} />
                        </Rows>
                    </Columns>
                    <Rows style={{ marginLeft: "10px" }}>
                        <TitlePair title="Telefon" value={secmeBasvuruModel.telefon} />
                        <TitlePair title="Meslek" value={secmeBasvuruModel.meslek} />
                        <TitlePair title="Eğitim Durumu" value={secmeBasvuruModel.egitimDurumu} />
                        <TitlePair title="Yaşadığı İl" value={secmeBasvuruModel.yasadigiIl} />
                    </Rows>
                    <Rows style={{ marginLeft: "10px" }}>
                        <span><b>Koro Tecrübesi</b>: {secmeBasvuruModel.koroTecrubesi}</span>
                        <span><b>Seçme Ön Notu</b>: {secmeBasvuruModel.secmeNotu}</span>
                    </Rows>
                </Columns>
            </GenericGroupBox>
            <GenericGroupBox label="Kulak Analizi">
                <Columns wrap style={{ marginTop: 8 }} dontGrowForChild>
                    <Rows style={{ marginLeft: "10px" }}>
                        <Columns hasLightBottomBorder width={sliderWidth} style={{ marginRight: "5px", marginBottom: "10px", paddingBottom: "15px", placeItems: "center" }}>
                            <Rows flex>
                                <MyText bold>Normal Melodi</MyText>
                                <VatanSecmePuanSlider value={secmeBasvuruSonucModel.normalMelodiPuan} allowNull disabled />
                            </Rows>
                            <Cell width={100} style={{ marginLeft: "15px", marginTop: "20px" }}>
                                <MyText header>{secmeBasvuruSonucModel.normalMelodiPuan?.toFixed(1) ?? "-"}</MyText>
                            </Cell>
                        </Columns>
                        <Columns width={sliderWidth} style={{ marginRight: "5px", marginBottom: "10px", placeItems: "center" }}>
                            <Rows flex>
                                <MyText bold>Arıza Melodi</MyText>
                                <VatanSecmePuanSlider value={secmeBasvuruSonucModel.arizaMelodiPuan} allowNull disabled />
                            </Rows>
                            <Cell width={100} style={{ marginLeft: "15px", marginTop: "20px" }}>
                                <MyText header>{secmeBasvuruSonucModel.arizaMelodiPuan?.toFixed(1) ?? "-"}</MyText>
                            </Cell>
                        </Columns>
                        <Columns hasLightBottomBorder width={sliderWidth} style={{ marginRight: "5px", marginBottom: "10px", paddingBottom: "15px", placeItems: "center" }}>
                            <Rows flex>
                                <MyText bold>İnce Nota</MyText>
                                <VatanNotaSlider disabled allowNull value={secmeBasvuruSonucModel.inceNota} cinsiyet={secmeBasvuruModel.cinsiyet} />
                            </Rows>
                            <Rows width={100} style={{ marginLeft: "15px", marginTop: "20px" }}>
                                <MyText header>{BusinessHelper.Nota.NotaIsimGetir(secmeBasvuruSonucModel.inceNota)} {BusinessHelper.Nota.NotaOktavGetir(secmeBasvuruSonucModel.inceNota)}</MyText>
                                <MyText note>{BusinessHelper.Nota.ZorlukAciklamaGetir(secmeBasvuruSonucModel.inceNota, secmeBasvuruModel.cinsiyet === EnmCinsiyet.Erkek)}</MyText>
                            </Rows>
                        </Columns>
                        <Columns hasLightBottomBorder width={sliderWidth} style={{ marginRight: "5px", marginBottom: "10px", paddingBottom: "15px", placeItems: "center" }}>
                            <Rows flex>
                                <MyText bold>Kalın Nota</MyText>
                                <VatanNotaSlider disabled allowNull value={secmeBasvuruSonucModel.kalinNota} cinsiyet={secmeBasvuruModel.cinsiyet} />
                            </Rows>
                            <Rows width={100} style={{ marginLeft: "15px", marginTop: "20px" }}>
                                <MyText header>{BusinessHelper.Nota.NotaIsimGetir(secmeBasvuruSonucModel.kalinNota)} {BusinessHelper.Nota.NotaOktavGetir(secmeBasvuruSonucModel.kalinNota)}</MyText>
                                <MyText note>{BusinessHelper.Nota.ZorlukAciklamaGetir(secmeBasvuruSonucModel.kalinNota, secmeBasvuruModel.cinsiyet === EnmCinsiyet.Erkek)}</MyText>
                            </Rows>
                        </Columns>

                    </Rows>
                    <Rows wrap style={{ maxWidth: "650px", marginLeft: "15px" }}>
                        <span><b>Teknik Detaylar: </b>{StringHelper.emptyToDash(secmeBasvuruSonucModel.teknikDetaylar)}<br /><br /></span>
                        <span><b>Diğer Detaylar: </b>{StringHelper.emptyToDash(secmeBasvuruSonucModel.digerDetaylar)}<br /><br /></span>
                        <span><b>Tayin Edilen Ses Grubu: </b>{StringHelper.emptyToDash(tayinEdilenSesGrubu)}<br /><br /></span>

                        <MyText bold>Sonuç</MyText>
                        {secmeBasvuruSonucModel.sonuc === true && <Rows>{appIcons.Zorluk.uygun_maxi}<MyText>Kazandı</MyText></Rows>}
                        {secmeBasvuruSonucModel.sonuc === false && <Rows>{appIcons.Zorluk.uygunDegil_maxi}<MyText>Kazanamadı</MyText></Rows>}
                        {secmeBasvuruSonucModel != null && secmeBasvuruSonucModel.sonuc === null && <Rows>{appIcons.Zorluk.bilinmiyor_maxi}<MyText>Belirsiz</MyText></Rows>}
                        {secmeBasvuruSonucModel === null && <Rows><MyText>Seçmelere Katılmadı</MyText></Rows>}


                    </Rows>
                </Columns>
            </GenericGroupBox>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="info" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Tamam")}</Button>
            </Columns>
        </Rows >
    );
}

export default SecmeSonucBilgiPopupDisplayer;
import * as React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    if (props.value === 0)
        return <></>;

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={25} variant="determinate" {...props} value={100} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    style={{ marginTop: "3px" }}
                    variant="subtitle1"
                    component="div"
                    color="text.secondary"
                    fontSize={9}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { KoroModel } from "../../models/businessModels/KoroModel";
import KorocanImage from "../business/KorocanImage";
import GenericListSelectField, { IGenericListSelectFieldProps } from "../common/GenericListSelectField";
import { MyText } from "../common/MyText";
import Cell from "../layout/Cell";
import Columns from "../layout/Columns";

export type IVatanKoroSelectFieldProps = Omit<IGenericListSelectFieldProps<KoroModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumKorolar: KoroModel[], genelDurumHelper: GenelDurumHelper, };

function VatanKoroSelectField(props: IVatanKoroSelectFieldProps) {
    const { tumKorolar, genelDurumHelper, ...rest } = props;
    const orderedKoroList = tumKorolar.orderByTurkish(e => e.isim);

    return (
        <GenericListSelectField
            {...rest}
            list={orderedKoroList}
            labelGetter={(e) => (
                <Columns dontGrowForChild style={{ alignItems: "center" }}>
                    <Cell width={100} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <KorocanImage style={{ height: "50px", boxShadow: "0px 0px 5px 1px #fff" }} title={e.isim} icerikId={e.resimIcerikId}  genelDurumHelper={genelDurumHelper} saveToStorage="FullSize" />
                    </Cell>
                    <Cell dontGrowForChild flex>
                        <MyText header style={{ whiteSpace: "normal", marginLeft: "20px" }}>{e.isim}</MyText>
                    </Cell>
                </Columns>
            )
            }
            valueGetter={(e) => e.id}
        />
    )
}

export default VatanKoroSelectField;
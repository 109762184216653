/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import JsonHelper from "../../../helpers/JsonHelper";
import { SezonSarkiModel } from "../../../models/businessModels/SezonSarkiModel";
import { MyText } from "../../../components/common/MyText";
import Cell from "../../../components/layout/Cell";
import VatanDatePicker from "../../../components/common/VatanDatePicker";

interface ISezonSarkiKayitTarihPopupEditorProps {
    sezonSarkiModelList: SezonSarkiModel[],
    genelDurumHelper: GenelDurumHelper,
    closer: (model: SezonSarkiModel[] | null) => void,
}

export default function SezonSarkiKayitTarihPopupEditor(props: ISezonSarkiKayitTarihPopupEditorProps) {
    const { sezonSarkiModelList, closer, genelDurumHelper } = props;

    const [list, setList] = useState<SezonSarkiModel[]>(JsonHelper.deepCopy(sezonSarkiModelList));

    const updateLinkModel = (item: SezonSarkiModel, action: (model: SezonSarkiModel) => void) => {
        const newList = JsonHelper.deepCopy(list);
        action(newList.single(e => e.id === item.id));
        setList(newList);
    }

    const tumSezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar;
    const orderedList = list.select(e => ({ item: e, sezon: tumSezonlar.single(t => t.id === e.sezonModelId) })).orderByDesc(e => e.sezon.isim).select(e => e.item);
    /*--*/

    return (
        <Rows>
            {orderedList.map(sezonSarki =>
                <Columns key={sezonSarki.id} width={400}>
                    <Cell width={150} style={{ margin: "10px", alignItems: "center", alignContent: "center" }}>
                        <MyText>{tumSezonlar.single(e => e.id === sezonSarki.sezonModelId).isim}</MyText>
                    </Cell>
                    <Cell flex style={{ margin: "10px" }}>
                        <VatanDatePicker value={sezonSarki.kayitTarihi} onChange={e => updateLinkModel(sezonSarki, t => t.kayitTarihi = e)} label={"Kayıt Son Tarihi"} canClean />
                    </Cell>
                </Columns>)}
            <Rows flex></Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => closer(list)} disabled={false} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

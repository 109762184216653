import { FormControl, SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { CSSProperties } from "react";
import { StringOrNumber } from "../../helpers/StringOrNumber";

export interface IFormSegmentedRadioButtonListProps<T, U extends StringOrNumber> {
    value: U | null,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    onChange: (e: U | null) => void,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
}

function FormSegmentedRadioButtonList<T, U extends StringOrNumber>(props: IFormSegmentedRadioButtonListProps<T, U>) {
    const { value, list, labelGetter, valueGetter, onChange, fontSize, sx, disabled, style } = props;
    const fontSizeToUse = fontSize ?? 14;
    const strings = (list.select(labelGetter));
    strings.push('');

    return (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <ToggleButtonGroup
                sx={sx}
                size="small"
                value={value}
                disabled={disabled}
                exclusive
                onChange={(e, v) => onChange(v)}
                aria-label="text alignment"
            >
                {list.map((item, index) => {
                    return (
                        <ToggleButton style={{ textTransform: "none"}} value={valueGetter(item)} key={index}>
                            <Typography fontSize={fontSizeToUse}>{labelGetter(item)}</Typography>
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </FormControl>
    );
}

export default FormSegmentedRadioButtonList;
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import AlertHelper from "../../../helpers/AlertHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import Columns from "../../../components/layout/Columns";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import OzgecmisGuncellemePopupEditor from "../../main/profil/OzgecmisGuncellemePopupEditor";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import OzelGrupluKisiAvatarListControl from "../../../components/business/OzelGrupluKisiAvatarListControl";
import StringHelper from "../../../helpers/StringHelper";

function OzgecmisYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <OzgecmisYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function OzgecmisYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  async function ozgecmisGuncelle(hesap: HesapBilgiVeKullaniciModel) {
    const response = await AlertHelper.ShowClosableContent<string>(genelDurumHelper.translate("Özgeçmiş Güncelle"), (closer) => (
      <OzgecmisGuncellemePopupEditor
        hesapId={hesap.id}
        ozgecmis={StringHelper.nullToEmpty(hesap.ozgecmis)}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response === null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        seciliKoro.hesapBilgiModel.ozgecmis = response!;
        const foundHesapOnList = seciliKoro.hesapBilgiList.singleOrDefault(e => e.id === seciliKoro.hesapBilgiModel.id);
        if (foundHesapOnList) foundHesapOnList.ozgecmis = response!
      });
  }

  const tumKoristHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e));
  const tumNonKoristHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => !BusinessHelper.Genel.KoristMi(e));
  const aktifHesaplar = tumKoristHesaplar.where(e => e.aktif);
  const pasifHesaplar = tumKoristHesaplar.where(e => !e.aktif);
  const digerAktifHesaplar = tumNonKoristHesaplar.where(e => e.aktif);
  // const digerPasifHesaplar = tumNonKoristHesaplar.where(e => !e.aktif);

  return (
    <MainLayout
      location={mainScreenLocations.konserYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>

        <VatanAccordionSimple title={"Aktif Koristler"}>
          <Rows>
            <Columns wrap>
              <SesGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumKoristHesaplar}
                gosterilecekHesaplar={aktifHesaplar}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                hesapColorizeMethod="Özgeçmiş"
                onClick={e => ozgecmisGuncelle(e)}
                genelDurumHelper={genelDurumHelper} />
            </Columns>
          </Rows>
        </VatanAccordionSimple>

        <VatanAccordionSimple title={"Eski Koristler"}>
          <Rows>
            <Columns wrap>
              <SesGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumKoristHesaplar}
                gosterilecekHesaplar={pasifHesaplar}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                hesapColorizeMethod="Özgeçmiş"
                onClick={e => ozgecmisGuncelle(e)}
                genelDurumHelper={genelDurumHelper} />
            </Columns>
          </Rows>
        </VatanAccordionSimple>

        <VatanAccordionSimple title={"Diğer Aktif Hesaplar"}>
          <Rows>
            <Columns wrap>
              <OzelGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumNonKoristHesaplar}
                gosterilecekHesaplar={digerAktifHesaplar}
                hesapColorizeMethod="Özgeçmiş"
                onClick={e => ozgecmisGuncelle(e)}
                genelDurumHelper={genelDurumHelper}
                hesapGrupGetir={_ => { return { id: "0" }; }}
                grupIsimGetir={_ => "Hepsi"}
                hesapGrupSortFunc={_ => 1} />
            </Columns>
          </Rows>
        </VatanAccordionSimple>

      </Rows>
    </MainLayout>
  );
}

export default OzgecmisYonetimScreen;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { GuidHelper } from "../../../helpers/GuidHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import Columns from "../../../components/layout/Columns";
import Cell from "../../../components/layout/Cell";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import { ICustomSwipeButton } from "../../../components/common/MyListItem";
import KorocanThumbnailIcerikId from "../../../components/business/KorocanThumbnailIcerikId";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import VatanTextField from "../../../components/common/VatanTextField";
import appColors from "../../../app/appColors";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { SecmeBasvuruSonucModel } from "../../../models/businessModels/SecmeBasvuruSonucModel";
import SecmeSonucPopupEditor from "./SecmeSonucPopupEditor";
import { IconButton } from "@mui/material";
import StringHelper from "../../../helpers/StringHelper";

function SecmeYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SecmeYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function SecmeYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);


  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  const tumBasvurular = genelDurumHelper.SeciliKoroModel.tumSecmeBasvurular.where(e => e.sezonModelId === seciliSezonId);

  const filteredBasvurular = searchFilter.length <= 2 ?
    tumBasvurular :
    tumBasvurular.where(e => includesSearchText(`${e.ad} ${e.soyad}`));

  /* -- END OF STANDARD -- */

  const secmeBasvuruRowsGetir = (basvuruList: SecmeBasvuruModel[]): JSX.Element | null => {
    if (basvuruList.length === 0)
      return null;

    const tumGunler = basvuruList.select(e => DateTimeHelper.toDateString(e.secmeTarihi)).distinct().orderBy(e => e);

    const contents = tumGunler.selectMany(gun => {
      const gununBasvurulari = basvuruList.where(e => DateTimeHelper.toDateString(e.secmeTarihi) === gun);
      const gununSaatleri = gununBasvurulari.select(e => DateTimeHelper.toTimeString(e.secmeTarihi)).distinct().orderBy(e => e);

      return gununSaatleri.map(gunSaat => {
        const saatinBasvurulari = gununBasvurulari.where(e => DateTimeHelper.toTimeString(e.secmeTarihi) === gunSaat);

        return <Rows key={`${gun}-${gunSaat}`}>
          <Columns wrap>
            {saatinBasvurulari.map(secmeBasvuru => {
              const rightButtons: ICustomSwipeButton[] = [];

              const sonucVar = secmeBasvuru.sonucModel != null;
              const sonuc = sonucVar && secmeBasvuru.sonucModel!.sonuc;
              const sonucRenk = !sonucVar ? undefined : sonuc === true ? appColors.LIGHTGREEN : sonuc === false ? appColors.LIGHTRED : appColors.LIGHTYELLOW;

              rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => secmeSonucEkleGuncelle(secmeBasvuru), text: "Güncelle" });

              const sesGrupBiliniyor = (StringHelper.nullToEmpty(secmeBasvuru.bilinenSesGrubu)) !== "" && secmeBasvuru.bilinenSesGrubu?.toLocaleLowerCase("tr-tr") !== "bilmiyorum";

              return <Columns hasLightBottomBorder width={400} key={secmeBasvuru.id} style={{ background: sonucRenk }}>
                <Columns>
                  <Cell style={{ placeContent: "center" }}>
                    <IconButton color="primary" onClick={() => secmeSonucEkleGuncelle(secmeBasvuru)} title="Güncelle" >
                      {appIcons.CrudIcons.guncelle}
                    </IconButton>
                  </Cell>
                  <KorocanThumbnailIcerikId title={`${secmeBasvuru.ad} ${secmeBasvuru.soyad}`} icerikId={secmeBasvuru.resimIcerikId} saveToStorage="None" genelDurumHelper={genelDurumHelper} size="normal" />
                  <Rows style={{ marginLeft: "5px"}}>
                    <MyText header>{`${secmeBasvuru.ad} ${secmeBasvuru.soyad}`}</MyText>
                    <Columns>
                      <MyText smallNote>Belirttiği Ses Grubu:</MyText>
                      <MyText style={{ marginLeft: "5px", color: sesGrupBiliniyor ? appColors.RED : undefined }} bold={sesGrupBiliniyor} smallNote>{secmeBasvuru.bilinenSesGrubu}</MyText>
                    </Columns>
                    <Columns>
                      <MyText smallNote style={{ marginRight: "15px" }} >{EnmCinsiyet[secmeBasvuru.cinsiyet]} / {secmeBasvuru.dogumYili}</MyText>
                      {sonucVar ?
                        sonuc === true ? <MyText smallNote bold>SONUÇ OLUMLU</MyText> :
                          sonuc === false ? <MyText smallNote bold>SONUÇ OLUMSUZ</MyText> :
                            <MyText smallNote bold>SONUÇ BELİRSİZ</MyText> : undefined
                      }
                    </Columns>
                  </Rows>
                </Columns>
              </Columns>
            })}
          </Columns>
        </Rows>
      });
    });

    return <Rows>
      {contents}
    </Rows>
  }

  const secmeSonucEkleGuncelle = async (model: SecmeBasvuruModel) => {
    if (model.sonucModel === null)
      secmeBasvuruEkle(model);
    else
      secmeBasvuruGuncelle(model);
  }

  const secmeBasvuruEkle = async (secmeBasvuruModel: SecmeBasvuruModel) => {
    const sonucModel = new SecmeBasvuruSonucModel();
    sonucModel.id = GuidHelper.NewGuid();

    sonucModel.inceNota = 0;
    sonucModel.kalinNota = 0;
    sonucModel.normalMelodiPuan = 0;
    sonucModel.arizaMelodiPuan = 0;
    sonucModel.digerDetaylar = "";
    sonucModel.sesKullanim = "";
    sonucModel.teknikDetaylar = "";
    sonucModel.sonucSesGrup = null;
    sonucModel.sonuc = null;
    sonucModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    sonucModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<SecmeBasvuruSonucModel>("Seçme Sonucu Ekle", (closer) => (
      <SecmeSonucPopupEditor
        secmeBasvuruModel={secmeBasvuruModel}
        secmeBasvuruSonucModel={sonucModel}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const basvuru = seciliKoro.tumSecmeBasvurular.single(e => e.id === secmeBasvuruModel.id);
        basvuru.sonucModel = response;
      });
  }

  const secmeBasvuruGuncelle = async (secmeBasvuruModel: SecmeBasvuruModel): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<SecmeBasvuruSonucModel>("Seçme Sonucu Güncelle", (closer) => (
      <SecmeSonucPopupEditor
        secmeBasvuruModel={secmeBasvuruModel}
        secmeBasvuruSonucModel={secmeBasvuruModel.sonucModel!}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const basvuru = seciliKoro.tumSecmeBasvurular.single(e => e.id === secmeBasvuruModel.id);
        basvuru.sonucModel = response;
      });
  }

  const tumGunlerVeSaatler = filteredBasvurular.select(e => e.secmeTarihi?.valueOf()).distinct().orderBy(e => e === undefined ? Number.MAX_SAFE_INTEGER : e);
  return (
    <MainLayout
      location={mainScreenLocations.secmeYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns wrap>
          <Cell style={{ marginLeft: "10px", marginTop: "10px" }}>
            <VatanTextField value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell style={{ marginLeft: "10px", marginTop: "10px" }}>
            <VatanSezonSelectField
              value={seciliSezonId}
              label={"Sezon"}
              tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
              onChange={e => setSeciliSezonId(e)} />
          </Cell>
          <Cell flex ></Cell>
        </Columns>
        <Rows style={{ marginTop: "10px" }}>
          {tumGunlerVeSaatler.map(gunVeSaat => {
            const tarih = gunVeSaat ? new Date(gunVeSaat) : null;
            const basvurular = filteredBasvurular.where(e => e.secmeTarihi?.valueOf() === gunVeSaat).orderByTurkish(e => `${e.ad} ${e.soyad}`);

            const saatTarihBaslik = !gunVeSaat ?
              "Tarih belirlenmeyenler" :
              `${DateTimeHelper.toMonthDayAndDayOfWeekString(tarih, genelDurumHelper.translate)} - ${DateTimeHelper.toTimeString(tarih)}`;

            const olumluSayi = basvurular.where(e => e.sonucModel?.sonuc === true).length;
            const olumsuzSayi = basvurular.where(e => e.sonucModel?.sonuc === false).length;
            const belirsizSayi = basvurular.where(e => e.sonucModel?.sonuc === null).length;

            const ekInfoList = [
              olumluSayi > 0 ? `${olumluSayi} Olumlu` : "",
              olumsuzSayi > 0 ? `${olumsuzSayi} Olumsuz` : "",
              belirsizSayi > 0 ? `${belirsizSayi} Belirsiz` : "",
            ].where(e => e !== "");

            let baslik = `${saatTarihBaslik} - ${basvurular.length} Aday`;
            if (ekInfoList.length > 0) {
              baslik = `${baslik} (${ekInfoList.join(", ")})`;
            }

            return <VatanAccordionSimple key={gunVeSaat ?? `=`} title={baslik}>
              {secmeBasvuruRowsGetir(basvurular)}
            </VatanAccordionSimple>
          }
          )}
        </Rows>
      </Rows>
    </MainLayout>
  );
}

export default SecmeYonetimScreen;

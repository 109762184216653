import Columns, { ColumnsCSSProperties } from "../layout/Columns";
import { MyText } from "../common/MyText";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import { AnketModel } from "../../models/businessModels/AnketModel";
import appColors from "../../app/appColors";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

interface IAnketAvatarListControlProps {
    anketler: AnketModel[],
    style?: ColumnsCSSProperties,
    genelDurumHelper: GenelDurumHelper,
}

function AnketAvatarListControl(props: IAnketAvatarListControlProps) {
    const getInnerContent = (anket: AnketModel) => {
        return (
            <div key={anket.id} style={{ borderRadius: 5, width: 165, padding: 10, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <MyText style={{ textAlign: "center", fontSize: 13 }}>{anket.baslik}</MyText>
                <MyText style={{ textAlign: "center" }} note small>{DateTimeHelper.toMonthDayAndYearAndDayOfWeekString(anket.tarihBaslangic, props.genelDurumHelper.translate)}</MyText>
            </div>
        );
    }

    return (
        <Columns wrap style={props.style}>
            {props.anketler.map(item => getInnerContent(item))}
        </Columns>
    )
}

export default AnketAvatarListControl;
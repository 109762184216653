import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import AlertHelper from "../../../helpers/AlertHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function KadroRaporScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KadroRaporScreenInner genelDurumHelper={genelDurumHelper} />
}

function KadroRaporScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const tumKullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e));
  const aktifKullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e) && e.aktif);

  const kadrolar = genelDurumHelper.KadrolariniGetir(aktifKullanicilar.selectMany(e => e.kadroVeSesGrubuBilgiModelList)).orderByDesc(e => e.aktif ? `_${e.isim}` : e.isim);
  const sezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar.orderByDesc(e => e.isim);

  function copyKadroAsText(hesaplar: HesapBilgiVeKullaniciModel[]): void {

    const bulunanTumSesGruplari = hesaplar.select(e => BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(genelDurumHelper.SesGruplariniGetir(e.sesGrupIdList)));
    const temelSesGruplari = bulunanTumSesGruplari.distinct().orderBy(e => e?.sira);

    const icerik = temelSesGruplari.where(e => !!e).select(e => e!.isim + "\n" + hesaplar.where(t => t.sesGrupIdList.contains(e!.id)).select(t => `${t.kullaniciModel.ad} ${t.kullaniciModel.soyad}`).join("\n")).join("\n\n");

    navigator.clipboard.writeText(icerik);
    AlertHelper.ShowMessage("Dikkat", "Kadro Kopyalandı")
  }

  // Main Result

  return (
    <MainLayout
      location={mainScreenLocations.kadroRapor}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows>
        {sezonlar.map(sezon => kadrolar.where(e => e.aktif && e.sezonModelId === sezon.id).map(kadro =>
          <VatanAccordionSimple
            style={{ flex: 1 }}
            key={kadro.isim}
            title={`${sezon.isim} - ${kadro.isim}`}
            buttons={[{ text: "Yazı Olarak Kopyala", action: () => copyKadroAsText(aktifKullanicilar.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, kadro.id))) }]}
            alwaysOpen>
            <Rows flex style={{ marginLeft: "10px", marginBottom: "10px" }}>
              <SesGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumKullanicilar}
                gosterilecekHesaplar={aktifKullanicilar.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, kadro.id))}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                hesapColorizeMethod="Durum"
                altSesGruplariniGoster
                thumbnailSize="normal"
                hesapSesGrupGetir={BusinessHelper.SesGrubu.KadroyaGoreSesGruplariniGetir(kadro.id, genelDurumHelper)}
                genelDurumHelper={genelDurumHelper} />
            </Rows>
          </VatanAccordionSimple>
        ))}
        {sezonlar.map(sezon => kadrolar.where(e => !e.aktif && e.sezonModelId === sezon.id).map(kadro =>
          <VatanAccordionSimple
            style={{ flex: 1 }}
            key={kadro.isim}
            title={`${sezon.isim} - ${kadro.isim}`}>
            <Rows flex style={{ marginLeft: "10px", marginBottom: "10px" }}>
              <SesGrupluKisiAvatarListControl
                darkSeperator
                tumHesaplar={tumKullanicilar}
                gosterilecekHesaplar={tumKullanicilar.where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, kadro.id))}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                hesapColorizeMethod="Durum"
                altSesGruplariniGoster
                thumbnailSize="normal"
                hesapSesGrupGetir={BusinessHelper.SesGrubu.KadroyaGoreSesGruplariniGetir(kadro.id, genelDurumHelper)}
                genelDurumHelper={genelDurumHelper} />
            </Rows>
          </VatanAccordionSimple>
        ))}        
      </Rows>
    </MainLayout>
  );
}

export default KadroRaporScreen;
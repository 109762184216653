/* eslint-disable @typescript-eslint/no-explicit-any */
import "./AudioMixer.css"
import React, { ChangeEvent, createRef, useEffect, useState } from 'react';
import appColors from '../../app/appColors';
import IcerikHelper from '../../helpers/IcerikHelper';
import { Box, Button, IconButton, Link } from '@mui/material';
import { appIcons } from '../../app/appIcons';
import Columns from '../layout/Columns';
import Rows from '../layout/Rows';
import GenelDurumHelper from '../../helpers/GenelDurumHelper';
import { EnmSarkiLinkTip } from '../../models/enums/EnmSarkiLinkTip';
import { SarkiLinkModel } from "../../models/businessModels/SarkiLinkModel";
import { MyText } from "../common/MyText";
import Cell from "../layout/Cell";
import { useObserveElementWidthGroup } from "../core/useObserveElementWidthGroup";
import VatanNumericField from "../common/VatanNumericField";
import StringHelper from "../../helpers/StringHelper";

export interface IAudioMixerProps {
	genelDurumHelper: GenelDurumHelper,
	sarkiId: string,
	autoloadAudio?: boolean,
}

class AudioContentItem {
	icerikId: string;
	base64Content: string | undefined;
	uzanti: string;

	constructor(icerikId: string, base64Icerik: string | undefined, uzanti: string) {
		this.icerikId = icerikId;
		this.base64Content = base64Icerik;
		this.uzanti = uzanti;
	}
}

type AudioMixerScreenSizes = "small" | "large";

function AudioMixer(props: IAudioMixerProps) {
	const sarki = props.genelDurumHelper.SeciliKoroModel.tumSarkilar.single(e => e.id === props.sarkiId);

	const { sizeName, ref } = useObserveElementWidthGroup<HTMLDivElement, AudioMixerScreenSizes>({ 500: "small", 10000: "large" });

	const isSmallScreen = sizeName === "small";

	const isMidiAdmin = (props.genelDurumHelper.SeciliKoroModel.koroVeHesapModelList?.length ?? 0) >= 1;
	const sesGrupGetir = (model: SarkiLinkModel) => props.genelDurumHelper.SesGruplariniGetir([model.sesGrupId!]).single();
	const sesGrupIsimGetir = (model: SarkiLinkModel) => sesGrupGetir(model).isim;

	const midiLinkler = sarki.sarkiLinkModelList.where(e => e.linkTip === EnmSarkiLinkTip['Midi Kaydı']).orderBy(e => sesGrupGetir(e).sira);

	const [collapsed, setCollapsed] = useState<boolean>(false);

	const [audioContents, setAudioContents] = useState<AudioContentItem[]>(midiLinkler.select(e => new AudioContentItem(e.icerikId!, undefined, IcerikHelper.IcerikMetaGetir(e.icerikId!).uzanti)));
	const [speed, setSpeed] = useState<number>(100);
	const [loop, setLoop] = useState<boolean>(true);
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [duration, setDuration] = useState<number>(0);
	const [loopStart, setLoopStart] = useState<number>(0);
	const [loopEnd, setLoopEnd] = useState<number>(0);
	const [enableLoopDetails, setEnableLoopDetails] = useState<boolean>(false);

	const loopTrimIsValid = loopEnd - loopStart > 2;

	async function getir(link: SarkiLinkModel) {
		const response = await props.genelDurumHelper.requestHelper.Genel.IcerikGetirDirect(link.icerikId!, IcerikHelper.IcerikMetaGetir(link.icerikId!).uzanti, sesGrupIsimGetir(link) + " Midi Kaydı Yükleniyor");
		const base64Content = !response.successfull ? "" : await IcerikHelper.GetBase64FromBlob(response.data!);
		return {
			link: link,
			base64Content: base64Content,
			successfull: response.successfull,
		};
	}

	const veriGetir = async () => {
		// içerik base64 ise, direkt bas ekrana

		const audioPromises = midiLinkler.select(e => getir(e));

		const webResponse = await Promise.all(audioPromises);

		if (!webResponse.all(e => e.successfull))
			return;

		setAudioContents(webResponse.select(e => new AudioContentItem(e.link.icerikId!, e.base64Content, IcerikHelper.IcerikMetaGetir(e.link.icerikId!).uzanti)));
	}

	const timeSliderInputRef = createRef<HTMLInputElement>();
	const durationDivRef = createRef<HTMLDivElement>();
	const durationSummaryDivRef = createRef<HTMLDivElement>();

	const audioRefList = midiLinkler.select(e => {
		const myRef = createRef<HTMLAudioElement>();
		return {
			midiLink: e,
			audioRef: myRef,
		}
	});

	const audioLoaded = audioContents.all(e => !!e.base64Content);

	useEffect(() => {
		if (props.autoloadAudio !== false)
			veriGetir();
	}, [props.sarkiId])

	// Button Clicks

    function openCloseLoop() {
        const newState = !loop
        setLoop(newState);
        if (!newState && loopDetails)
            setEnableLoopDetails(false);
    }

    function openCloseLoopDetails() {
        const newState = !enableLoopDetails
        setEnableLoopDetails(newState);
        if (newState && !loop)
            setLoop(true);
    }

	function rewind(): void {
		let currentTime = audioRefList.first().audioRef.current!.currentTime;
		currentTime -= 5;
		if (currentTime < 0) currentTime = 0;

		audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
		setCurrentSeekOnControls();
	}

	function forward(): void {
		let currentTime = audioRefList.first().audioRef.current!.currentTime;
		currentTime += 5;

		if (currentTime > audioRefList.first().audioRef.current!.duration) {
			if (!loop) {
				currentTime = duration;
				audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
				audioRefList.forEach(e => e.audioRef.current!.pause());
				setCurrentSeekOnControls();
				setIsPlaying(false);
			}
			else {
				currentTime = 0;
				audioRefList.forEach(e => e.audioRef.current!.currentTime = duration);
				setCurrentSeekOnControls();
			}
		}
		else {
			audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
			setCurrentSeekOnControls();
		}
	}

	async function playPause() {
		if (!audioRefList.any(e => e.audioRef.current?.readyState !== 0)) {
			await veriGetir();
			return;
		}
		if (isPlaying) {
			audioRefList.forEach(e => e.audioRef.current?.pause());
			let currentTime = audioRefList.first().audioRef.current!.currentTime;

			if (loop && enableLoopDetails && loopTrimIsValid && currentTime >= loopEnd)
				currentTime = loopStart;

			audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
			setIsPlaying(false);
		}
		else {
			// audioRefList.forEach(e => e.audioRef.current?.pause());
			// let currentTime = audioRefList.first().audioRef.current!.currentTime;

			// if (loop && showLoopDetails && loopTrimIsValid && currentTime >= loopEnd)
			// 	currentTime = loopStart;

			// audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
			audioRefList.forEach(e => {
				e.audioRef.current?.play();
			});
			setIsPlaying(true);
		}
	}

	// Slider Changes

	function playbackrateChange(curRate: number) {
		audioRefList.forEach(e => e.audioRef.current!.playbackRate = curRate / 100);
		setSpeed(curRate);
	}

	function audioSeekFromSlider(event: ChangeEvent<HTMLInputElement>): void {
		const currentTime = parseInt(event.target.value);

		audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
		durationDivRef.current!.innerText = getDurationStringFromSeconds(currentTime);
		if (durationSummaryDivRef.current)
			durationSummaryDivRef.current!.innerText = getDurationStringFromSeconds(currentTime);
	}

	function updateAudioVolume(audio: HTMLAudioElement, value: string): void {
		audio.volume = parseInt(value) / 100;
	}

	// audio events

	function checkRepeatAndReplayIfNecessary(): void {
		if (!loop) {
			setIsPlaying(false);
			return;
		}

		const currentTime = 0;
		audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
		durationDivRef.current!.innerText = getDurationStringFromSeconds(currentTime);
		if (durationSummaryDivRef.current)
			durationSummaryDivRef.current!.innerText = getDurationStringFromSeconds(currentTime);

		timeSliderInputRef.current!.value = currentTime.toString();
		audioRefList.forEach(e => e.audioRef.current!.play());
	}

	function setCurrentSeekOnControls(): void {
		let currentTime = audioRefList.first().audioRef.current!.currentTime;

		if (loop && enableLoopDetails && loopTrimIsValid && currentTime >= loopEnd) {
			currentTime = loopStart;
			audioRefList.forEach(e => e.audioRef.current!.pause());
			audioRefList.forEach(e => e.audioRef.current!.currentTime = currentTime);
			audioRefList.forEach(e => e.audioRef.current!.play());
		}

		durationDivRef.current!.innerText = getDurationStringFromSeconds(currentTime);
		if (durationSummaryDivRef.current)
			durationSummaryDivRef.current!.innerText = getDurationStringFromSeconds(currentTime);

		timeSliderInputRef.current!.value = currentTime.toString();
	}

	// Helper Functions

	function MyIconButton(props: { enabled: boolean, onClick: () => void, children?: React.ReactNode }) {
		return <Button disabled={!props.enabled} onClick={() => props.onClick()} style={{ padding: 0, minWidth: "42px" }}>{props.children}</Button>
	}

	function getDurationStringFromSeconds(duration: number): string {
		duration = Math.round(duration);
		return (Math.floor(duration / 60)).toString() + ":" + (duration % 60 < 10 ? "0" : "") + (duration % 60).toString();
	}

	// render

	const audioList = audioRefList.map(e => {

		const src = !audioLoaded ? undefined :
			IcerikHelper.GetAudioSourceFromBase64Content(
				audioContents.single(t => t.icerikId === e.midiLink.icerikId).base64Content!,
				IcerikHelper.IcerikMetaGetir(e.midiLink.icerikId!).uzanti);

		return <audio
			key={e.midiLink.icerikId}
			src={src}
			controls={false}
			loop={false}
			autoPlay={false}
			preload=""
			crossOrigin=""
			mediaGroup={props.sarkiId}
			ref={e.audioRef}
			onCanPlayThrough={() => { const dur = audioRefList.first().audioRef!.current!.duration; setDuration(dur); if (loopEnd === 0) setLoopEnd(dur); }}
			onEnded={() => checkRepeatAndReplayIfNecessary()}
			onTimeUpdate={() => setCurrentSeekOnControls()}
		/>
	}
	);

	const volumeControls = audioRefList.map(_audioRef => {
		const base64Content = IcerikHelper.GetAudioSourceFromBase64Content(StringHelper.nullToEmpty(audioContents.single(t => t.icerikId === _audioRef.midiLink.icerikId).base64Content), IcerikHelper.IcerikMetaGetir(_audioRef.midiLink.icerikId!).uzanti);
		const sesGrupIsim = sesGrupIsimGetir(_audioRef.midiLink);
		const calculatedFontSize =
			sesGrupIsim.length < 8 ? 13 :
				sesGrupIsim.length < 9 ? 11 :
					sesGrupIsim.length < 10 ? 10 :
						sesGrupIsim.length < 11 ? 8 :
							sesGrupIsim.length < 12 ? 6 :
								sesGrupIsim.length < 13 ? 8 : 7

		return (
			<Rows key={_audioRef.midiLink.icerikId} width={60}>
				<div className='mixerChannelContainer' style={{ alignSelf: "center" }}>
					<input
						disabled={!audioLoaded}
						defaultValue={100}
						key={_audioRef.midiLink.id}
						max={100}
						min={0}
						{...({ orient: 'vertical' } as any)}
						type='range'
						className='mixerChannelVolume mixerRange slider kirmizicubuk'
						onChange={(t) => updateAudioVolume(_audioRef.audioRef.current!, t.target.value)}
					/>
				</div>
				<div style={{ display: "flex", alignItems: "center", height: "16px", alignSelf: "center", fontSize: calculatedFontSize }}>
					<Link style={{ cursor: "pointer" }} onClick={() => !audioLoaded ? null : IcerikHelper.DownloadToClient(sarki.isim + " - " + sesGrupIsim + " midi kaydı" + IcerikHelper.IcerikMetaGetir(_audioRef.midiLink.icerikId!).uzanti, base64Content)}>{sesGrupIsim}</Link>
				</div>
			</Rows>
		);
	});

	const loopDetailsButton = <MyIconButton key="Tekrar Süreleri" enabled={audioLoaded} onClick={() => openCloseLoopDetails()} >{enableLoopDetails ? appIcons.Mixer.trimOnLarge : appIcons.Mixer.trimOffLarge}</MyIconButton>
	const loopDetails = <Columns style={{ marginTop: "10px", marginRight: "10px" }}>
		<VatanNumericField disabled={!audioLoaded || !loop || !enableLoopDetails} error={!loopTrimIsValid} style={{ width: "40px" }} title="Tekrar Başlangıç (sny)" onChange={e => setLoopStart(e ?? 0)} value={Math.floor(loopStart)} narrow />
		<VatanNumericField disabled={!audioLoaded || !loop || !enableLoopDetails} error={!loopTrimIsValid} style={{ width: "40px" }} InputProps={{ style: { paddingLeft: "5px !important" } }} title="Tekrar Bitiş (sny)" onChange={e => setLoopEnd(e ?? 0)} value={Math.floor(loopEnd)} narrow />
	</Columns>

	const repeatButton = <MyIconButton key="repeat" enabled={audioLoaded} onClick={() => openCloseLoop()}>{loop ? appIcons.Mixer.repeatOnLarge : appIcons.Mixer.repeatOffLarge}</MyIconButton>

	const playPauseButtons = [
		<MyIconButton key="backward" enabled={audioLoaded} onClick={() => rewind()}>{appIcons.Mixer.backward}</MyIconButton>,
		<MyIconButton key="playpause" enabled={true} onClick={() => playPause()}>{isPlaying ? appIcons.Mixer.pause : appIcons.Mixer.play}</MyIconButton>,
		<MyIconButton key="forward" enabled={audioLoaded} onClick={() => forward()}>{appIcons.Mixer.forward}</MyIconButton>];

	const durationBar = [
		<Cell key="duration_currenttext" width={45}><div style={{ marginTop: "8px", color: appColors.TEXT_PRIMARY }} ref={durationDivRef}>0:00</div></Cell>,
		<Cell key="duration_slider" flex><input disabled={!audioLoaded} ref={timeSliderInputRef} className='mixerDuration mixerRange slider kirmizicubuk' type='range' max={duration} onChange={audioSeekFromSlider} onInput={audioSeekFromSlider} /></Cell>,
		<Cell key="duration_totaltext" width={45}><MyText style={{ marginTop: "5px", marginLeft: "10px", color: appColors.TEXT_PRIMARY }}>{getDurationStringFromSeconds(duration)}</MyText></Cell>]

	const speedDetailsButton = <Cell key="speed_label" width={50}><IconButton title="Çalma Hızı" onClick={() => playbackrateChange(100)}>{appIcons.Mixer.speedOnLarge}</IconButton></Cell>
	const speedControls = <Columns flex style={{ paddingTop: "5px" }}>
		<Cell key="speed_slider" flex style={{ paddingLeft: "5px", paddingTop: "5px" }}><input disabled={!audioLoaded} className='mixerPlaybackRate mixerRange slider kirmizicubuk' type='range' min={50} max={!isMidiAdmin ? 150 : 200} step={10} value={speed} onChange={e => playbackrateChange(parseInt(e.target.value))} onInput={e => playbackrateChange(parseInt((e.target as any).value))} /></Cell>
		<Cell key="speed_text" width={45}><MyText style={{ marginTop: "9px", marginLeft: "10px", color: appColors.TEXT_PRIMARY }} note>x{(speed / 100).toFixed(1)}</MyText></Cell>
	</Columns>

	const summaryBar = <Columns height={audioLoaded ? 24 : 0} style={{ overflow: "hidden", marginLeft: "-5px", marginRight: "-5px", marginTop: "-5px", fontStyle: "italic", fontSize: "12px", color: appColors.TEXT_SECONDARY, fontWeight: "200" }}>
		<Columns flex style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, background: appColors.GRAY_LIGHT4, borderBottom: collapsed ? "1px solid gray" : undefined, overflow: "hidden" }} dontGrowForChild>
			<Columns style={{ marginLeft: "5px", marginTop: "4px", visibility: collapsed ? "visible" : "hidden" }}>
				<div style={{ whiteSpace: "nowrap" }}>Koro Mixer: {isPlaying ? "Çalıyor" : "Durdu"}, Süre&nbsp;</div>
				<div ref={durationSummaryDivRef}>0:00</div>
				<div style={{ whiteSpace: "nowrap" }}>/{getDurationStringFromSeconds(duration)}, hız: x{(speed / 100).toFixed(1)}, tekrar {loop ? "açık" : "kapalı"} </div>
			</Columns>
			<Cell flex></Cell>
			<IconButton onClick={() => setCollapsed(!collapsed)}>{collapsed ? appIcons.Genel.expandMore : appIcons.Genel.expandLess}</IconButton>
		</Columns>
	</Columns>

	// const speedBar = [
	// 	<Cell key="speed_label" width={30}><MyText style={{ marginTop: "5px", color: appColors.TEXT_PRIMARY }}>Hız</MyText></Cell>,
	// 	<Cell key="speed_slider" flex style={{ paddingTop: "2px" }}><input disabled={!audioLoaded} className='mixerPlaybackRate mixerRange slider kirmizicubuk' type='range' min={70} max={100} step={10} value={speed} onChange={e=> playbackrateChange(parseInt(e.target.value))} onInput={e=>playbackrateChange((e.target as any).value)} /></Cell>,
	// 	<Cell key="speed_text" width={50}><MyText style={{ marginTop: "6px", marginLeft: "10px", color: appColors.TEXT_PRIMARY }} note>x{(speed / 100).toFixed(1)}</MyText></Cell>]

	return <Box ref={ref} style={{ overflow: "hidden" }} height={collapsed ? 25 : undefined}>
		{audioList}
		<Columns style={{ border: "1px solid gray", borderRadius: 5, padding: 5 }}>
			<Rows flex style={{ alignSelf: "center" }}>
				{summaryBar}
				<Columns wrap>
					{volumeControls}
				</Columns>
				<Columns style={{ marginTop: "15px" }} dontGrowForChild>
					<Columns width={175}>
						<Columns style={{ borderRadius: "10px", paddingRight: "0px", marginRight: "5px", background: appColors.GRAY_LIGHT4 }}>
							{repeatButton}
							{loopDetailsButton}
							{loopDetails}
						</Columns>
					</Columns>
					<Cell flex></Cell>
					{isSmallScreen ? null :
						<Columns style={{ borderRadius: "10px", paddingRight: "0px", marginRight: "5px", background: appColors.GRAY_LIGHT4 }}>
							{playPauseButtons}
						</Columns>
					}
					<Cell flex></Cell>
					<Columns width={175} style={{ display: "flex", justifyContent: "flex-end" }}>
						<Columns style={{ borderRadius: "10px", paddingRight: "0px", marginRight: "5px", background: appColors.GRAY_LIGHT4 }}>
							{speedControls}
							{speedDetailsButton}
						</Columns>
					</Columns>
				</Columns>
				{!isSmallScreen ? null :
					<Columns style={{ marginTop: "15px" }} dontGrowForChild>
						<Columns flex style={{ borderRadius: "10px", paddingRight: "0px", marginRight: "5px", paddingTop: "5px", paddingBottom: "5px", background: appColors.GRAY_LIGHT4 }}>
							<Cell flex></Cell>
							{playPauseButtons}
							<Cell flex></Cell>
						</Columns>
					</Columns>
				}
				<Columns>
					{durationBar}
				</Columns>
			</Rows>
		</Columns>
	</Box>
}


/* function loadMixer(containerControl, mainName, songPartList) {
	songs = [];
	for(var key in songPartList)
	{
		songs[key] = new Audio();
		songs[key].src = songPartList[key][1];
		songs[key].preload = "none";
	}
		
	var mainDivName = "koroMixer_" + mainName;
	var mainControl = jQuery("<div id='" + mainDivName + "' class='mixerContainer'>\n" +
	  "    <div class='mixerChannelListContainer'></div>\n" + 
	  "    <div class='mixerControls'>\n" + 
	  "        <button class='mixerButton mixerLoad dashicons dashicons-format-audio' onClick='loadClick(this)'></button>\n" + 
	  "        <button class='mixerButton mixerStart dashicons dashicons-controls-play' onClick='startClick(this)' style='visibility: hidden'></button>\n" + 
	  "        <button class='mixerButton mixerStop dashicons dashicons-controls-pause' onClick='stopClick(this)' style='visibility: hidden'></button>\n" + 
	  "        <button class='mixerButton mixerPrev dashicons dashicons-controls-back' onClick='prevClick(this)' style='visibility: hidden'></button>\n" + 
	  "        <button class='mixerButton mixerNext dashicons dashicons-controls-forward' onClick='nextClick(this)' style='visibility: hidden'></button>\n" + 
	  "        <div class='mixerTimeContainer'><span class='mixerCurrentTime'>0:00</span>&nbsp;/&nbsp;<span class='mixerTotalTime'>0:00</span></div>\n" + 
	  "        <input type='range' class='mixerDuration mixerRange slider' value=0 oninput='durationChange(this)' onchange='durationChange(this)' />\n" + 
	  "        Hız: <input type='range' class='mixerPlaybackRate mixerRange slider' value=100 min=70 max=100 oninput='playbackrateChange(this)' onchange='playbackrateChange(this)' />\n" + 
	  "    </div>" + 
	  "" +
	  "</div>");	

	var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	for(var key in songPartList)
	{
		var controlToAdd;
		
		if (iOS)
			controlToAdd = jQuery(
			"        <div class='mixerChannelContainer' channelName='" + songPartList[key][0] + "'>\n" + 
			"            <input type='checkbox' max=100 value=100 oninput='channelChange(this)' onclick='channelOnOffChange(this)' checked disabled />\n" +
			"            <div class='mixerChannelName mixerChannelName" + key + "' style='background: lightcoral'><a href='" + songPartList[key][1] + "'>" + songPartList[key][0] + "</a></div>\n" +
			"        <div />\n");
		else
			controlToAdd = jQuery(
			"        <div class='mixerChannelContainer' channelName='" + songPartList[key][0] + "'>\n" + 
			"            <input type='range' orient='vertical' class='mixerChannelVolume mixerRange slider' max=100 value=100 oninput='channelChange(this)' onchange='channelChange(this)' />\n" +
			"            <div class='mixerChannelName mixerChannelName" + key + "' style='background: lightcoral'><a href='" + songPartList[key][1] + "'>" + songPartList[key][0] + "</a></div>\n" +
			"        <div />\n");
		
		jQuery(mainControl).find(".mixerChannelListContainer").append(controlToAdd);
		controlToAdd.data("song",songs[key]);
	}	
	
	jQuery(containerControl).append(mainControl);
	mainControl.data("songList", songs);
	
	for(var key in songs) { // şarkılar yüklenince süreleri yazsın
		songs[key].oncanplaythrough = (function(givenSongs, givenMainControl) { // onloadeddata
			return function() {
				setupSongDurationsAndEvents(givenSongs, givenMainControl);
					
			};
		})(songs, mainControl);
	}		
}

function setupSongDurationsAndEvents(givenSongs, givenMainControl)
{
	var allOk = true;
	for(var key in givenSongs)
	{
		if (givenSongs[key].readyState != 4) 
			allOk = false;

		if (givenSongs[key].readyState != 4) 
			givenMainControl.find(".mixerChannelName" + key).css("background","lightcoral");	
		else
			givenMainControl.find(".mixerChannelName" + key).css("background","lightgreen");	
	}
		
	if (allOk == true)
	{
		var duration = parseInt(givenSongs[0].duration);
		givenMainControl.find(".mixerDuration").attr('max',parseInt(givenSongs[0].duration));
		var myText = (parseInt(duration / 60)).toString() + ":" + (duration % 60 < 10 ? "0" : "") + (duration % 60).toString();
		givenMainControl.find(".mixerTotalTime").text(myText);					
	
		givenMainControl.find(".mixerLoad").css("visibility","hidden");
		givenMainControl.find(".mixerStart").css("visibility","");
		givenMainControl.find(".mixerStop").css("visibility","");
		givenMainControl.find(".mixerPrev").css("visibility","");
		givenMainControl.find(".mixerNext").css("visibility","");

		givenSongs[0].addEventListener('timeupdate', (function(givenSongs, givenMainControl) {
			return function() {
				curtime = parseInt(givenSongs[0].currentTime);
				var myText = (parseInt(curtime / 60)).toString() + ":" + (curtime % 60 < 10 ? "0" : "") + (curtime % 60).toString();
				givenMainControl.find(".mixerCurrentTime").text(myText);	
				givenMainControl.find(".mixerDuration").val(curtime);
			};
		})(givenSongs,givenMainControl));

		givenSongs[0].addEventListener('ended', (function(givenSongs, givenMainControl) {
			return function() {
				if (givenSongs[0].currentTime == givenSongs[0].duration)
				{
					jQuery.each( givenSongs, function( key, value ) { value.currentTime = 0; value.play(); });
				}
			};	
		})(givenSongs,givenMainControl));
	}				
}

function loadClick(e) {
	var mySongs = jQuery(e).closest(".mixerContainer").data("songList");
	jQuery(e).css("visibility","hidden");
	
	var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	if (iOS)
		jQuery.each( mySongs, function( key, value ) { value.play();  value.pause(); });
	else 
		jQuery.each( mySongs, function( key, value ) { value.preload = "auto"; });

}


function prevClick(e) {
	var mySongs = jQuery(e).closest(".mixerContainer").data("songList");
	destTime = mySongs[0].currentTime - 10;
	if (destTime < 0)
		destTime = 0;
	jQuery.each( mySongs, function( key, value ) { value.currentTime = destTime; });
}


function nextClick(e) {
	var mySongs = jQuery(e).closest(".mixerContainer").data("songList");
	destTime = mySongs[0].currentTime + 10;
	if (destTime > mySongs[0].duration)
		destTime = 0;
	jQuery.each( mySongs, function( key, value ) { value.currentTime = destTime; });
}

function startClick(e) {
   var mySongs = jQuery(e).closest(".mixerContainer").data("songList");
	
	var firstDuration = mySongs[0].duration;
	jQuery.each( mySongs, function( key, value ) { value.duration = firstDuration; });
	jQuery.each( mySongs, function( key, value ) { value.play(); });
}

function stopClick(e) {
		var mySongs = jQuery(e).closest(".mixerContainer").data("songList");
	jQuery.each( mySongs, function( key, value ) { value.pause(); });
}

function channelOnOffChange(e) {
	var mySong = jQuery(e).closest(".mixerChannelContainer").data("song");

	if (jQuery(e)[0].checked == true)
		mySong.muted = false;
	else
		mySong.muted = true;
}

function channelChange(e) {
	var mySong = jQuery(e).closest(".mixerChannelContainer").data("song");
	mySong.volume = e.value / 100;
}

function durationChange(e) {
		var mySongs = jQuery(e).closest(".mixerContainer").data("songList");
	var curTime = e.value;
	jQuery.each( mySongs, function( key, value ) { value.currentTime = curTime; });
}

function playbackrateChange(e) {
		var mySongs = jQuery(e).closest(".mixerContainer").data("songList");
	var curRate = e.value;
	jQuery.each( mySongs, function( key, value ) { value.playbackRate = curRate / 100; });
}
*/

export default AudioMixer;
import React, { useState } from "react";
import AlertHelper from "../../helpers/AlertHelper";
import ClosableCustomContentDialog, { IClosableCustomContentDialogProps } from "./ClosableCustomContentDialog";
import CustomAlertDialog, { ICustomAlertDialogProps } from "./CustomAlertDialog";
import CustomContentDialog, { ICustomContentDialogProps } from "./CustomContentDialog";
import CustomTextInputDialog, { ICustomTextInputDialogProps } from "./CustomTextInputDialog";
import CustomYesNoDialog, { ICustomYesNoDialogProps } from "./CustomYesNoDialog";
import CustomChoicesDialog, { ICustomChoicesDialogProps } from "./CustomChoicesDialog";
import ClosableCustomContentDialog2 from "./ClosableCustomContentDialog2";

export default function DialogDisplayers() {
  const [customAlertDialogProps, setCustomAlertDialogProps] = useState<ICustomAlertDialogProps>(
    {
      contentMessage: "",
      open: false,
      titleMessage: "",
      onClose: null,
    });

  const [customYesNoDialogProps, setCustomYesNoDialogProps] = useState<ICustomYesNoDialogProps>(
    {
      contentMessage: "",
      open: false,
      titleMessage: "",
      onClose: null,
      onYesClick: null,
    });
    
  const [customChoicesDialogProps, setCustomChoicesDialogProps] = useState<ICustomChoicesDialogProps>(
    {
      contentMessage: "",
      open: false,
      titleMessage: "",
      choices: [],
      onClose: null,
      onChoiceClick: null,
    });

  const [customTextInputDialogProps, setCustomTextInputDialogProps] = useState<ICustomTextInputDialogProps>(
    {
      contentMessage: "",
      open: false,
      titleMessage: "",
      onClose: null,
      onYesClick: null,
      isMultiLine: false,
    });

  const [customContentDialogProps, setCustomContentDialogProps] = useState<ICustomContentDialogProps>(
    {
      content: null,
      open: false,
      titleMessage: "",
      onClose: null,
    });

  const [closableCustomContentDialogProps, setClosableCustomContentDialogProps] = useState<IClosableCustomContentDialogProps>(
    {
      content: null,
      open: false,
      titleMessage: "",
      onClose: null,
      extraFullHeight: false,
    });

  const [closableCustomContentDialogProps2, setClosableCustomContentDialogProps2] = useState<IClosableCustomContentDialogProps>(
    {
      content: null,
      open: false,
      titleMessage: "",
      onClose: null,
      extraFullHeight: false,
    });

  AlertHelper.setCustomAlertDialogPropsMethod = setCustomAlertDialogProps;
  AlertHelper.setCustomYesNoDialogPropsMethod = setCustomYesNoDialogProps;
  AlertHelper.setCustomChoicesDialogPropsMethod = setCustomChoicesDialogProps;
  AlertHelper.setCustomTextInputDialogPropsMethod = setCustomTextInputDialogProps;
  AlertHelper.setCustomContentDialogPropsMethod = setCustomContentDialogProps;
  AlertHelper.setClosableContentDialogPropsMethod = setClosableCustomContentDialogProps;
  AlertHelper.setClosableContentDialogPropsMethod2 = setClosableCustomContentDialogProps2;

  return (
    <React.Fragment>
      <CustomAlertDialog
        titleMessage={customAlertDialogProps.titleMessage}
        contentMessage={customAlertDialogProps.contentMessage}
        open={customAlertDialogProps.open}
        onClose={customAlertDialogProps.onClose}
      />
      <CustomYesNoDialog
        titleMessage={customYesNoDialogProps.titleMessage}
        contentMessage={customYesNoDialogProps.contentMessage}
        open={customYesNoDialogProps.open}
        onClose={customYesNoDialogProps.onClose}
        onYesClick={customYesNoDialogProps.onYesClick}
      />
       <CustomChoicesDialog
        titleMessage={customChoicesDialogProps.titleMessage}
        contentMessage={customChoicesDialogProps.contentMessage}
        open={customChoicesDialogProps.open}
        onClose={customChoicesDialogProps.onClose}
        onChoiceClick={customChoicesDialogProps.onChoiceClick}
        choices={customChoicesDialogProps.choices}
      />
      <CustomTextInputDialog
        titleMessage={customTextInputDialogProps.titleMessage}
        contentMessage={customTextInputDialogProps.contentMessage}
        open={customTextInputDialogProps.open}
        onClose={customTextInputDialogProps.onClose}
        onYesClick={customTextInputDialogProps.onYesClick}
        isMultiLine={customTextInputDialogProps.isMultiLine}
      />
      <CustomContentDialog
        titleMessage={customContentDialogProps.titleMessage}
        content={customContentDialogProps.content}
        open={customContentDialogProps.open}
        onClose={customContentDialogProps.onClose}
      />
      <ClosableCustomContentDialog
        titleMessage={closableCustomContentDialogProps.titleMessage}
        content={closableCustomContentDialogProps.content}
        open={closableCustomContentDialogProps.open}
        onClose={closableCustomContentDialogProps.onClose}
        extraFullHeight={closableCustomContentDialogProps.extraFullHeight}
      />
      <ClosableCustomContentDialog2
        titleMessage={closableCustomContentDialogProps2.titleMessage}
        content={closableCustomContentDialogProps2.content}
        open={closableCustomContentDialogProps2.open}
        onClose={closableCustomContentDialogProps2.onClose}
        extraFullHeight={closableCustomContentDialogProps2.extraFullHeight}
      />      
    </React.Fragment>
  );
}

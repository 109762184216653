/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import VatanTextField from "../../../components/common/VatanTextField";
import JsonHelper from "../../../helpers/JsonHelper";
import Cell from "../../../components/layout/Cell";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import { SecmeBasvuruSonucModel } from "../../../models/businessModels/SecmeBasvuruSonucModel";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import KorocanThumbnailIcerikId from "../../../components/business/KorocanThumbnailIcerikId";
import { MyText } from "../../../components/common/MyText";
import VatanSesGrupSelectField from "../../../components/vatan/VatanSesGrupSelectField";
import { SecmeBasvuruSonucRequestModel } from "../../../models/requestModels/SecmeBasvuruSonucRequestModel";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import GenericGroupBox from "../../../components/common/GenericGroupBox";
import VatanNotaSlider from "../../../components/vatan/VatanNotaSlider";
import VatanSecmePuanSlider from "../../../components/vatan/VatanSecmePuanSlider";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import FormSegmentedRadioButtonList from "../../../components/common/FormSegmentedRadioButtonList";
import appColors from "../../../app/appColors";
import { useScreenWidth } from "../../../components/core/useScreenWidth";
import StringHelper from "../../../helpers/StringHelper";

interface ISecmeSonucPopupEditorProps {
    secmeBasvuruModel: SecmeBasvuruModel,
    secmeBasvuruSonucModel: SecmeBasvuruSonucModel,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: SecmeBasvuruSonucModel | null) => void,
}

function TitlePair(_props: { title: string, value: string | number | null | undefined }) {
    const valueFormatted = StringHelper.emptyToDash(_props.value);
    return <Columns><MyText bold>{_props.title}:</MyText><MyText style={{ marginLeft: "5px" }}>{valueFormatted}</MyText></Columns>
}

function SecmeSonucPopupEditor(props: ISecmeSonucPopupEditorProps) {
    const { secmeBasvuruModel, closer, guncellemeMi, genelDurumHelper } = props;

    const [secmeBasvuruSonucModel, setSecmeBasvuruSonucModel] = useState<SecmeBasvuruSonucModel>(JsonHelper.deepCopy(props.secmeBasvuruSonucModel));
    const isVerySmall = useScreenWidth() === "Max600"

    // update methods

    const updateModel = (action: (model: SecmeBasvuruSonucModel) => void) => {
        const result = JsonHelper.deepCopy(secmeBasvuruSonucModel);
        action(result);
        setSecmeBasvuruSonucModel(result);
    }

    // others

    const kaydet = async () => {
        const reqModel = new SecmeBasvuruSonucRequestModel(secmeBasvuruSonucModel, secmeBasvuruModel.id);

        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Secme.SonucGuncelle(reqModel);
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Secme.SonucEkle(reqModel);
            if (!webResponse.successfull)
                return;
        }

        closer(secmeBasvuruSonucModel);
    }

    const sliderWidth = 280; //isVerySmall ? 250 : 375;
    const bigTextBoxWidth = 250;

    const inceNotaColumns = <Columns style={{ placeItems: "center" }}>
        <Rows flex>
            <MyText>İnce Nota</MyText>
            <VatanNotaSlider
                value={secmeBasvuruSonucModel.inceNota}
                cinsiyet={secmeBasvuruModel.cinsiyet}
                onChange={e => updateModel(t => t.inceNota = e)} />
        </Rows>
        <Rows width={100} style={{ marginLeft: "15px", marginTop: "20px" }}>
            <MyText header>{BusinessHelper.Nota.NotaIsimGetir(secmeBasvuruSonucModel.inceNota)} {BusinessHelper.Nota.NotaOktavGetir(secmeBasvuruSonucModel.inceNota)}</MyText>
            <MyText note>{BusinessHelper.Nota.ZorlukAciklamaGetir(secmeBasvuruSonucModel.inceNota, secmeBasvuruModel.cinsiyet === EnmCinsiyet.Erkek)}</MyText>
        </Rows>
    </Columns>

    const kalinNotaColumn = <Columns style={{ placeItems: "center" }}>
        <Rows flex>
            <MyText>Kalın Nota</MyText>
            <VatanNotaSlider
                value={secmeBasvuruSonucModel.kalinNota}
                cinsiyet={secmeBasvuruModel.cinsiyet}
                onChange={e => updateModel(t => t.kalinNota = e)} />
        </Rows>
        <Rows width={100} style={{ marginLeft: "15px", marginTop: "20px" }}>
            <MyText header>{BusinessHelper.Nota.NotaIsimGetir(secmeBasvuruSonucModel.kalinNota)} {BusinessHelper.Nota.NotaOktavGetir(secmeBasvuruSonucModel.kalinNota)}</MyText>
            <MyText note>{BusinessHelper.Nota.ZorlukAciklamaGetir(secmeBasvuruSonucModel.kalinNota, secmeBasvuruModel.cinsiyet === EnmCinsiyet.Erkek)}</MyText>
        </Rows>
    </Columns>

    const normalMelodiColumn = <Columns width={sliderWidth} style={{ paddingBottom: "15px", placeItems: "center" }}>
        <Rows flex style={{ marginLeft: "10px"}}>
            <MyText>Normal Melodi</MyText>
            <VatanSecmePuanSlider value={secmeBasvuruSonucModel.normalMelodiPuan}
                allowNull
                onChange={e => updateModel(t => t.normalMelodiPuan = e)} />
        </Rows>
        <Cell width={50} style={{ marginLeft: "15px", marginTop: "20px" }}>
            <MyText header>{secmeBasvuruSonucModel.normalMelodiPuan?.toFixed(1) ?? "-"}</MyText>
        </Cell>
    </Columns>

    const arizaMelodiColumn = <Columns width={sliderWidth} style={{ paddingBottom: "15px", placeItems: "center" }}>
        <Rows flex style={{ marginLeft: "10px"}}>
            <MyText>Arıza Melodi</MyText>
            <VatanSecmePuanSlider value={secmeBasvuruSonucModel.arizaMelodiPuan}
                allowNull
                onChange={e => updateModel(t => t.arizaMelodiPuan = e)} />
        </Rows>
        <Cell width={50} style={{ marginLeft: "15px", marginTop: "20px" }}>
            <MyText header>{secmeBasvuruSonucModel.arizaMelodiPuan?.toFixed(1) ?? "-"}</MyText>
        </Cell>
    </Columns>

    const teknikDetayInput = <VatanTextField
        rows={4}
        multiline
        showStats
        fullWidth
        label="Teknik Detaylar"
        value={secmeBasvuruSonucModel.teknikDetaylar}
        appendMaxlenOnLabel={4000}
        onChange={e => updateModel(t => t.teknikDetaylar = e)} />

    const digerDetayInput = <VatanTextField
        rows={4}
        multiline
        showStats
        fullWidth
        label="Diğer Detaylar"
        value={secmeBasvuruSonucModel.digerDetaylar}
        appendMaxlenOnLabel={4000}
        onChange={e => updateModel(t => t.digerDetaylar = e)} />

    const sonucComboInput = <VatanSesGrupSelectField
        isSesGrupDisabled={e => e.sesGrupTip !== EnmSesGrupTip["Alt Ses Grubu"]}
        value={secmeBasvuruSonucModel.sonucSesGrup}
        label={"Sonuç Ses Grubu"}
        tumSesGruplari={genelDurumHelper.SeciliKoroModel.tumSesGruplari}
        onChange={e => updateModel(t => t.sonucSesGrup = e)} />

    const sonucRadioInput = <FormSegmentedRadioButtonList
        style={{ width: "238px", background: secmeBasvuruSonucModel.sonuc === true ? appColors.LIGHTGREEN : secmeBasvuruSonucModel.sonuc === false ? appColors.LIGHTRED : appColors.LIGHTYELLOW }}
        value={BusinessHelper.Nullable.NullableBoolToNumber(secmeBasvuruSonucModel.sonuc)}
        list={BusinessHelper.Nullable.NullableBoolValueList}
        labelGetter={e => BusinessHelper.Nullable.NullableBoolNumberValueSelector(e, "BELİRSİZ", "OLUMSUZ", "OLUMLU")}
        valueGetter={e => e}
        onChange={e => updateModel(t => t.sonuc = BusinessHelper.Nullable.NumberToNullableBool(e))} />

    return (
        <Rows style={{ display: "flex" }}>
            <GenericGroupBox label="Başvuru Bilgileri">
                <Columns wrap dontGrowForChild style={{ marginTop: 8 }}>
                    <Columns>
                        <KorocanThumbnailIcerikId
                            title={`${secmeBasvuruModel.ad} ${secmeBasvuruModel.soyad}`}
                            icerikId={secmeBasvuruModel.resimIcerikId}
                            saveToStorage="None"
                            genelDurumHelper={genelDurumHelper}
                            size="large" />
                        <Rows style={{ maxWidth: "250px", minWidth: "200px", marginLeft: "10px" }}>
                            <MyText header>{`${secmeBasvuruModel.ad} ${secmeBasvuruModel.soyad}`}</MyText>
                            <MyText note>{`${secmeBasvuruModel.eposta}`}</MyText>
                            <MyText>{`${EnmCinsiyet[secmeBasvuruModel.cinsiyet]} / ${secmeBasvuruModel.dogumYili}`}</MyText>
                            <TitlePair title="Ses Grubu" value={secmeBasvuruModel.bilinenSesGrubu} />
                        </Rows>
                    </Columns>
                    <Rows style={{ marginLeft: "10px" }}>
                        <TitlePair title="Telefon" value={secmeBasvuruModel.telefon} />
                        <TitlePair title="Meslek" value={secmeBasvuruModel.meslek} />
                        <TitlePair title="Eğitim Durumu" value={secmeBasvuruModel.egitimDurumu} />
                        <TitlePair title="Yaşadığı İl" value={secmeBasvuruModel.yasadigiIl} />
                    </Rows>
                    <Rows style={{ marginLeft: "10px" }}>
                        <span><b>Koro Tecrübesi</b>: {secmeBasvuruModel.koroTecrubesi}</span>
                        <span style={{marginTop: "5px"}}><b>Seçme Ön Notu</b>: {secmeBasvuruModel.secmeNotu}</span>
                    </Rows>
                </Columns>
            </GenericGroupBox>
            <GenericGroupBox label="Kulak Analizi">
                <Rows style={{ marginTop: 8, marginLeft: "15px" }}>
                    <Columns wrap>
                        <Rows flex style={{ minWidth: "350px" }}>
                            <Cell hasLightBottomBorder style={{ marginBottom: "10px", paddingLeft: "15px", paddingBottom: "10px", marginRight: "15px" }}>
                                {inceNotaColumns}
                            </Cell>
                            <Cell hasLightBottomBorder style={{ marginBottom: "10px", paddingLeft: "15px", paddingBottom: "10px", marginRight: "15px" }}>
                                {kalinNotaColumn}
                            </Cell>
                        </Rows>
                        <Rows>
                            <Cell hasLightBottomBorder style={{ marginBottom: "10px", paddingBottom: "15px" }}>
                                {normalMelodiColumn}
                            </Cell>
                            <Cell hasLightBottomBorder style={{ marginBottom: "10px", paddingBottom: "15px" }}>
                                {arizaMelodiColumn}
                            </Cell>
                        </Rows>
                    </Columns>
                    <Columns wrap>
                        <Cell flex style={{ minWidth: bigTextBoxWidth, marginRight: "5px", marginBottom: "10px" }}>
                            {teknikDetayInput}
                        </Cell>
                        <Cell flex style={{ minWidth: bigTextBoxWidth, marginRight: "5px", marginBottom: "10px" }}>
                            {digerDetayInput}
                        </Cell>
                        <Rows width={sliderWidth}>
                            <Cell style={{ marginRight: "2px", marginBottom: "2px", marginLeft: "2px" }}>
                                {sonucComboInput}
                            </Cell>
                            <GenericGroupBox label="Sonuç" style={{ marginTop: "-2px" }}>
                                <Rows style={{ alignItems: "center" }}>
                                    {sonucRadioInput}
                                </Rows>
                            </GenericGroupBox>
                        </Rows>
                    </Columns>
                </Rows>
                {/* <Columns wrap style={{ marginTop: 8 }} dontGrowForChild>
                    <Rows style={{ marginLeft: "10px", flexGrow: 1 }}>
                        
                        
                    </Rows>
                    <Columns wrap style={{ maxWidth: "450px", marginLeft: "15px" }}>
                        <Cell width={bigTextBoxWidth} style={{ marginRight: "5px", marginBottom: "10px" }}>
                            {teknikDetayInput}
                        </Cell>
                        <Cell width={bigTextBoxWidth} style={{ marginRight: "5px", marginBottom: "10px" }}>
                            {digerDetayInput}
                        </Cell>
                        <Columns width={bigTextBoxWidth}>
                            <Cell flex style={{ marginRight: "2px", marginBottom: "2px", marginLeft: "2px" }}>
                                {sonucComboInput}
                            </Cell>
                            <GenericGroupBox label="Sonuç" style={{ marginTop: "-7px" }}>
                                <Rows>
                                    {sonucRadioInput}
                                </Rows>
                            </GenericGroupBox>
                        </Columns>
                    </Columns>
                </Columns> */}
            </GenericGroupBox>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );
}

export default SecmeSonucPopupEditor;
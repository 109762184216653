import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import StringHelper from "../../helpers/StringHelper";
import VatanFreeAutoCompleteField, { IVatanFreeAutoCompleteFieldProps } from "../common/VatanFreeAutoCompleteField";

export type IVatanDonemFieldProps = Omit<IVatanFreeAutoCompleteFieldProps, "sourceList"> & {
    genelDurumHelper: GenelDurumHelper,
}

function VatanDilField(props: IVatanDonemFieldProps) {
    const { genelDurumHelper, ...rest } = props;

    const sourceList = genelDurumHelper.SeciliKoroModel.tumSarkilar
        .where(e => StringHelper.isNotEmpty(e.dil))
        .select(e => e.dil!)
        .distinct()
        .orderByTurkish(e => e);

    return <VatanFreeAutoCompleteField
        sourceList={sourceList}
        {...rest}
    />
}

export default VatanDilField;
import React from 'react';
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { userLoggedOut } from "../../features/currentUserSlice";
import { MyText } from "../../components/common/MyText";
import { mainScreenLocations } from "../../app/mainScreens";
import { sonDurumTemizlendi } from "../../features/loadingMessageSlice";
import { useTranslation } from "react-i18next";
import TranslationHelper from '../../translations/TranslationHelper';

export function LogoutPage() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);

    useEffect(() => {
        dispatch(sonDurumTemizlendi());
        dispatch(userLoggedOut());
        navigate(mainScreenLocations.login);
    })

    dispatch(userLoggedOut());
    dispatch(sonDurumTemizlendi())

    return (
        <div>
            <MyText>{translate("Sistemden çıkış yapılmıştır. Otomatik olarak girişi sayfasına yönlendirileceksiniz")}</MyText>
            <MyText>{translate("Eğer yönlendirme yapılmazsa, aşağıdaki link üzerinden giriş sayfasına ilerleyebilirsiniz")}</MyText>
            <Button onClick={() => navigate(mainScreenLocations.login)}>{translate("Giriş")}</Button>
        </div>
    );
}
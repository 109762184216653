import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { StringOrNumber } from "../../../helpers/StringOrNumber";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import GenericTwoBoxListMultiSelectField from "../../../components/common/GenericTwoBoxListMultiSelectField";

interface ISortableMetaListPopupEditorProps<T, U extends StringOrNumber> {
    genelDurumHelper: GenelDurumHelper,
    itemLabel: string,
    label: string
    value: U[],
    list: T[],
    labelGetter: (item: T) => string,
    valueGetter: (item: T) => U,
    closer: (result: U[] | null) => void,
}

function SortableMetaListPopupEditor<T, U extends StringOrNumber>(props: ISortableMetaListPopupEditorProps<T, U>) {
    const { closer, itemLabel, label, value, list, labelGetter, valueGetter, genelDurumHelper } = props;

    const [currentData, setCurrentData] = useState<U[]>(value.slice());

    return (
        <Rows style={{ paddingTop: 20, display: "flex" }}>
            <Rows>
                <GenericTwoBoxListMultiSelectField
                    itemLabel={itemLabel}
                    label={label}
                    value={currentData}
                    onChange={(e) => setCurrentData(e)}
                    list={list}
                    labelGetter={labelGetter}
                    valueGetter={valueGetter}
                    sortable
                    genelDurumHelper={genelDurumHelper}
                />
            </Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => closer(currentData)} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default SortableMetaListPopupEditor;
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import { Button, FormControl } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import VatanTextField from "../../../components/common/VatanTextField";
import { EnmKatilimBildirmeDurum } from "../../../models/enums/EnmKatilimBildirmeDurum";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import FormSegmentedRadioButtonList from "../../../components/common/FormSegmentedRadioButtonList";
import EnumHelper from "../../../helpers/EnumHelper";
import { useScreenWidth } from "../../../components/core/useScreenWidth";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import { SecmeKatilimBildirmeDurumGuncelleRequestModel } from "../../../models/requestModels/SecmeKatilimBildirmeDurumGuncelleRequestModel";
import StringHelper from "../../../helpers/StringHelper";

interface ISecmeBasvuruKatilimBildirmeDurumPopupEditorProps {
    basvuru: SecmeBasvuruModel
    genelDurumHelper: GenelDurumHelper,
    closer: (model: { katilimBildirmeDurum: EnmKatilimBildirmeDurum | null, katilmamaGerekce: string } | null) => void,
}

function SecmeBasvuruKatilimBildirmeDurumPopupEditor(props: ISecmeBasvuruKatilimBildirmeDurumPopupEditorProps) {
    const [katilimDurum, setKatilimDurum] = useState<EnmKatilimBildirmeDurum | null>(props.basvuru.katilimBildirmeDurum ?? null);
    const [gerekce, setGerekce] = useState<string>(StringHelper.nullToEmpty(props.basvuru.katilmamaGerekce));

    const yoklamaKatilimDurumBildir = async () => {
        const gerekceGirilebilir = katilimDurum === EnmKatilimBildirmeDurum.Belirsiz || katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum;
        const gerekceFinal = gerekceGirilebilir ? (gerekce ?? " ") : "";

        const reqModel = new SecmeKatilimBildirmeDurumGuncelleRequestModel(props.basvuru.id, katilimDurum, gerekceFinal);
        const webResponse = await props.genelDurumHelper.requestHelper.Secme.SecmeKatilimBildirmeDurumGuncelle(reqModel);

        if (!webResponse.successfull)
            return;

        props.closer({ katilimBildirmeDurum: katilimDurum, katilmamaGerekce: gerekceFinal });
    }

    const gerekceGirilebilir = katilimDurum === EnmKatilimBildirmeDurum.Belirsiz || katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum;

    const gerekceMaxlenHataVarMi = gerekceGirilebilir && (!!gerekce && gerekce.length > 250);
    const gerekceHata = gerekceMaxlenHataVarMi;
    const isSmallScreen = useScreenWidth() === "Max600";

    return <Rows style={{ minWidth: isSmallScreen ? 250 : 450 }}>
        <Columns style={{ placeItems: "center"}}>
            <FormControl style={{ marginRight: 5 }}>
                <FormSegmentedRadioButtonList
                    list={EnumHelper.getNamesAndValues<EnmKatilimBildirmeDurum>(EnmKatilimBildirmeDurum)}
                    labelGetter={(e) => e.name}
                    valueGetter={(e) => e.value}
                    value={katilimDurum}
                    onChange={(value) => setKatilimDurum(value)} />
            </FormControl>
            {katilimDurum === EnmKatilimBildirmeDurum.Katılacağım ? appIcons.Zorluk.uygun_midi : katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum ? appIcons.Zorluk.uygunDegil_midi : appIcons.Zorluk.bilinmiyor_midi}
        </Columns>
        <FormControl style={{ maxWidth: 600, marginRight: 5, marginTop: 10, marginBottom: 10 }}>
            <VatanTextField
                fullWidth
                multiline
                showStats
                eventOnKeyPress
                rows={4}
                appendMaxlenOnLabel={250}
                error={gerekceHata}
                label={gerekceMaxlenHataVarMi ? "Gerekçe (En fazla 250 karakter)" : "Gerekçe"}
                disabled={!gerekceGirilebilir}
                value={gerekceGirilebilir ? gerekce : "Seçmelere katılma durumunda gerekçe girilmez"}
                onChange={(e) => setGerekce(e)} />
        </FormControl>

        <Columns style={{ marginTop: 10 }}>
            <Button color="error" variant="contained" onClick={() => props.closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                {appIcons.Popup.iptal}
                {props.genelDurumHelper.translate("İptal")}</Button>
            <Button color="success" variant="contained" disabled={!!gerekceHata || !katilimDurum} onClick={() => yoklamaKatilimDurumBildir()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                {appIcons.Popup.tamam}
                {props.genelDurumHelper.translate("Kaydet")}</Button>
        </Columns>
    </Rows>
        ;

}

export default SecmeBasvuruKatilimBildirmeDurumPopupEditor;
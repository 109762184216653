// AutoGenerated
export class HesapYorumModel {
    koroModelId!: string;
    hesapModelId!: string;
    secmeYorumlar!: string | null;
    arkadasYorumlar!: string | null;
    egitmenYorumlar!: string | null;
    id!: string;
    olusturan!: string;
    olusturmaTarih!: Date;
    guncelleyen!: string | null;
    guncellemeTarih!: Date | null;
}
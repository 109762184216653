import EnumHelper from "../../helpers/EnumHelper";
import GenericListMultiSelectField, { IGenericListMultiSelectFieldProps } from "../common/GenericListMultiSelectField";

type innerListType = { value: number, presentation: string };
export type IVatanCstFieldProps = Omit<IGenericListMultiSelectFieldProps<innerListType, number>, "list" | "labelGetter" | "valueGetter"> & { cstType: Record<number, string> };

function VatanCstListField(props: IVatanCstFieldProps) {
    const { cstType: enumType, ...rest } = props;
    return (
        <GenericListMultiSelectField
            {...rest}
            list={EnumHelper.getSelectListAsArray<number, number>(enumType, e => enumType[e])}
            labelGetter={(e) => e.presentation}
            valueGetter={(e) => e.value}
        />
    )
}

export default VatanCstListField;
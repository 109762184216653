import { FormControl } from "@mui/material";
import appColors from "../../app/appColors";

export type IGenericGroupBoxProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
{
    label: string,
    error?: boolean,
    warning?: boolean,
    disabled?: boolean,
}

export default function GenericGroupBox(props: IGenericGroupBoxProps) {
    const { label, error, warning, disabled, style, children, ...rest } = props;

    return <div style={{ minHeight: 0, ...style }} {...rest}>
        <FormControl disabled={disabled} fullWidth style={{ minHeight: 0, height: "100%" }} >
            <fieldset style={{ borderRadius: "4px", borderColor: error ? appColors.RED_MAIN : warning ? appColors.YELLOW : appColors.BLACK023, borderWidth: "1px", flex: 1, height: "100%" }}>
                {label === "" ? null : <legend style={{ fontSize: "12px", fontWeight: 400, color: error ? appColors.RED_MAIN : warning ? appColors.YELLOW : appColors.BLACK060 }}>{label}</legend>}
                {children}
            </fieldset>
        </FormControl>
    </div>
}
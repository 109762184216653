
// cihan
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, FormControl } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import StringHelper from "../../../helpers/StringHelper";
import VatanKadroSelectField from "../../../components/vatan/VatanKadroSelectField";
import { MyText } from "../../../components/common/MyText";

interface IKazananKullaniciOlusturPopupEditorProps {
    genelDurumHelper: GenelDurumHelper,
    secilenSayi: number,
    closer: (model: string | null) => void,
}

function KazananKullaniciOlusturPopupEditor(props: IKazananKullaniciOlusturPopupEditorProps) {
    const { secilenSayi, closer, genelDurumHelper } = props;

    const secilebilirKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === genelDurumHelper.AktifSezon.id);

    const [seciliKadroId, setSeciliKadroId] = useState<string>(StringHelper.nullToEmpty(secilebilirKadrolar.orderBy(e => e.olusturmaTarih).firstOrDefault()?.id));
    // others

    const onayla = async () => {
        closer(seciliKadroId);
    }

    return (
        <Rows style={{ display: "flex" }}>
            <MyText>Lütfen seçilen {secilenSayi?.toFixed()} aday koristin dahil edileceği kadroyu seçiniz</MyText>
            <FormControl style={{ width: "250px", marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "15px" }}>
                <VatanKadroSelectField
                    value={secilebilirKadrolar.any(e => e.id === seciliKadroId) ? seciliKadroId : ""}
                    label="Aday Koristlerin Dahil Edileceğim Kadro"
                    tumKadrolar={secilebilirKadrolar}
                    onChange={value => setSeciliKadroId(value)}
                />
            </FormControl>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => onayla()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default KazananKullaniciOlusturPopupEditor;
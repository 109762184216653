import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { appIcons } from "../../../app/appIcons";
import { mainScreenLocations } from "../../../app/mainScreens";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import Columns from "../../../components/layout/Columns";
import Cell from "../../../components/layout/Cell";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import KorocanThumbnailIcerikId from "../../../components/business/KorocanThumbnailIcerikId";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import VatanTextField from "../../../components/common/VatanTextField";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { Button, IconButton } from "@mui/material";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import appColors from "../../../app/appColors";
import AlertHelper from "../../../helpers/AlertHelper";
import SecmeSonucBilgiPopupDisplayer from "./SecmeSonucBilgiPopupDisplayer";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import KazananKullaniciOlusturPopupEditor from "./KazananKullaniciOlusturPopupEditor";
import { HesapBilgiVeKullaniciWithContentRequestModel } from "../../../models/requestModels/HesapBilgiVeKullaniciWithContentRequestModel";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { KullaniciModel } from "../../../models/businessModels/KullaniciModel";
import { GuidHelper } from "../../../helpers/GuidHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { KadroVeSesGrubuBilgiModel } from "../../../models/businessModels/KadroVeSesGrubuBilgiModel";
import JsonHelper from "../../../helpers/JsonHelper";
import StringHelper from "../../../helpers/StringHelper";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import IcerikHelper from "../../../helpers/IcerikHelper";

function SecmeSonucScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SecmeSonucScreenInner genelDurumHelper={genelDurumHelper} />
}

function SecmeSonucScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);
  const [seciliBasvuruIdList, setSeciliBasvuruIdList] = useState<string[]>([]);

  const kullaniciYonetimYetkiVarMi = genelDurumHelper.HasYetkiAny([EnmYetki["Korist Yönetim"]]);
  const varolanEpostalar = !kullaniciYonetimYetkiVarMi ? [] : genelDurumHelper.SeciliKoroModel.hesapBilgiList.select(e => e.kullaniciModel.kullaniciAdi).distinct();

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  const tumBasvurular = genelDurumHelper.SeciliKoroModel.tumSecmeBasvurular.where(e => e.sezonModelId === seciliSezonId);

  const filteredBasvurular = searchFilter.length <= 2 ?
    tumBasvurular :
    tumBasvurular.where(e => includesSearchText(`${e.ad} ${e.soyad}`));

  /* -- END OF STANDARD -- */

  const checkChanged = (secmeBasvuru: SecmeBasvuruModel, yeniDurum: boolean) => {
    let newList = seciliBasvuruIdList.slice();

    if (yeniDurum)
      newList.push(secmeBasvuru.id);
    else
      newList = newList.where(e => e !== secmeBasvuru.id);

    setSeciliBasvuruIdList(newList);
  }

  async function secilenlerinKullanicilariniOlustur() {
    const eklenecekler = kazananlar.where(e => seciliBasvuruIdList.contains(e.id));

    if (eklenecekler.length === 0) return;

    const responseKadroId = await AlertHelper.ShowClosableContent<string>("Dikkat", closer =>
      <KazananKullaniciOlusturPopupEditor
        secilenSayi={eklenecekler.length}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    )

    if (responseKadroId === null) return;

    for (const basvuru of eklenecekler) {
      const hesap = new HesapBilgiVeKullaniciModel();
      hesap.kullaniciModel = new KullaniciModel();

      const bulunanKullanici = await genelDurumHelper.requestHelper.Hesap.KullaniciAra(basvuru.eposta);

      let icerik: IcerikEklemeRequestModel | null = null;

      if (StringHelper.isEmpty(basvuru.resimIcerikId) === false) {
        const content = await IcerikHelper.IcerikGetirDirekt(basvuru.resimIcerikId!, genelDurumHelper);
        icerik = new IcerikEklemeRequestModel(GuidHelper.NewGuid(), ".png", content);
      }

      if (bulunanKullanici.data !== null) {
        alert(JsonHelper.stringify(bulunanKullanici));
        return;
      }
      else {
        hesap.kullaniciModel.id = GuidHelper.NewGuid();
        hesap.kullaniciModel.kullaniciAdi = basvuru.eposta;
        hesap.kullaniciModel.ad = basvuru.ad;
        hesap.kullaniciModel.soyad = basvuru.soyad;
        hesap.kullaniciModel.cinsiyet = basvuru.cinsiyet;
        hesap.kullaniciModel.dogumTarihi = new Date(1980, 1, 1);
        hesap.kullaniciModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        hesap.kullaniciModel.olusturmaTarih = DateTimeHelper.now();
      }

      const kvsgbm = new KadroVeSesGrubuBilgiModel();
      kvsgbm.id = GuidHelper.NewGuid();
      kvsgbm.kadroModelId = responseKadroId;
      kvsgbm.altSesGrupModelId = basvuru.sonucModel!.sonucSesGrup!;
      kvsgbm.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
      kvsgbm.olusturmaTarih = DateTimeHelper.now();

      hesap.aktif = true;
      hesap.araVerdi = false;
      hesap.id = GuidHelper.NewGuid();
      hesap.kadroVeSesGrubuBilgiModelList = [kvsgbm];
      hesap.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
      hesap.kullaniciModelId = hesap.kullaniciModel.id;
      hesap.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
      hesap.olusturmaTarih = DateTimeHelper.now();
      hesap.ozgecmis = "";
      hesap.resimIcerikId = icerik?.id ?? null;
      hesap.rolIdList = [genelDurumHelper.SeciliKoroModel.tumRoller.single(e => e.isim === "Korist").id];
      hesap.sesGrupIdList = BusinessHelper.SesGrubu.AltSesGrubununListesiniGetir(basvuru.sonucModel!.sonucSesGrup!, genelDurumHelper.SeciliKoroModel.tumSesGruplari);
      hesap.superAdmin = false;

      const reqModel = new HesapBilgiVeKullaniciWithContentRequestModel(hesap, icerik ? [icerik] : []);

      const webResponse = await genelDurumHelper.requestHelper.Hesap.HesapEkleCustomMessage(reqModel, `${basvuru.ad} ${basvuru.soyad} Ekleniyor`);
      if (!webResponse.successfull)
        return;
    }

    setSeciliBasvuruIdList([]);
    genelDurumHelper.reloadGenelDurum();
  }

  async function secmeSonucDetayGoster(secmeBasvuru: SecmeBasvuruModel) {
    await AlertHelper.ShowClosableContent<boolean>("Seçme Sonucu Ekle", (closer) => (
      <SecmeSonucBilgiPopupDisplayer
        secmeBasvuruModel={secmeBasvuru}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));
  }

  const secmeBasvuruRowsGetir = (basvuruList: SecmeBasvuruModel[], sesGrubunaGoreAyir: boolean): JSX.Element | null => {
    if (basvuruList.length === 0)
      return null;

    const tumSesGruplari = genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"]).orderBy(e => e.sira);

    const contents = (sesGrubunaGoreAyir ? tumSesGruplari : [null]).select(sesGrup => {

      const sesGrubununBasvurulari = (sesGrup === null ?
        basvuruList :
        basvuruList.where(e => e.sonucModel?.sonucSesGrup === sesGrup.id)
          .orderByTurkish(e => `${e.ad} ${e.soyad}`));

      const innerContent = sesGrubununBasvurulari.map(secmeBasvuru => {
        const s = secmeBasvuru.sonucModel;
        const seciliMi = seciliBasvuruIdList.contains(secmeBasvuru.id);
        const kullanicisiVar = varolanEpostalar.contains(secmeBasvuru.eposta);

        return <Columns hasLightBottomBorder width={400} key={secmeBasvuru.id} style={{ background: seciliMi ? appColors.SECONDARY : undefined, paddingTop: "5px", paddingBottom: "5px" }}>
          <Columns>
            {kullaniciYonetimYetkiVarMi &&
              <Cell style={{ placeContent: "center" }}>
                <IconButton disabled={kullanicisiVar} color="primary" onClick={() => checkChanged(secmeBasvuru, !seciliMi)} title={seciliMi ? "Seçme" : "Seç"} >
                  {seciliMi ? appIcons.CrudIcons.checked : appIcons.CrudIcons.unchecked}
                </IconButton>
              </Cell>
            }
            <Cell style={{ placeContent: "center" }}>
              <IconButton color="primary" onClick={() => secmeSonucDetayGoster(secmeBasvuru)} title="Detay Göster" >
                {appIcons.Genel.info}
              </IconButton>
            </Cell>
            <KorocanThumbnailIcerikId title={`${secmeBasvuru.ad} ${secmeBasvuru.soyad}`} icerikId={secmeBasvuru.resimIcerikId} saveToStorage="None" genelDurumHelper={genelDurumHelper} size="normal" />
            <Rows style={{ marginLeft: "5px" }}>
              <MyText bold>{`${secmeBasvuru.ad} ${secmeBasvuru.soyad}`}</MyText>
              <Rows>
                <MyText smallNote style={{ marginRight: "15px" }} >{EnmCinsiyet[secmeBasvuru.cinsiyet]} / {secmeBasvuru.dogumYili}</MyText>
                {!!s &&
                  <MyText smallNote style={{ marginRight: "15px" }} >
                    {s?.normalMelodiPuan} - {s?.arizaMelodiPuan} - {BusinessHelper.Nota.InceKalinNotaIsimVeOktavGetir(s?.kalinNota, s?.inceNota)} - ({BusinessHelper.Nota.OktavSayiFarkGetir(s?.kalinNota, s?.inceNota)} Oktav)</MyText>
                }
              </Rows>
            </Rows>
          </Columns>
        </Columns>
      });

      return <Rows key={sesGrup ? sesGrup.id : "-"}>
        {!sesGrubunaGoreAyir ? null : <Cell style={{ background: appColors.GRAY_LIGHT3 }}>
          <MyText style={{ padding: "5px" }} bold>{`${sesGrup?.isim} (${sesGrubununBasvurulari.length} aday)`}</MyText>
        </Cell>}
        <Columns wrap>
          {innerContent}
        </Columns>
      </Rows>
    });

    return <Rows>
      {contents}
    </Rows>
  }

  const kazananlar = filteredBasvurular.where(e => e.sonucModel?.sonuc === true);
  const kazanmayanlar = filteredBasvurular.where(e => e.sonucModel?.sonuc === false);
  const belirsizOlanlar = filteredBasvurular.where(e => e.sonucModel !== null && e.sonucModel!.sonuc === null);
  const secmeyeGelmeyenler = filteredBasvurular.where(e => e.sonucModel === null);

  return (
    <MainLayout
      location={mainScreenLocations.secmeSonuc}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns wrap>
          <Cell style={{ marginLeft: "10px", marginTop: "10px" }}>
            <VatanTextField value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell style={{ marginLeft: "10px", marginTop: "10px" }}>
            <VatanSezonSelectField
              value={seciliSezonId}
              label={"Sezon"}
              tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
              onChange={e => setSeciliSezonId(e)} />
          </Cell>
          <Cell flex ></Cell>
          {kullaniciYonetimYetkiVarMi && seciliBasvuruIdList.length > 0 &&
            < Cell style={{ marginTop: "10px" }}>
              <Button variant="contained" onClick={() => secilenlerinKullanicilariniOlustur()}>
                {appIcons.CrudIcons.ekleToplu} <span style={{ marginLeft: "10px" }}>Seçilenlerin Kullanıcılarını Oluştur</span>
              </Button>
            </Cell>
          }
        </Columns>
        <Rows style={{ marginTop: "10px" }}>

          <VatanAccordionSimple title={`Kazananlar (${kazananlar.length} aday)`} alwaysOpen>
            {secmeBasvuruRowsGetir(kazananlar, true)}
          </VatanAccordionSimple>
          <VatanAccordionSimple title={`Kazanamayanlar (${kazanmayanlar.length} aday)`}>
            {secmeBasvuruRowsGetir(kazanmayanlar, false)}
          </VatanAccordionSimple>
          <VatanAccordionSimple title={`Kararsız Kalınanlar (${belirsizOlanlar.length} aday)`}>
            {secmeBasvuruRowsGetir(belirsizOlanlar, false)}
          </VatanAccordionSimple>
          <VatanAccordionSimple title={`Seçmeye Katılmayanlar (${secmeyeGelmeyenler.length} aday)`}>
            {secmeBasvuruRowsGetir(secmeyeGelmeyenler, false)}
          </VatanAccordionSimple>

        </Rows>
      </Rows>
    </MainLayout >
  );
}

export default SecmeSonucScreen;

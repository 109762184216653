import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { MyText } from "../../../components/common/MyText";
import Columns from "../../../components/layout/Columns";
import Cell from "../../../components/layout/Cell";
import VatanTextField from "../../../components/common/VatanTextField";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { SarkiZorlukModel } from "../../../models/businessModels/SarkiZorlukModel";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import PdfDisplayer from "../../../components/media/PdfDisplayer";
import { Chip, IconButton } from "@mui/material";
import VatanTarzField from "../../../components/vatan/VatanTarzField";
import VatanGradientProgress from "../../../components/vatan/VatanGradientProgress";
import appColors from "../../../app/appColors";
import SarkiZorlukSarkiPopupEditor from "../../yonetim/sarkiZorluk/SarkiZorlukSarkiPopupEditor";
import SarkiKunyeDisplayer from "../../main/sarki/SarkiKunyeDisplayer";
import StringHelper from "../../../helpers/StringHelper";

function SarkiZorlukRaporScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SarkiZorlukRaporScreenInner genelDurumHelper={genelDurumHelper} />
}

function SarkiZorlukRaporScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [seciliTarzIdList, setSeciliTarzIdList] = useState<string[]>([]);
  /* -- END OF STANDARD -- */

  let sarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
  const tumSarkiZorluklar = genelDurumHelper.SeciliKoroModel.sarkiZorlukModelList;
  const tumTarzlar = genelDurumHelper.SeciliKoroModel.tumTarzlar;

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  if (searchFilter.length > 2) {
    sarkilar = sarkilar.where(e => includesSearchText(e.isim));
  }
  if (seciliTarzIdList.length > 0)
    sarkilar = sarkilar.where(e => e.tarzModelIdList.intersection(seciliTarzIdList).length > 0);

  const zorlukOlanSarkiIdler = tumSarkiZorluklar.select(e => e.sarkiModelId).distinct();
  const zorlukBilgisiOlanSarkilar = sarkilar.where(e => zorlukOlanSarkiIdler.contains(e.id));

  const zorlukBilgisiOlanKoroSarkilari = zorlukBilgisiOlanSarkilar.where(e => e.sezonSarkiModelList.length > 0).orderByTurkish(e => e.isim);
  const zorlukBilgisiOlanAdaySarkilar = zorlukBilgisiOlanSarkilar.where(e => e.sezonSarkiModelList.length === 0).orderByTurkish(e => e.isim);

  function sarkiPdfGoster(sarki: SarkiModel): void {
    const linkModel = sarki.sarkiLinkModelList.singleOrDefault(e => e.linkTip === EnmSarkiLinkTip.PDF && (StringHelper.nullToEmpty(e.aciklama)).isNullOrEmpty());
    if (linkModel)
      AlertHelper.ShowContent(sarki.isim, <PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={linkModel.icerikId!} />)
    else
      AlertHelper.ShowMessage("Dikkat", "Bu eserin PDF içeriği bulunmamaktadır");
  }

  function findVideos(sarki: SarkiModel) {
    const linkModels = sarki.sarkiLinkModelList
      .where(e => (e.linkTip === EnmSarkiLinkTip["Konser Videosu"] || e.linkTip === EnmSarkiLinkTip["Başka Koro Videosu"]) && e.linkUrl != null)
      .select(e => ({ aciklama: e.aciklama, link: e.linkUrl }));

    const sarkininKonserleri = genelDurumHelper.SeciliKoroModel.tumKonserler
      .selectMany(e => e.konserSarkiModelList.where(t => t.sarkiModelId === sarki.id).select(t => ({ konser: e, konserSarki: t })))
      .where(e => StringHelper.isNotEmpty(e.konserSarki.videoUrl))
      .select(e => ({ aciklama: `${e.konser.isim} (${e.konserSarki.siraNo}. eser)`, link: e.konserSarki.videoUrl }));

    return [...sarkininKonserleri, ...linkModels];
  }

  async function sarkiZorlukBilgiGoster(sarki: SarkiModel) {
    await AlertHelper.ShowClosableContent(`${sarki.isim} Şarkısının Künye Bilgileri`, (closer) => (
      <SarkiKunyeDisplayer
        genelDurumHelper={genelDurumHelper}
        closer={() => closer(null)}
        sarkiModel={sarki}
      />
    ));
  }

  async function sarkiVideoGoster(sarki: SarkiModel) {
    const merged = findVideos(sarki);

    if (merged.length === 0) {
      AlertHelper.ShowMessage("Dikkat", "Bu eserin video içeriği bulunmamaktadır");
      return;
    }

    if (merged.length > 1) {
      const sonuc = await AlertHelper.ConfirmWithChoices("Dikkat", "Açmak istediğiniz videoyu seçiniz", merged.select(e => e.aciklama!))

      if (sonuc == null)
        return;

      window.open(merged.first(e => e.aciklama === sonuc).link!, '_blank');
    }

    if (merged.length === 1) {
      window.open(merged[0].link!, '_blank');
      return;
    }
  }

  function generateVerticalBar(value: number | undefined, name: string) {
    return <Cell key={name} width={16} height={100}>
      <VatanGradientProgress width={16} height={100} style={{ borderRadius: 0 }} vertical value={(value ?? 0) * 10} />
    </Cell>;
  }
  const sarkiRowsGetir = (sarkiList: SarkiModel[], sarkiZorlukList: SarkiZorlukModel[]) => {
    if (sarkiList.length === 0)
      return null;

    const innerContent = sarkiList.map(sarki => {

      const sarkiZorluk = sarkiZorlukList.singleOrDefault(e => e.sarkiModelId === sarki.id);

      let stats = sarkiZorluk ? BusinessHelper.SarkiZorluk.StatOlustur(sarki, sarkiZorluk) : null;

      const uygunlukColor = sarkiZorluk === null ? undefined : sarkiZorluk.koroyaUygunluk === false ? appColors.LIGHTRED : sarkiZorluk.koroyaUygunluk === null ? appColors.LIGHTYELLOW : appColors.LIGHTGREEN;
      if (sarkiZorluk?.koroyaUygunluk === null)
        stats = null;

      const titleContent = <MyText header style={{ paddingLeft: "5px", paddingRight: "5px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", minWidth: "0px", whiteSpace: "pre-line", background: uygunlukColor }}>{sarki.isim}</MyText>;
      const tarzContentArray = sarki.tarzModelIdList.map(e => <Chip size="small" key={e} label={tumTarzlar.single(t => t.id === e).isim} />);
      const middleNotesContent = stats === null ? null :
        <Rows width={120}>
          <Columns>
            <Rows flex style={{ alignItems: "center" }}>
              <span style={{ borderRadius: "20px", padding: "8px", background: BusinessHelper.SarkiZorluk.GetColorAtPoint10Based(stats!.ortalamaOgrenme) }}>{stats!.ortalamaOgrenme?.toFixed(1) ?? "-"}</span>
              <MyText smallNote>Öğrenme</MyText>
            </Rows>
            <Rows flex style={{ alignItems: "center" }}>
              <span style={{ borderRadius: "20px", padding: "8px", background: BusinessHelper.SarkiZorluk.GetColorAtPoint10Based(stats!.ortalamaSoyleme) }}>{stats!.ortalamaSoyleme?.toFixed(1) ?? "-"}</span>
              <MyText smallNote>Söyleme</MyText>
            </Rows>
          </Columns>
        </Rows>

      // <MyText note style={{ marginLeft: "3px", marginRight: "5px", marginTop: "2px" }}>Öğrenme: {stats!.ortalamaOgrenme?.toFixed(1) ?? "-"}, Söyleme: {stats!.ortalamaSoyleme?.toFixed(1) ?? "-"}</MyText>;

      const bars = sarkiZorluk === null ? [] : [
        generateVerticalBar(stats?.eserUzunluk, "Eser Uzunluk"),
        generateVerticalBar(stats?.eserDil, "Eser Dil"),
        generateVerticalBar(stats?.armoni, "Armoni"),
        generateVerticalBar(stats?.melodi, "Melodi"),
        generateVerticalBar(stats?.divizi, "Divizi"),
        generateVerticalBar(stats?.sesSinir, "Ses Sınırı"),
        generateVerticalBar(stats?.nuans, "Nüans"),
        generateVerticalBar(stats?.duygu, "Duygu"),
      ];
      const innerContent = sarkiZorluk === null ?
        <MyText note style={{ marginLeft: "0px" }}>Zorluk bilgisi girilmemiştir</MyText>
        :
        <Rows>
          <Columns flex wrap style={{ marginTop: "5px" }}>
            {tarzContentArray}
          </Columns>
          {middleNotesContent}
        </Rows>

      // { icon: appIcons.Sarki.pdf, color: "primary", onClick: () => sarkiPdfGoster(sarki) },
      // { icon: appIcons.Sarki.youtube, color: "primary", onClick: () => sarkiVideoGoster(sarki), },
      // { icon: appIcons.Genel.info, color: "info", onClick: () => sarkiZorlukBilgiGoster(sarki, sarkiZorluk!), disabled: sarkiZorluk === null },


      return <Rows key={sarki.id} style={{ marginBottom: "20px" }} hasLightBottomBorder width={400}>
        <Columns flex style={{ marginBottom: "10px" }}>
          <Rows style={{ marginLeft: "20px" }}>
            <IconButton color="primary" onClick={() => sarkiPdfGoster(sarki)}>{appIcons.Sarki.pdf}</IconButton>
            <IconButton color="primary" onClick={() => sarkiVideoGoster(sarki)} disabled={findVideos(sarki).length === 0}>{appIcons.Sarki.youtube}</IconButton>
            <IconButton color="info" onClick={() => sarkiZorlukBilgiGoster(sarki)} disabled={sarkiZorluk === null}>{appIcons.Genel.info}</IconButton>
          </Rows>
          <Rows flex>
            {titleContent}
            <Columns>
              <Columns flex>
                {innerContent}
              </Columns>
              {bars}
            </Columns>
          </Rows>
          {/* <Columns>
            <Rows key={sarki.id} style={{ marginLeft: "5px" }}>
            {titleContent}
            <Cell>{innerContent}</Cell>
            </Rows>
            </Columns>
            <Columns>
            {bars}          
            </Columns> */}
        </Columns>
      </Rows>
    });

    return <Columns wrap>
      {innerContent}
    </Columns>
  }

  return (
    <MainLayout
      location={mainScreenLocations.sarkiZorlukRaporu}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex style={{ marginTop: "10px" }}>
        <Columns style={{ marginBottom: "10px" }}>
          <MyText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>Filtreleme: </MyText>
          <Cell style={{ marginLeft: "10px", marginTop: "5px" }}>
            <VatanTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell width={350} style={{ marginLeft: "10px", marginTop: "5px" }}>
            <VatanTarzField
              value={seciliTarzIdList}
              label="Tarz"
              colorize
              onChange={e => setSeciliTarzIdList(e)}
              genelDurumHelper={genelDurumHelper} />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <VatanAccordionSimple
          emptyNote="Zorluk bilgisi olan şarkı bulunmamaktadır"
          title={`Zorluk Bilgisi Olan Şarkılar (${zorlukBilgisiOlanKoroSarkilari.length} şarkı)`}>
          {sarkiRowsGetir(zorlukBilgisiOlanKoroSarkilari, tumSarkiZorluklar)}
        </VatanAccordionSimple>
        <VatanAccordionSimple
          emptyNote="Zorluk bilgisi olan diğer şarkı bulunmamaktadır"
          title={`Zorluk Bilgisi Olan Diğer Şarkılar (${zorlukBilgisiOlanAdaySarkilar.length} şarkı)`}>
          {sarkiRowsGetir(zorlukBilgisiOlanAdaySarkilar, tumSarkiZorluklar)}
        </VatanAccordionSimple>
      </Rows>
    </MainLayout>
  );
}

export default SarkiZorlukRaporScreen;
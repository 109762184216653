import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { mainScreenLocations } from "../../../app/mainScreens";
import { MyText } from "../../../components/common/MyText";
import Columns from "../../../components/layout/Columns";
import Cell from "../../../components/layout/Cell";
import VatanTextField from "../../../components/common/VatanTextField";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { IconButton } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import SarkiYorumSarkiPopupEditor from "./SarkiYorumSarkiPopupEditor";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { SarkiYorumModel } from "../../../models/businessModels/SarkiYorumModel";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function SarkiYorumScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KoristYorumScreenInner genelDurumHelper={genelDurumHelper} />
}

function KoristYorumScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  /* -- END OF STANDARD -- */

  const tumSezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar.orderBy(e => e.isim);
  const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);

  let sarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === seciliSezonId));
  let tumSarkiYorumlar = genelDurumHelper.SeciliKoroModel.sarkiYorumModelList;

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  if (searchFilter.length > 2) {
    tumSarkiYorumlar = tumSarkiYorumlar.where(e => includesSearchText(e.icerik) || includesSearchText(e.baslik));
    sarkilar = sarkilar.where(e => tumSarkiYorumlar.any(t => t.sarkiModelId === e.id));
  }

  sarkilar = seciliSezonId === genelDurumHelper.AktifSezon.id ? sarkilar.orderBy(e => e.sira) : sarkilar.orderByTurkish(e => e.isim);

  const yorumSil = async (sarki: SarkiModel, sarkiYorum: SarkiYorumModel) => {
    if (await AlertHelper.Confirm("Dikkat", `${sarki.isim} şarkısının ${sarkiYorum.baslik} yorumunu silmek istediğiniz emin misiniz?`) === false)
      return;

    const response = await genelDurumHelper.requestHelper.SarkiYorum.SarkiYorumSil(sarkiYorum.id);
    if (!response.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarkiYorumList = seciliKoro.sarkiYorumModelList;
        sarkiYorumList.remove(sarkiYorumList.single(e => e.id === sarkiYorum.id));
      });
  }

  const yorumEkleGuncelle = async (sarki: SarkiModel, sarkiYorum: SarkiYorumModel | null) => {
    const response = await AlertHelper.ShowClosableContent<SarkiYorumModel>(`${sarki.isim} şarkısının yorumlarını güncelle`, (closer) => (
      <SarkiYorumSarkiPopupEditor
        genelDurumHelper={genelDurumHelper}
        closer={closer}
        sarkiModel={sarki}
        sarkiYorumModel={sarkiYorum}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const newSarkiYorumList = seciliKoro.sarkiYorumModelList.slice();
        const oldHesapYorum = newSarkiYorumList.singleOrDefault(e => e.id === response?.id);
        if (oldHesapYorum != null)
          newSarkiYorumList.remove(oldHesapYorum);

        newSarkiYorumList.push(response!);

        seciliKoro.sarkiYorumModelList = newSarkiYorumList;
      });
  }

  const yorumKopyala = (sarki: SarkiModel) => {
    const yorumlar = tumSarkiYorumlar.where(e => e.sarkiModelId === sarki.id).orderBy(e => e.baslik).select(e => `${e.baslik}:\n${e.icerik}`).join("\n\n");
    const text = `${sarki.isim}\n\n${yorumlar}`;

    navigator.clipboard.writeText(text);
    AlertHelper.ShowMessage("Dikkat", "Şarkı yorumları kopyalandı");
  }

  const sarkiRowGetir = (sarki: SarkiModel, sarkiYorumList: SarkiYorumModel[]) => {
    const leftButtons: ICustomSwipeButton[] = [
      { icon: appIcons.CrudIcons.ekle, color: "primary", onClick: () => yorumEkleGuncelle(sarki, null) },
      { icon: appIcons.CrudIcons.kopyala, color: "primary", onClick: () => yorumKopyala(sarki) },
    ];

    return <Rows key={sarki.id} style={{ marginBottom: "20px" }}>
      <MyListItem itemDividerText={sarki.isim} leftButtons={leftButtons} />
      {sarkiYorumList.length > 0 ? null : <MyText note style={{ marginLeft: "40px" }}>Yorum bulunmamaktadır</MyText>}
      {sarkiYorumList.map(sarkiYorum => {
        const olusturan = genelDurumHelper.SeciliKoroModel.hesapBilgiList.firstOrDefault(e => e.kullaniciModel.kullaniciAdi === sarkiYorum.olusturan);
        const adSoyad = `${olusturan?.kullaniciModel.ad ?? "-"} ${olusturan?.kullaniciModel.soyad ?? "-"}`;

        return <Columns key={sarkiYorum.id} hasLightBottomBorder style={{ alignItems: "flex-start" }}>
          <IconButton color="primary" onClick={() => yorumEkleGuncelle(sarki, sarkiYorum)}>
            {appIcons.CrudIcons.guncelle}
          </IconButton>
          <IconButton color="error" onClick={() => yorumSil(sarki, sarkiYorum)}>
            {appIcons.CrudIcons.sil}
          </IconButton>
          <Rows width={250} style={{ marginLeft: "5px" }}>
            <MyText>{sarkiYorum.baslik}</MyText>
            <MyText note italic>{adSoyad}</MyText>
          </Rows>
          <Cell flex>
            <MyText note style={{ minWidth: "0px", whiteSpace: "pre-line" }}>{sarkiYorum?.icerik ?? "-"}</MyText>
          </Cell>
        </Columns>
      })}
    </Rows>
  }

  return (
    <MainLayout
      location={mainScreenLocations.sarkiYorumYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows style={{ marginTop: "10px" }}>
        <Columns>
          <MyText style={{ marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>Filtreleme: </MyText>
          <Cell width={250}>
            <VatanSezonSelectField
              value={seciliSezonId}
              label="Sezon"
              tumSezonlar={tumSezonlar}
              onChange={e => setSeciliSezonId(e)}
            />
          </Cell>
          <Cell style={{ marginLeft: "10px", marginTop: "5px" }}>
            <VatanTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <Rows>
          {sarkilar.map(e => sarkiRowGetir(e, tumSarkiYorumlar.where(t => t.sarkiModelId === e.id)))}
        </Rows>
      </Rows>
    </MainLayout>
  );
}

export default SarkiYorumScreen;
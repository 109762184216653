import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, ListItem } from "@mui/material";
import Columns from "../layout/Columns";
import { appIcons } from "../../app/appIcons";
import { MyText } from "../common/MyText";
import { ICustomSwipeButton, MyListItem } from "../common/MyListItem";
import { CSSProperties } from "@mui/material/styles/createMixins";
import appColors from "../../app/appColors";
import React, { useState } from "react";

export interface IVatanAccordionSimpleProps extends Omit<AccordionProps, "children" | "expanded" | "onChange"> {
    title: string,
    alwaysOpen?: boolean,
    bigCenteredTitle?: boolean,
    emptyNote?: string,
    visible?: boolean,
    children: React.ReactNode | (() => React.ReactNode),
    buttons?: { text: string, action: () => void }[],
    initialOpen?: boolean,
    innerContainerStyle?: CSSProperties,
}

export default function VatanAccordionSimple(props: IVatanAccordionSimpleProps) {
    const { initialOpen, innerContainerStyle, buttons, visible, emptyNote, bigCenteredTitle, alwaysOpen, title, ...others } = props;
    const [expanded, setExpanded] = useState<boolean>(initialOpen ?? false);

    if (visible === false)
        return null;

    const titleStyle: CSSProperties = bigCenteredTitle ? { fontSize: "20px", fontWeight: "bold", justifyContent: "center" } : {};

    const rightButtons: ICustomSwipeButton[] | undefined = buttons?.map(e => {
        return {
            icon: appIcons.CrudIcons.kopyala,
            text: e.text,
            color: "secondary",
            onClick: e.action,
            disabled: false,
        };
    });

    const calculateChildren = () => {
        if (!expanded)
            return null;
        const actualChildren = typeof props.children === "function" ? props.children() : props.children;

        return actualChildren ?? <MyText style={{ marginLeft: "15px", marginTop: "-7px" }} italic note>{props.emptyNote}</MyText>
    }

    return alwaysOpen ?
        (
            <>
                <MyListItem style={{ background: appColors.BACKGROUND_PAPER, ...titleStyle }} itemDividerText={title} rightButtons={rightButtons} />
                <Columns wrap style={innerContainerStyle}>
                    {(typeof props.children === "function" ? props.children() : props.children) ?? (!emptyNote ? null : <MyText style={{ marginLeft: "15px" }} italic note>{emptyNote}</MyText>)}
                </Columns>
            </>
        ) : (
            <Accordion sx={{ background: "transparent" }} TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={(e, t) => setExpanded(t)} {...others}>
                <AccordionSummary sx={{ background: appColors.BACKGROUND_PAPER, minHeight: "42px" }} expandIcon={appIcons.Genel.expandMore}>
                    <ListItem dense style={{ marginTop: "0px", padding: "0px", ...titleStyle }}>
                        <MyText bold style={titleStyle}>{title}</MyText>
                    </ListItem>
                </AccordionSummary>
                <AccordionDetails>
                    <Columns wrap style={innerContainerStyle}>
                        {calculateChildren()}
                    </Columns>
                </AccordionDetails>
            </Accordion>
        );
}

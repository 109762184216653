import { Badge, IconButton, alpha } from "@mui/material";
import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import Columns from "../layout/Columns";
import { MyText } from "../common/MyText";
import Cell from "../layout/Cell";
import Rows from "../layout/Rows";
import appColors from "../../app/appColors";
import KorocanThumbnailForHesap from "./KorocanThumbnailForHesap";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { CSSProperties } from "react";
import StringHelper from "../../helpers/StringHelper";

interface IOzelGrupluKisiAvatarListControlProps<T> {
    onClick?: (hesap: HesapBilgiVeKullaniciModel) => void,
    gosterilecekHesaplar: HesapBilgiVeKullaniciModel[],
    tumHesaplar: HesapBilgiVeKullaniciModel[],
    thumbnailSize?: "small" | "normal" | "large" | "xlarge",
    badgeCountGetter?: (hesap: HesapBilgiVeKullaniciModel) => { error: number, info: number },
    darkSeperator?: boolean,
    hesapColorizeMethod?: "Özgeçmiş" | "Durum",
    hesapGrupGetir: (hesap: HesapBilgiVeKullaniciModel) => T,
    grupIsimGetir: (grup: T) => string,
    hesapGrupSortFunc: (grup: T) => number,
    genelDurumHelper: GenelDurumHelper
}


function hesapBackgroundOverrideWithOzgecmis(hesap: HesapBilgiVeKullaniciModel): CSSProperties | undefined {
    const len = StringHelper.nullToEmpty(hesap.ozgecmis).length;
    return len === 0 ? undefined : len < 20 ? {
        background: alpha(appColors.LIGHTYELLOW, 0.3)
    } : {
        background: alpha(appColors.LIGHTGREEN, 0.3)
    };
}

function hesapBackgroundOverrideWithState(hesap: HesapBilgiVeKullaniciModel): CSSProperties | undefined {
    return !hesap.aktif ? {
        background: alpha(appColors.LIGHTRED, 0.3)
    } : hesap.araVerdi ? {
        background: alpha(appColors.LIGHTYELLOW, 0.3)
    } : undefined;
}

function OzelGrupluKisiAvatarListControl<T>(props: IOzelGrupluKisiAvatarListControlProps<T>) {

    const siraliGruplar = props.tumHesaplar.select(e => props.hesapGrupGetir(e)).distinct(t => props.grupIsimGetir(t)).orderBy(props.hesapGrupSortFunc);

    const { innerWidth, innerHeight, fontSize } = getThumbnailSize(props.thumbnailSize ?? "small");
    const isSmall = (props.thumbnailSize ?? "small") === "small";

    const GetInnerContent = (hesap: HesapBilgiVeKullaniciModel): React.ReactNode => {
        const badgeCount: { error: number, info: number } | undefined = props.badgeCountGetter ? props.badgeCountGetter(hesap) : undefined;

        return (
            <div key={hesap.id} style={{ height: innerHeight, width: innerWidth, padding: 0, alignSelf: "center" }}>
                <div style={{ position: "absolute", right: 0, marginTop: "-5px" }}>
                    <Badge sx={{ marginLeft: "15px" }} color="info" badgeContent={badgeCount?.info ?? 0}>
                        <div style={{ width: "20px" }}></div>
                    </Badge>
                    <Badge color="error" badgeContent={badgeCount?.error ?? 0}>
                        <div style={{ width: "20px" }}></div>
                    </Badge>
                </div>
                <KorocanThumbnailForHesap hesap={hesap} size={props.thumbnailSize ?? "small"} style={{ alignSelf: "center", marginTop: "3px" }} genelDurumHelper={props.genelDurumHelper} />
                <MyText style={{ flex: 1, marginTop: isSmall ? "-5px" : undefined, textAlign: "center", fontSize: fontSize, lineHeight: 1 }} note>{hesap.kullaniciModel.ad} {hesap.kullaniciModel.soyad}</MyText>
            </div>
        );
    }

    const anaContent = siraliGruplar.map(grup => {
        const sesGrubununHesaplari = props.gosterilecekHesaplar.where(e => props.grupIsimGetir(props.hesapGrupGetir(e)) === props.grupIsimGetir(grup)).orderBy(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
        const viewList = sesGrubununHesaplari.map((hesap) => {
            const background = !props.hesapColorizeMethod ?  undefined : props.hesapColorizeMethod === "Durum" ? hesapBackgroundOverrideWithState(hesap) : hesapBackgroundOverrideWithOzgecmis(hesap);

            return (
                <IconButton style={background} key={hesap?.id ?? "ilk"} onClick={() => props.onClick ? props.onClick!(hesap) : {}}>
                    {GetInnerContent(hesap)}
                </IconButton>
            );
        });

        return (
            <Cell flex key={grup ? props.grupIsimGetir(grup) : "ilk"} style={{ borderRightWidth: 1, borderRightColor: props.darkSeperator ? appColors.GRAY_LIGHT2 : appColors.GRAY_LIGHT3, borderRightStyle: "solid" }}>
                <MyText style={{ textAlign: "center", fontSize: fontSize + 4 }}>{`${props.grupIsimGetir(grup!)} (${sesGrubununHesaplari.length.toString()})`}</MyText>
                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                    {viewList}
                </div>
            </Cell>
        );
    });

    return (
        <Rows flex>
            <Columns wrap>
                {anaContent}
            </Columns>
        </Rows>
    );
}

function getThumbnailSize(thumbnailSize: "small" | "normal" | "large" | "xlarge"): { innerWidth: number; innerHeight: number; fontSize: number } {
    switch (thumbnailSize) {
        case "small": return { innerHeight: 65, innerWidth: 45, fontSize: 10, };
        case "normal": return { innerHeight: 85, innerWidth: 65, fontSize: 12, };
        case "large": return { innerHeight: 110, innerWidth: 80, fontSize: 14, };
        case "xlarge": return { innerHeight: 180, innerWidth: 150, fontSize: 16, };
    }
}

export default OzelGrupluKisiAvatarListControl;
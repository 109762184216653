import { Box, Chip, FormControl, InputLabel, Select, SxProps, Theme, alpha } from "@mui/material";
import { CSSProperties } from "react";
import appColors from "../../app/appColors";
import { StringOrNumber } from "../../helpers/StringOrNumber";
import Columns from "../layout/Columns";

export interface IGenericListMultiSelectFieldProps<T, U extends StringOrNumber> {
    id?: string,
    value: U[] | null,
    label: string,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    onChange: (e: U[]) => void,
    error?: boolean,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
    small?: boolean,
    isItemInactive?: (e: T) => boolean,
    chipColorOverride?: (e: T) => string | undefined,
    isItemDisabled?: (e: U) => boolean,
    sticky?: boolean
}

export default function GenericListMultiSelectField<T, U extends StringOrNumber>(props: IGenericListMultiSelectFieldProps<T, U>) {
    const { small, sticky, isItemDisabled, isItemInactive, id, value, label, list, labelGetter, valueGetter, onChange, error, fontSize, sx, disabled, style } = props;
    const fontSizeToUse = fontSize ?? 14;

    const chipClicked = (newValue: T) => {
        const newIdList = value?.slice() ?? [];
        if (isSelected(newValue))
            newIdList.remove(valueGetter(newValue));
        else
            newIdList.push(valueGetter(newValue));

        onChange(newIdList);
    }

    const isSelected = (e: T) => (value?.contains(valueGetter(e)) ?? false);
    const isLight = (e: T) => isItemInactive ? isItemInactive(e) : false;

    const findColor = (e: T, forDisplay: boolean) => {
        const overrideColor = props.chipColorOverride ? props.chipColorOverride(e) : undefined;

        if (forDisplay) {
            if (overrideColor)
                return { background: alpha(overrideColor, 0.30), color: appColors.TEXT_PRIMARY };
            else if (isLight(e))
                return { background: alpha(appColors.BLACK, 0.03), color: appColors.TEXT_PRIMARY };
            else
                return { background: alpha(appColors.BLACK, 0.08), color: appColors.TEXT_PRIMARY };
        }
        else {
            if (isSelected(e)) {
                if (overrideColor)
                    return { background: alpha(overrideColor, 0.30), color: appColors.TEXT_PRIMARY };
                else if (isLight(e))
                    return { background: alpha(appColors.PRIMARY_LIGHT, 0.5), color: appColors.WHITE };
                else
                    return { background: appColors.PRIMARY_LIGHT, color: appColors.WHITE };
            }
            else {
                if (isLight(e))
                    return { background: alpha(appColors.GRAY_LIGHT3, 1), color: appColors.TEXT_PRIMARY };
                else
                    return { background: appColors.GRAY_LIGHT2, color: appColors.TEXT_PRIMARY };
            }
        }
    }

    return sticky ?
        <FormControl disabled={disabled} fullWidth style={{ marginTop: 0, ...style }} >
            <fieldset style={{ borderRadius: "4px", borderColor: error ? appColors.RED_MAIN : appColors.BLACK023, borderWidth: "1px" }}>
                <legend style={{ fontSize: "12px", fontWeight: 400, color: error ? appColors.RED_MAIN : appColors.BLACK060, }}>{label}</legend>
                <Columns>
                    {list.map(e => <Chip
                        disabled={isItemDisabled ? isItemDisabled(valueGetter(e)) : undefined}
                        key={valueGetter(e).toString()}
                        label={labelGetter(e)}
                        onClick={() => chipClicked(e)}
                        // color={isSelected(e) ? (isLight(e) ? "secondary" : "primary") : undefined}
                        style={{ ...findColor(e, false), margin: "5px", cursor: "pointer", display: "flex" }}
                    />
                    )}
                </Columns>
            </fieldset>
        </FormControl>
        :
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
            <Select
                multiple
                size={small ? "small" : undefined}
                disabled={disabled}
                sx={{ overflow: "hidden", ...sx }}
                inputProps={{ style: { paddingTop: "5px !important", paddingBottom: "5px !important", fontSize: fontSizeToUse } }}
                error={error}
                MenuProps={{ MenuListProps: { style: { display: "flex", flexWrap: "wrap" } } }}
                labelId={id}
                id={id}
                value={value}
                label={label}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.map((_value) => (
                            <Chip key={_value} style={{ ...findColor(list.single(e => valueGetter(e) === _value), true) }} label={labelGetter(list.single(e => valueGetter(e) === _value))?.toString().trim()} />
                        ))}
                    </Box>
                )}
                // onChange={(e) => onChange((e.target.value as U[]).where(e => !!e))}
            >
                {list.map(e => <Chip
                    disabled={isItemDisabled ? isItemDisabled(valueGetter(e)) : undefined}
                    key={valueGetter(e).toString()}
                    label={labelGetter(e)}
                    onClick={() => chipClicked(e)}
                    // color={isSelected(e) ? (isLight(e) ? "secondary" : "primary") : undefined}
                    style={{ ...findColor(e, false), margin: "5px", cursor: "pointer", display: "flex" }}
                />
                )}
            </Select>
        </FormControl>
}

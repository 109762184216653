/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import VatanTextField from "../../../components/common/VatanTextField";
import { RolModel } from "../../../models/businessModels/RolModel";
import KisiAvatarListControl from "../../../components/business/KisiAvatarListControl";
import { VatanAccordion, VatanAccordionGroup } from "../../../components/common/VatanAccordion";
import JsonHelper from "../../../helpers/JsonHelper";
import VatanCstListField from "../../../components/common/VatanCstListField";
import { EnmYetki } from "../../../models/enums/EnmYetki";

interface IRolPopupEditorProps {
    rolModel: RolModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: RolModel | null) => void,
}

function RolPopupEditor(props: IRolPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [rolModel, setRolModel] = useState<RolModel>(JsonHelper.deepCopy(props.rolModel));

    const tumRoller = genelDurumHelper.SeciliKoroModel.tumRoller;

    const updateRolModel = (action: (model: RolModel) => void) => {
        const result = JsonHelper.deepCopy(rolModel);
        action(result);
        setRolModel(result);
    }

    const hata = tumRoller.any(e => e.id !== rolModel.id && e.isim === rolModel.isim) ? "Bu isimde başka bir rol bulunmaktadır" : "";
    const hataVar = !!hata || !rolModel.isim;

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Rol.RolGuncelle(rolModel);
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Rol.RolEkle(rolModel);
            if (!webResponse.successfull)
                return;
        }

        closer(rolModel);
    }

    const kullananHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.rolIdList.contains(props.rolModel.id));

    function modifyList(e: EnmYetki[]): EnmYetki[] {
        const result = e.slice();
        if (result.contains(EnmYetki["Anket Yönetim"]) && !result.contains(EnmYetki["Anket İzleme"]))
            result.push(EnmYetki["Anket İzleme"]);
        if (result.contains(EnmYetki["Calışma Yönetim"]) && !result.contains(EnmYetki["Calışma İzleme"]))
            result.push(EnmYetki["Calışma İzleme"]);
        if (result.contains(EnmYetki["Duyuru Yönetim"]) && !result.contains(EnmYetki["Duyuru İzleme"]))
            result.push(EnmYetki["Duyuru İzleme"]);
        if (result.contains(EnmYetki["Şarkı Yönetim"]) && !result.contains(EnmYetki["Şarkı İzleme"]))
            result.push(EnmYetki["Şarkı İzleme"]);
        if (result.contains(EnmYetki["Kayıt Onaylama"]) && !result.contains(EnmYetki["Kayıt Kuartet İzleme"]))
            result.push(EnmYetki["Kayıt Kuartet İzleme"]);
        return result;
    }

    function disabledGetter(e: EnmYetki): boolean {
        if (e === EnmYetki["Anket İzleme"] && rolModel.yetkiList.contains(EnmYetki["Anket Yönetim"]))
            return true;
        if (e === EnmYetki["Calışma İzleme"] && rolModel.yetkiList.contains(EnmYetki["Calışma Yönetim"]))
            return true;
        if (e === EnmYetki["Duyuru İzleme"] && rolModel.yetkiList.contains(EnmYetki["Duyuru Yönetim"]))
            return true;
        if (e === EnmYetki["Şarkı İzleme"] && rolModel.yetkiList.contains(EnmYetki["Şarkı Yönetim"]))
            return true;
        if (e === EnmYetki["Kayıt Kuartet İzleme"] && rolModel.yetkiList.contains(EnmYetki["Kayıt Onaylama"]))
            return true;
        return false;
    }

    return (
        <Rows height={guncellemeMi ? 550 : 250} style={{ minWidth: 850, paddingTop: 20, display: "flex" }}>
            <VatanTextField
                disabled={!guncellenebilir}
                label="Rol İsmi"
                error={hataVar}
                helperText={hata}
                value={rolModel.isim}
                onChange={(e) => updateRolModel(t => t.isim = e)}
                eventOnKeyPress />
            <VatanCstListField
                disabled={!guncellenebilir}
                style={{ marginTop: 10 }}
                label="Yetkileri"
                value={rolModel.yetkiList}
                cstType={EnmYetki}
                onChange={(e) => updateRolModel(t => t.yetkiList = modifyList(e))}
                isItemDisabled={disabledGetter}
                id={rolModel.id} />

            <Rows flex style={{ marginTop: 10, overflowY: "auto" }}>
                {guncellemeMi &&
                    <VatanAccordionGroup initialExpandedKey="hesap" children={(onChange, expandedKey) => (
                        <>
                            {kullananHesaplar.length > 0 ?
                                <VatanAccordion expandedKey="hesap" title={`Grubu İçeren Hesaplar (${kullananHesaplar.length} hesap)`} expanded={expandedKey === "hesap"} onExpandedChanged={onChange} >
                                    <>
                                        <MyText bold>Grubu İçeren Hesaplar</MyText>
                                        <KisiAvatarListControl hesaplar={kullananHesaplar} size="normal" genelDurumHelper={genelDurumHelper} hideNames />
                                    </>
                                </VatanAccordion>
                                :
                                <MyText note italic>Grubu içeren hesap bulunmamaktadır</MyText>
                            }
                        </>
                    )} />
                }
            </Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default RolPopupEditor;
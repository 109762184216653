import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { MyListItem } from "../../../components/common/MyListItem";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { HesapSarkiSesKayitModel } from "../../../models/businessModels/HesapSarkiSesKayitModel";
import { HesapKuartetModel } from "../../../models/businessModels/HesapKuartetModel";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import AlertHelper from "../../../helpers/AlertHelper";
import { HesapKuartetListDurumGuncelleRequestModel } from "../../../models/requestModels/HesapKuartetListDurumGuncelleRequestModel";
import { HesapKuartetDurumGuncelleRequestModel } from "../../../models/requestModels/HesapKuartetDurumGuncelleRequestModel";
import JsonHelper from "../../../helpers/JsonHelper";
import { GuidHelper } from "../../../helpers/GuidHelper";

interface IKuartetSarkiPopupEditorProps {
    sarki: SarkiModel,
    kayitlar: HesapSarkiSesKayitModel[],
    kuartetler: HesapKuartetModel[],
    hesaplar: HesapBilgiVeKullaniciModel[],
    genelDurumHelper: GenelDurumHelper,
    closer: (response: HesapKuartetModel[] | null) => void,
}

function KuartetSarkiPopupEditor(props: IKuartetSarkiPopupEditorProps) {
    const { closer, genelDurumHelper } = props;

    const [kuartetBasariliOlanlar, setKuartetBasariliOlanlar] = useState<string[]>([]);
    const [kuartetBasarisizOlanlar, setKuartetBasarisizOlanlar] = useState<string[]>([]);
    const [kuartetYapmayanlar, setKuartetYapmayanlar] = useState<string[]>([]);


    const kuartetDurumGuncelle = async (sarki: SarkiModel, hesap: HesapBilgiVeKullaniciModel, sonDurum: boolean | null) => {
        const buttonTexts = ["Başarılı", "Başarısız", "Kuartet Yapmadı"];
        if (sonDurum === null)
            buttonTexts.remove("Kuartet Yapmadı");
        else if (sonDurum === true)
            buttonTexts.remove("Başarılı");
        else
            buttonTexts.remove("Başarısız");

        const confirmResult = await AlertHelper.ConfirmWithChoices(
            "Kuartet Sonucu",
            `${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad} isimli kişinin ${sarki.isim} eseri Kuartet sonucunu seçiniz`,
            buttonTexts
        );

        if (confirmResult == null)
            return;

        const result = confirmResult as string;

        const _kuartetBasariliOlanlar = kuartetBasariliOlanlar.slice();
        const _kuartetBasarisizOlanlar = kuartetBasarisizOlanlar.slice();
        const _kuartetYapmayanlar = kuartetYapmayanlar.slice();

        if (_kuartetBasariliOlanlar.contains(hesap.id))
            _kuartetBasariliOlanlar.remove(hesap.id);
        if (_kuartetBasarisizOlanlar.contains(hesap.id))
            _kuartetBasarisizOlanlar.remove(hesap.id);
        if (_kuartetYapmayanlar.contains(hesap.id))
            _kuartetYapmayanlar.remove(hesap.id);

        if (result === "Başarılı")
            _kuartetBasariliOlanlar.push(hesap.id);
        else if (result === "Başarısız")
            _kuartetBasarisizOlanlar.push(hesap.id);
        else //if (result === "Kuartet Yapmadı")
            _kuartetYapmayanlar.push(hesap.id);

        setKuartetBasariliOlanlar(_kuartetBasariliOlanlar);
        setKuartetBasarisizOlanlar(_kuartetBasarisizOlanlar);
        setKuartetYapmayanlar(_kuartetYapmayanlar);
    }

    const kuartetleriGercektenBildir = async () => {

        const modelToSend = new HesapKuartetListDurumGuncelleRequestModel([]);
        const kuartetResults: { hesapId: string, durum: boolean | null }[] = [];

        for (const hesapId of kuartetBasariliOlanlar)
            kuartetResults.push({ hesapId: hesapId, durum: true });
        for (const hesapId of kuartetBasarisizOlanlar)
            kuartetResults.push({ hesapId: hesapId, durum: false });
        for (const hesapId of kuartetYapmayanlar)
            kuartetResults.push({ hesapId: hesapId, durum: null });

        for (const kuartetResult of kuartetResults)
            modelToSend.kuartetler.push(new HesapKuartetDurumGuncelleRequestModel(props.sarki.id, kuartetResult.hesapId, kuartetResult.durum));


        const webResponse = await genelDurumHelper.requestHelper.HesapMuzikal.HesapKuartetListDurumGuncelle(modelToSend);
        if (!webResponse.successfull)
            return;

        const result = JsonHelper.deepCopy(props.kuartetler);

        for (const kuartetteBasariliOlan of kuartetBasariliOlanlar) {
            const found = result.singleOrDefault(e => e.hesapModelId === kuartetteBasariliOlan && e.sarkiModelId === props.sarki.id);
            if (found == null) {
                const modelToAdd = new HesapKuartetModel();
                modelToAdd.id = GuidHelper.NewGuid();
                modelToAdd.hesapModelId = kuartetteBasariliOlan;
                modelToAdd.sarkiModelId = props.sarki.id;
                modelToAdd.tarih = new Date();
                modelToAdd.kuartetBasarili = true;
                result.push(modelToAdd);
            }
            else
                found.kuartetBasarili = true;
        }

        for (const kuartetteBasarisizOlan of kuartetBasarisizOlanlar) {
            const found = result.singleOrDefault(e => e.hesapModelId === kuartetteBasarisizOlan && e.sarkiModelId === props.sarki.id);
            if (found == null) {
                const modelToAdd = new HesapKuartetModel();
                modelToAdd.id = GuidHelper.NewGuid();
                modelToAdd.hesapModelId = kuartetteBasarisizOlan;
                modelToAdd.sarkiModelId = props.sarki.id;
                modelToAdd.tarih = new Date();
                modelToAdd.kuartetBasarili = false;
                result.push(modelToAdd);
            }
            else
                found.kuartetBasarili = false;
        }

        for (const kuartetYapmayan of kuartetYapmayanlar) {
            const found = result.singleOrDefault(e => e.hesapModelId === kuartetYapmayan && e.sarkiModelId === props.sarki.id);
            if (found != null) {
                result.remove(found);
            }
        }

        closer(result);
    }

    let kuartetYapmayanIcerik: JSX.Element[] | JSX.Element = <MyListItem><MyText note>Kuartet yapmayan kullanıcı bulunmamaktadır</MyText></MyListItem>;
    let kuartetHataliIcerik: JSX.Element[] | JSX.Element = <MyListItem><MyText note>Kuartet hatası olan kullanıcı bulunmamaktadır</MyText></MyListItem>;
    let kuartetYapanIcerik: JSX.Element[] | JSX.Element = <MyListItem><MyText note>Kuartet yapan kullanıcı bulunmamaktadır</MyText></MyListItem>;
    let kuartetKayitGondermeyenIcerik: JSX.Element[] | JSX.Element = <MyListItem><MyText note>Kayıt göndermeyen kullanıcı bulunmamaktadır</MyText></MyListItem>;

    const tumKayitlar = props.kayitlar.where(e => e.sarkiModelId === props.sarki.id);
    const tumKuartetler = props.kuartetler.where(e => e.sarkiModelId === props.sarki.id);

    const kuartetYapmayanKullanicilar: HesapBilgiVeKullaniciModel[] = [];
    const kuartetHataliKullanicilar: HesapBilgiVeKullaniciModel[] = [];
    const kuartetYapanKullanicilar: HesapBilgiVeKullaniciModel[] = [];
    const kuartetKayitGondermeyenKullanicilar: HesapBilgiVeKullaniciModel[] = [];

    const kullanicilar = props.hesaplar.where(e => e.aktif === true && !e.araVerdi && BusinessHelper.Genel.KoristMi(e));

    kullanicilar.forEach((hesap) => {
        const kayitlar = tumKayitlar.where(e => e.hesapModelId === hesap.id);
        const kuartet = tumKuartetler.singleOrDefault(e => e.hesapModelId === hesap.id);
        const uygunKayitVar = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar) === EnmKayitDurum.Onaylandı;

        // ön listeye bak orda varsa, diğer kısmı sallama
        if (kuartetBasariliOlanlar.contains(hesap.id)) {
            kuartetYapanKullanicilar.push(hesap);
        }
        else if (kuartetBasarisizOlanlar.contains(hesap.id)) {
            kuartetHataliKullanicilar.push(hesap);
        }
        else if (kuartetYapmayanlar.contains(hesap.id)) {
            if (uygunKayitVar)
                kuartetYapmayanKullanicilar.push(hesap);
            else
                kuartetKayitGondermeyenKullanicilar.push(hesap);
        }
        else {

            // normal listeye bak
            if (kuartet == null) {
                if (uygunKayitVar)
                    kuartetYapmayanKullanicilar.push(hesap);
                else
                    kuartetKayitGondermeyenKullanicilar.push(hesap);
            }
            else if (kuartet.kuartetBasarili === true)
                kuartetYapanKullanicilar.push(hesap);
            else
                kuartetHataliKullanicilar.push(hesap);
        }
    });

    // Kuartet
    if (kuartetYapmayanKullanicilar.length > 0)
        kuartetYapmayanIcerik = (
            <SesGrupluKisiAvatarListControl
                tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
                gosterilecekHesaplar={kuartetYapmayanKullanicilar}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                onClick={(hesap) => kuartetDurumGuncelle(props.sarki, hesap, null)}
                genelDurumHelper={genelDurumHelper} />
        );

    if (kuartetHataliKullanicilar.length > 0) {
        kuartetHataliIcerik = (
            <SesGrupluKisiAvatarListControl
                tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
                gosterilecekHesaplar={kuartetHataliKullanicilar}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                onClick={(hesap) => kuartetDurumGuncelle(props.sarki, hesap, false)}
                genelDurumHelper={genelDurumHelper} />
        );
    }
    if (kuartetKayitGondermeyenKullanicilar.length > 0) {
        kuartetKayitGondermeyenIcerik = (
            <SesGrupluKisiAvatarListControl
                tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
                gosterilecekHesaplar={kuartetKayitGondermeyenKullanicilar}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                onClick={(hesap) => kuartetDurumGuncelle(props.sarki, hesap, null)}
                genelDurumHelper={genelDurumHelper} />
        );
    }
    if (kuartetYapanKullanicilar.length > 0) {
        kuartetYapanIcerik = (
            <SesGrupluKisiAvatarListControl
                tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && BusinessHelper.Genel.KoristMi(e))}
                gosterilecekHesaplar={kuartetYapanKullanicilar}
                sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                onClick={(hesap) => kuartetDurumGuncelle(props.sarki, hesap, true)}
                genelDurumHelper={genelDurumHelper} />
        );
    }

    return (
        <Rows height={550} style={{ minWidth: 890, display: "flex" }}>
            <Rows flex>
                <MyListItem itemDividerText="Kuartet Yapmayanlar" />
                {kuartetYapmayanIcerik}
                <MyListItem itemDividerText="Kuartet Hatası Olanlar" />
                {kuartetHataliIcerik}
                <MyListItem itemDividerText="Kuartet Yapanlar" />
                {kuartetYapanIcerik}
                <MyListItem itemDividerText="Kayıt Göndermeyenler" />
                {kuartetKayitGondermeyenIcerik}
            </Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kuartetleriGercektenBildir()} disabled={!(kuartetBasariliOlanlar.length > 0 || kuartetBasarisizOlanlar.length > 0 || kuartetYapmayanlar.length > 0)} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );


}

export default KuartetSarkiPopupEditor;
/* eslint-disable react/no-children-prop */
// cihan
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import SarkiBilgileriPopupEditor from "./SarkiBilgileriPopupEditor";
import SarkiLinkListPopupEditor from "./SarkiLinkListPopupEditor";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import { mainScreenLocations } from "../../../app/mainScreens";
import VatanTextField from "../../../components/common/VatanTextField";
import Cell from "../../../components/layout/Cell";
import { GuidHelper } from "../../../helpers/GuidHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import { MyText } from "../../../components/common/MyText";
import appColors from "../../../app/appColors";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import StringHelper from "../../../helpers/StringHelper";

function SarkiYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);

  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  });

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SarkiYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function SarkiYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");

  const yonetimYetkisi = genelDurumHelper.HasYetkiAll([EnmYetki["Şarkı Yönetim"]]);

  const sarkiRowGetir = (sarkiList: SarkiModel[], siraNoGoster: boolean): JSX.Element[] | null => {
    if (sarkiList.length === 0)
      return null;

    return sarkiList.map((item) => {
      const durum = item.kayitGondermeSurecinde ? "Kayıt Sürecince" : "Onaylı";
      const durumStyle = item.kayitGondermeSurecinde ? { color: appColors.RED } : { color: appColors.RED_DARK };

      const kayitSureciAcilabilir = !item.kayitGondermeSurecinde;
      const kayitSureciKapanabilir = item.kayitGondermeSurecinde;

      const rightButtons: ICustomSwipeButton[] = [];
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, text: "Güncelle", onClick: () => sarkiBilgiGuncelle(item, yonetimYetkisi, yonetimYetkisi), color: "success" });
      rightButtons.push({ icon: appIcons.Sarki.link, text: "Linkler", onClick: () => sarkiLinkListGuncelle(item, yonetimYetkisi, yonetimYetkisi), color: "success" });

      if (yonetimYetkisi) {
        rightButtons.push({ icon: appIcons.CrudIcons.sil, text: "Sil", onClick: () => sarkiSil(item.id), color: "error" });
        if (kayitSureciAcilabilir)
          rightButtons.push({ icon: appIcons.Sarki.kayitAc, text: "Kayıt Aç", onClick: () => sarkiKayitAc(item.id), color: "warning" });
        if (kayitSureciKapanabilir)
          rightButtons.push({ icon: appIcons.Sarki.kayitKapat, text: "Kayıt Kapat", onClick: () => sarkiKayitKapat(item.id), color: "error" });
      }

      const sarkiIsim = (siraNoGoster ? `${item.sira}. ` : "") + `${item.isim} (${BusinessHelper.SesGrubu.IdListtenSATBUret(genelDurumHelper.SeciliKoroModel.tumSesGruplari, item.sesGrupIdList)})`;
      return (
        <MyListItem key={item.id} width={550}>
          <div style={{ height: 40, width: "500px" }}>
            <Columns hasLightBottomBorder>
              {rightButtons.map((item) =>
                <Button key={item.text} variant="text" title={item.text} color={item.color} style={{ minWidth: 35, paddingLeft: 0, paddingRight: 0 }} onClick={() => item.onClick!()}>
                  {item.icon}
                </Button>
              )}
              <Rows width={300}>
                <Typography noWrap style={{ overflow: "hidden" }}>{sarkiIsim}</Typography>
                <Typography style={{ fontSize: "12px", fontStyle: "italic", ...durumStyle }}>Durum: {durum} <span style={{ color: appColors.TEXT_PRIMARY }}> ({item.sarkiLinkModelList.length} Link)</span></Typography>
              </Rows>
            </Columns>
          </div>
        </MyListItem>
      );
    });
  }

  const sarkiBilgiGuncelle = async (sarkiModel: SarkiModel, guncellenebilir: boolean, silinebilir: boolean) => {
    const response = await AlertHelper.ShowClosableContent<SarkiModel>(genelDurumHelper.translate("Şarkısı Güncelle", { sarkiIsim: sarkiModel.isim }), (closer) =>
      <SarkiBilgileriPopupEditor
        guncellenebilir={guncellenebilir}
        silinebilir={silinebilir}
        guncellemeMi={true}
        sarki={sarkiModel}
        tumSarkilar={genelDurumHelper.SeciliKoroModel.tumSarkilar}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarkilar = seciliKoro.tumSarkilar;

        sarkilar.remove(sarkilar.single(e => e.id === response!.id));
        sarkilar.push(response!);
      });
  };

  const sarkiLinkListGuncelle = async (sarki: SarkiModel, guncellenebilir: boolean, silinebilir: boolean) => {
    const response = await AlertHelper.ShowClosableContent<WithContentRequestModel<SarkiLinkModel[]>>(`${sarki.isim} Şarkısının Bağlantılarını Güncelle`, (closer) =>
      <SarkiLinkListPopupEditor
        guncellenebilir={guncellenebilir}
        silinebilir={silinebilir}
        sarki={sarki}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;

        const sarkiOnYonetim = seciliKoro.tumSarkilar.single(e => e.id === sarki.id);

        sarkiOnYonetim.sarkiLinkModelList = response!.model!.slice();
      });
  }

  const sarkiEkle = async () => {
    const sarkiModel = new SarkiModel();
    sarkiModel.id = GuidHelper.NewGuid();
    sarkiModel.isim = "";
    sarkiModel.aktif = false;
    sarkiModel.tarih = "";
    sarkiModel.sure = 0;
    sarkiModel.tempo = "";
    sarkiModel.sozYazari = "";
    sarkiModel.besteci = "";
    sarkiModel.duzenleme = "";
    sarkiModel.kisaAciklama = "";
    sarkiModel.uzunAciklama = "";
    sarkiModel.kisaIngilizceAciklama = "";
    sarkiModel.uzunIngilizceAciklama = "";
    sarkiModel.sira = 0
    sarkiModel.olcuSayisi = "";
    sarkiModel.dil = "";
    sarkiModel.donem = "";
    sarkiModel.zorluk = "";
    sarkiModel.ekYorum = "";
    sarkiModel.eslik = "";
    sarkiModel.kayitGondermeSurecinde = false;
    sarkiModel.sarkiLinkModelList = [];
    sarkiModel.sesGrupIdList = [];
    sarkiModel.sezonSarkiModelList = [];
    sarkiModel.adaySezonModelIdList = [];
    sarkiModel.tarzModelIdList = [];
    sarkiModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    sarkiModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
    sarkiModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<SarkiModel>(`${sarkiModel.isim} Şarkısını Güncelle`, (closer) =>
      <SarkiBilgileriPopupEditor
        guncellenebilir={true}
        silinebilir={false}
        guncellemeMi={false}
        sarki={sarkiModel}
        tumSarkilar={genelDurumHelper.SeciliKoroModel.tumSarkilar}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarkilar = seciliKoro.tumSarkilar

        sarkilar.push(response!);
      });
  }

  const sarkiSil2 = async (_id: string) => {
    const response = await genelDurumHelper.requestHelper.Koro.TopluIslem(GuidHelper.Empty);
    if (!response.successfull)
      return;
  }

  const sarkiSil = async (id: string) => {
    if (await AlertHelper.Confirm("Dikkat", "Şarkıyı silmek istediğinizden emin misiniz?") === false)
      return;

    const response = await genelDurumHelper.requestHelper.Sarki.SarkiSil(id);
    if (!response.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarkiList = seciliKoro.tumSarkilar;
        sarkiList.remove(sarkiList.single(e => e.id === id));
      });
  }

  const sarkiKayitAc = async (id: string) => {
    const response = await genelDurumHelper.requestHelper.Sarki.SarkiKayitSureciAc(id);
    if (!response.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarki = seciliKoro.tumSarkilar.single(e => e.id === id);
        sarki.kayitGondermeSurecinde = true;
      });
  }

  const sarkiKayitKapat = async (id: string) => {
    const response = await genelDurumHelper.requestHelper.Sarki.SarkiKayitSureciKapat(id);
    if (!response.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarki = seciliKoro.tumSarkilar.single(e => e.id === id);
        sarki.kayitGondermeSurecinde = false;
      });
  }

  let sarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
  if (searchFilter !== "") {
    if (searchFilter === "nogenre") {
      sarkilar = sarkilar.where(e => e.tarzModelIdList.length === 0);
    }
    else {
      const searchFilterLower = searchFilter.toLocaleLowerCase();
      sarkilar = sarkilar.where(e =>
        e.isim.toLocaleLowerCase().indexOf(searchFilterLower) > -1 ||
        StringHelper.nullToEmpty(BusinessHelper.Tarz.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumTarzlar, e.tarzModelIdList))
          .toLocaleLowerCase()?.indexOf(searchFilterLower) > -1);
    }
  }

  const tumSarkilar = sarkilar.orderByTurkish(e => e.isim);
  const sezonSarkilari = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id));
  const sezonDisiSarkilar = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id) === false);

  const guncelSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === true).orderBy(e => e.sira);
  const bekleyenSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === false).orderBy(e => e.sira);

  const eskiSarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length > 0).orderByTurkish(e => e.isim);
  const digerSarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length === 0).orderByTurkish(e => e.isim);

  return (
    <MainLayout
      location={mainScreenLocations.sarkiYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns style={{ margin: "10px" }}>
          <Cell width={300}>
            <VatanTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell flex>
          </Cell>
          <Cell>
            <Button disabled={!yonetimYetkisi} onClick={() => sarkiEkle()}>
              {appIcons.CrudIcons.ekle}
              <Typography>Yeni Şarkı Ekle</Typography>
            </Button>
          </Cell>
        </Columns>
        <VatanAccordionSimple
          title="Aktif Şarkılar"
          children={sarkiRowGetir(guncelSarkilar, true) ?? <MyText note>Aktif şarkı bulunmamaktadır</MyText>}
          alwaysOpen
        />
        <VatanAccordionSimple
          title="Bekleyen Şarkılar"
          children={sarkiRowGetir(bekleyenSarkilar, true) ?? <MyText note>Bekleyen şarkı bulunmamaktadır</MyText>}
          alwaysOpen
        />
        <VatanAccordionSimple
          title="Eski Şarkılar"
          children={sarkiRowGetir(eskiSarkilar, false) ?? <MyText note>Eski şarkı bulunmamaktadır</MyText>}
          alwaysOpen
        />
        <VatanAccordionSimple
          title="Diğer Şarkılar"
          children={sarkiRowGetir(digerSarkilar, false) ?? <MyText note>Diğer şarkı bulunmamaktadır</MyText>}
          alwaysOpen
        />
      </Rows>
    </MainLayout>
  );
}

export default SarkiYonetimScreen;
import Columns, { ColumnsCSSProperties } from "../layout/Columns";
import { MyText } from "../common/MyText";
import { CalismaModel } from "../../models/businessModels/CalismaModel";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import appColors from "../../app/appColors";
import { Button } from "@mui/material";
import Rows from "../layout/Rows";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

interface ICalismaAvatarListControlProps {
    calismalar: CalismaModel[],
    onClick?: (calisma: CalismaModel) => void,
    style?: ColumnsCSSProperties,
    genelDurumHelper: GenelDurumHelper
}

function CalismaAvatarListControl(props: ICalismaAvatarListControlProps) {
    const getInnerContent = (calisma: CalismaModel) => {
        return (
            <div key={calisma.id} style={{ borderRadius: 5, width: 165, padding: 10, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <MyText style={{ textAlign: "center", fontSize: 13 }}>{DateTimeHelper.toMonthDayAndYearAndDayOfWeekString(calisma.tarihBaslangic, props.genelDurumHelper.translate)}</MyText>
                <MyText style={{ textAlign: "center", fontSize: 11 }} note>{calisma.icerik}</MyText>
            </div>
        );
    }

    const getButtonContent = (calisma: CalismaModel) => {
        return (
            <div key={calisma.id} style={{ borderRadius: 5, width: 187, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <Button fullWidth key={calisma.id} variant="text" onClick={() => props.onClick!(calisma)} sx={{ padding: "10px", textTransform: "none" }}>
                    <Rows>
                        <MyText style={{ textAlign: "center", fontSize: 13 }}>{DateTimeHelper.toMonthDayAndYearAndDayOfWeekString(calisma.tarihBaslangic, props.genelDurumHelper.translate)}</MyText>
                        <MyText style={{ textAlign: "center", fontSize: 11 }} note>{calisma.icerik}</MyText>
                    </Rows>
                </Button>
            </div>
        );
    }

    return (
        <Columns wrap style={props.style}>
            {props.calismalar.map(item => props.onClick ? getButtonContent(item) : getInnerContent(item))}
        </Columns>
    )
}

export default CalismaAvatarListControl;
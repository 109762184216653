import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { RolModel } from "../../../models/businessModels/RolModel";
import RolPopupEditor from "./RolPopupEditor";
import { GuidHelper } from "../../../helpers/GuidHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import appColors from "../../../app/appColors";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function RolYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <RolYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function RolYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const rolRowGetir = (rolList: RolModel[]): JSX.Element[] => {
    return rolList.map((item) => {
      const koristCount = genelDurumHelper.SeciliKoroModel.hesapBilgiList.count(e => e.rolIdList.contains(item.id));
      const altBilgi = `${koristCount} korist`;

      const silinebilir = item.ontanimli === false;
      const guncellenebilir = item.ontanimli === false;
      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => rolSil(item, silinebilir) });
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => rolGuncelle(item, guncellenebilir) });

      return (
        <MyListItem leftButtons={rightButtons} key={item.id} style={{ width: "400px" }}>
          <Rows>
            <MyText style={{ alignSelf: "flex-start" }}>{item.isim}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi}</MyText>
          </Rows>
        </MyListItem>
      );
    });
  }

  const rolEkle = async () => {
    const rolModel = new RolModel();
    rolModel.id = GuidHelper.NewGuid();
    rolModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    rolModel.isim = "";
    rolModel.yetkiList = [];
    rolModel.ontanimli = false;
    rolModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    rolModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<RolModel>("Rol Ekle", (closer) => (
      <RolPopupEditor
        rolModel={rolModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const roller = seciliKoro.tumRoller;
        roller.push(response!);
      });
  }

  const rolSil = async (rolModel: RolModel, silinebilir: boolean): Promise<void> => {
    if (!silinebilir) {
      AlertHelper.ShowMessage("Dikkat", "Ön tanımlı roller silinemez");
      return;
    }

    if (await AlertHelper.Confirm("Dikkat", `${rolModel.isim} isimli gruubu silme istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Rol.RolSil(rolModel.id);
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const roller = seciliKoro.tumRoller;
        roller.remove(roller.single(e => e.id === rolModel.id));
      });
  }

  const rolGuncelle = async (rolModel: RolModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<RolModel>("Rol Güncelle", (closer) => (
      <RolPopupEditor
        guncellenebilir={guncellenebilir}
        rolModel={rolModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const roller = seciliKoro.tumRoller;
        roller.remove(roller.single(e => e.id === response!.id));
        roller.push(response!);
      });
  }

  const tumRoller = genelDurumHelper.SeciliKoroModel.tumRoller.orderByTurkish(e => e.isim);

  let ontanimliRolIcerik: JSX.Element[] | JSX.Element = <ListItem><MyText note>Öntanımlı rol bulunmamaktadır</MyText></ListItem>;
  let ozelRolIcerik: JSX.Element[] | JSX.Element = <ListItem><MyText note>Özel rol bulunmamaktadır</MyText></ListItem>;

  const ontanimliRoller = tumRoller.where(e => e.ontanimli === true);
  const ozelRoller = tumRoller.where(e => e.ontanimli === false);

  if (ontanimliRoller.length > 0) {
    ontanimliRolIcerik = rolRowGetir(ontanimliRoller);
  }
  if (ozelRoller.length > 0) {
    ozelRolIcerik = rolRowGetir(ozelRoller);
  }

  return (
    <MainLayout
      location={mainScreenLocations.rolYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "500px",
        '&:last-child td, &:last-child th': { border: 0 },
        '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
      }}>
        <ListItem>
          <Button onClick={() => rolEkle()}>
            {appIcons.CrudIcons.ekle}
            <Typography>Yeni Rol Ekle</Typography>
          </Button>
        </ListItem>
        <MyListItem itemDividerText="Öntanımlı Roller" />
        {ontanimliRolIcerik}
        <MyListItem itemDividerText="Özel Roller" />
        {ozelRolIcerik}
      </List>
    </MainLayout>
  );
}

export default RolYonetimScreen;
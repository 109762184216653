/* eslint-disable @typescript-eslint/no-empty-function */
// cihan
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface ICustomYesNoDialogProps {
  titleMessage: string;
  contentMessage: string;
  open: boolean;
  onClose: (() => void) | null;
  onYesClick: (() => void) | null;
}

export default function CustomYesNoDialog(props: ICustomYesNoDialogProps) {
  return props.open === true ? (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={props.onClose != null ? props.onClose : (() => {})}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.titleMessage}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.contentMessage.split("\n").map(e => (
            <span key={e}>{e}<br /></span>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose != null ? props.onClose : (() => {})} color="primary" autoFocus>
          HAYIR
        </Button>
        <Button onClick={props.onYesClick != null ? props.onYesClick : (() => {})} color="primary">
          EVET
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

/* eslint-disable react/no-children-prop */
// cihan
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { Button, Chip } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import VatanTextField from "../../../components/common/VatanTextField";
import { TarzModel } from "../../../models/businessModels/TarzModel";
import { VatanAccordion, VatanAccordionGroup } from "../../../components/common/VatanAccordion";
import JsonHelper from "../../../helpers/JsonHelper";

interface ITarzPopupEditorProps {
    tarzModel: TarzModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: TarzModel | null) => void,
}

function TarzPopupEditor(props: ITarzPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [tarzModel, setTarzModel] = useState<TarzModel>(JsonHelper.deepCopy(props.tarzModel));

    const tumTarzlar = genelDurumHelper.SeciliKoroModel.tumTarzlar;

    const updateTarzModel = (action: (model: TarzModel) => void) => {
        const result = JsonHelper.deepCopy(tarzModel);
        action(result);
        setTarzModel(result);
    }

    const hata = tumTarzlar.any(e => e.id !== tarzModel.id && e.isim === tarzModel.isim) ? "Bu isimde başka bir tarz bulunmaktadır" : "";
    const hataVar = !!hata || !tarzModel.isim;

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Tarz.TarzGuncelle(tarzModel);
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Tarz.TarzEkle(tarzModel);
            if (!webResponse.successfull)
                return;
        }

        closer(tarzModel);
    }

    const kullananSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.tarzModelIdList.contains(props.tarzModel.id));

    return (
        <Rows height={guncellemeMi ? 550 : 250} style={{ minWidth: 850, paddingTop: 20, display: "flex" }}>
            <VatanTextField
                disabled={!guncellenebilir}
                label="Tarz İsmi"
                error={hataVar}
                helperText={hata}
                value={tarzModel.isim}
                onChange={(e) => updateTarzModel(t => t.isim = e)}
                appendMaxlenOnLabel={50}
                eventOnKeyPress />
            <VatanTextField
                disabled={!guncellenebilir}
                label="Tarz Açıklaması"
                value={tarzModel.aciklama}
                onChange={(e) => updateTarzModel(t => t.aciklama = e)}
                appendMaxlenOnLabel={1000}
                eventOnKeyPress />

            <Rows flex style={{ marginTop: 10, overflowY: "auto" }}>
                {guncellemeMi &&
                    <VatanAccordionGroup initialExpandedKey="hesap" children={(onChange, expandedKey) => (
                        <>
                            {kullananSarkilar.length > 0 ?
                                <VatanAccordion expandedKey="hesap" title={`Grubu İçeren Hesaplar (${kullananSarkilar.length} hesap)`} expanded={expandedKey === "hesap"} onExpandedChanged={onChange} >
                                    <>
                                        <MyText bold>Grubu İçeren Şarkılar</MyText>
                                        <Columns wrap>
                                        {kullananSarkilar.map(item => <Chip key={item.id} label={item.isim} />)}
                                        </Columns>
                                    </>
                                </VatanAccordion>
                                :
                                <MyText note italic>Grubu içeren hesap bulunmamaktadır</MyText>
                            }
                        </>
                    )} />
                }
            </Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default TarzPopupEditor;
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { GuidHelper } from "../../../helpers/GuidHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { KoroVeHesapModel } from "../../../models/businessModels/KoroVeHesapModel";
import KorocanImage from "../../../components/business/KorocanImage";
import Columns from "../../../components/layout/Columns";
import Cell from "../../../components/layout/Cell";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { KoroModel } from "../../../models/businessModels/KoroModel";
import { KullaniciModel } from "../../../models/businessModels/KullaniciModel";
import KoroPopupEditor from "./KoroPopupEditor";
import { KoroVeHesapWithContentRequestModel } from "../../../models/requestModels/KoroVeHesapWithContentRequestModel";
import KullanilmayanIcerikListPopupEditor from "./KullanilmayanIcerikListPopupEditor";
import appColors from "../../../app/appColors";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function KoroYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KoroYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function KoroYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const koroRowListGetir = (koro: KoroVeHesapModel): JSX.Element => {

    const rightButtons: ICustomSwipeButton[] = [];

    rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => koroGuncelle(koro), text: "Güncelle" });
    rightButtons.push({ icon: appIcons.ScreenIcons.koroAyarlari, color: "primary", onClick: () => kullanilmayanIcerikAc(koro), text: "Kullanılmayan İçerikler" });
    rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => koroSil(koro), text: "Sil" });

    return <MyListItem width={800} rightButtons={rightButtons} key={koro.koroModel.id}>
      <Columns flex>
        <Cell width={250}>
          <KorocanImage title={koro.koroModel.isim} icerikId={koro.koroModel.resimIcerikId} genelDurumHelper={genelDurumHelper} style={{ maxHeight: "100px", maxWidth: "200px" }} />
        </Cell>
        <Rows style={{ justifyContent: "center" }}>
          <MyText header>{koro.koroModel.isim}</MyText>
          <MyText>{koro.hesapModel.kullaniciModel.ad} {koro.hesapModel.kullaniciModel.soyad}</MyText>
          <MyText note>{koro.hesapModel.kullaniciModel.kullaniciAdi}</MyText>
        </Rows>
        <Cell flex></Cell>
      </Columns>
    </MyListItem>
  }

  const koroEkle = async () => {
    const koroModel = new KoroVeHesapModel();
    koroModel.koroModel = new KoroModel();
    koroModel.hesapModel = new HesapBilgiVeKullaniciModel();
    koroModel.hesapModel.kullaniciModel = new KullaniciModel();

    koroModel.koroModel.id = GuidHelper.NewGuid();
    koroModel.koroModel.isim = ""
    koroModel.koroModel.resimIcerikId = null;
    koroModel.koroModel.aktif = false;
    koroModel.koroModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
    koroModel.koroModel.olusturmaTarih = DateTimeHelper.today();

    koroModel.hesapModel.kullaniciModel.id = GuidHelper.NewGuid();
    koroModel.hesapModel.kullaniciModel.kullaniciAdi = ""
    koroModel.hesapModel.kullaniciModel.cinsiyet = null;
    koroModel.hesapModel.kullaniciModel.ad = "";
    koroModel.hesapModel.kullaniciModel.soyad = "";
    koroModel.hesapModel.kullaniciModel.dogumTarihi = new Date(1980, 1, 1);
    koroModel.hesapModel.kullaniciModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
    koroModel.hesapModel.kullaniciModel.olusturmaTarih = koroModel.koroModel.olusturmaTarih;

    koroModel.hesapModel.id = GuidHelper.NewGuid();
    koroModel.hesapModel.kullaniciModelId = koroModel.hesapModel.kullaniciModel.id;
    koroModel.hesapModel.aktif = true;
    koroModel.hesapModel.resimIcerikId = null;
    koroModel.hesapModel.kadroVeSesGrubuBilgiModelList = [];
    koroModel.hesapModel.sesGrupIdList = [];
    koroModel.hesapModel.rolIdList = [];
    koroModel.hesapModel.superAdmin = true;
    koroModel.hesapModel.koroModelId = koroModel.koroModel.id;
    koroModel.hesapModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
    koroModel.hesapModel.olusturmaTarih = koroModel.koroModel.olusturmaTarih;

    const model = new KoroVeHesapWithContentRequestModel(koroModel, []);

    const response = await AlertHelper.ShowClosableContent<KoroVeHesapWithContentRequestModel>("Koro Ekle", (closer) => (
      <KoroPopupEditor
        koro={model}
        tumKorolar={genelDurumHelper.SeciliKoroModel.koroVeHesapModelList}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const korolar = seciliKoro.koroVeHesapModelList;
        korolar.push(response!.model);
      });
  }

  const koroGuncelle = async (koroModel: KoroVeHesapModel): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<KoroVeHesapWithContentRequestModel>("Koro Güncelle", (closer) => (
      <KoroPopupEditor
        koro={new KoroVeHesapWithContentRequestModel(koroModel, [])}
        tumKorolar={genelDurumHelper.SeciliKoroModel.koroVeHesapModelList}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const korolar = seciliKoro.koroVeHesapModelList;
        const foundKoro = korolar.single(e => e.koroModel.id === koroModel.koroModel.id);

        korolar.remove(foundKoro);
        korolar.push(response!.model);
      });
  }

  const kullanilmayanIcerikAc = async (koroModel: KoroVeHesapModel): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<boolean>("Kullanılmayan İçerikler", (closer) => (
      <KullanilmayanIcerikListPopupEditor
        koroId={koroModel.koroModel.id}
        closer={closer}
        genelDurumHelper={genelDurumHelper} />
    ));

    if (response == null)
      return;
  }

  const koroSil = async (koroModel: KoroVeHesapModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", `Duyuruyu silmek istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Koro.KoroSil(koroModel.koroModel.id);
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const korolar = seciliKoro.koroVeHesapModelList;
        korolar.remove(korolar.single(e => e.koroModel.id === koroModel.koroModel.id));
      });
  }

  const allKoroList = genelDurumHelper.SeciliKoroModel.koroVeHesapModelList;

  return (
    <MainLayout
      location={mainScreenLocations.koroYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "800px",
        '&:last-child td, &:last-child th': { border: 0 },
        '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
      }}>
        <ListItem>
          <Button onClick={() => koroEkle()}>
            <Typography>Yeni Koro Ekle</Typography>
            {appIcons.CrudIcons.ekle}
          </Button>
        </ListItem>
        <Rows>
          {allKoroList.map(koro => koroRowListGetir(koro))}
        </Rows>
      </List>
    </MainLayout>
  );
}

export default KoroYonetimScreen;


/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, IconButton, Link, List, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import Cell from "../../../components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import Columns from "../../../components/layout/Columns";
import appColors from "../../../app/appColors";
import { useScreenWidth } from "../../../components/core/useScreenWidth";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import Rows from "../../../components/layout/Rows";
import SortableMetaListPopupEditor from "./SortableMetaListPopupEditor";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import VatanMetaFilterEditor from "../../../components/vatan/VatanMetaFilterEditor";
import { MetaSearchField } from "../../../helpers/MetaSearchField";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import SarkiBilgileriPopupEditor from "../../yonetim/sarki/SarkiBilgileriPopupEditor";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import PdfDisplayer from "../../../components/media/PdfDisplayer";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import GenericTwoBoxListMultiSelectField from "../../../components/common/GenericTwoBoxListMultiSelectField";
import StringHelper from "../../../helpers/StringHelper";

function SarkiMetaAnalizScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);
  const metaList = BusinessHelper.SarkiMeta.MetaSearchFields(genelDurumHelper);

  return <SarkiMetaAnalizScreenScreenInner metaList={metaList} genelDurumHelper={genelDurumHelper} />
}

function SarkiMetaAnalizScreenScreenInner(props: IMainScreenProps & { metaList: MetaSearchField[] }) {
  const { metaList, genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;

  const screenWidth = useScreenWidth();
  const isSmallScreen = screenWidth === "Max600"
  const isSmallOrMediumScreeen = isSmallScreen || screenWidth === "Max900";

  const selectableMetaFields = BusinessHelper.SarkiMeta.MetaPairs.orderByTurkish(e => e.isim);
  const [selectedMetaFieldNames, setSelectedMetaFieldNames] = useState<string[]>(["Süre", "Besteci", "Düzenleme", "Dönem", "Dil", "Uzun Açıklama"]);
  const [filterValues, setFilterValues] = useState<MetaSearchField[]>(metaList.slice());
  const [sortByMeta, setSortByMeta] = useState<string>("");

  let secilenSarkilar = BusinessHelper.SarkiMeta.Filter(tumSarkilar, filterValues).orderByTurkish(e => e.isim);

  if (sortByMeta !== "")
    secilenSarkilar = secilenSarkilar.orderByTurkish(e => BusinessHelper.SarkiMeta.MetaPairs.single(e => e.isim === sortByMeta).field(e, genelDurumHelper))
  // end of filtreleme

  function sarkiPdfGoster(sarki: SarkiModel): void {
    const linkModel = sarki.sarkiLinkModelList.singleOrDefault(e => e.linkTip === EnmSarkiLinkTip.PDF && (StringHelper.nullToEmpty(e.aciklama)).isNullOrEmpty());
    if (linkModel)
      AlertHelper.ShowContent(sarki.isim, <PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={linkModel.icerikId!} />)
    else
      AlertHelper.ShowMessage("Dikkat", "Bu eserin PDF içeriği bulunmamaktadır");
  }

  async function sarkiGuncelleBaslat(sarki: SarkiModel) {
    const response = await AlertHelper.ShowClosableContent<SarkiModel>(`${sarki.isim} Şarkısını Güncelle`, (closer) =>
      <SarkiBilgileriPopupEditor
        guncellenebilir={true}
        silinebilir={true}
        guncellemeMi={true}
        sarki={sarki}
        tumSarkilar={genelDurumHelper.SeciliKoroModel.tumSarkilar}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    );

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const sarkilar = seciliKoro.tumSarkilar;

        sarkilar.remove(sarkilar.single(e => e.id === response!.id));
        sarkilar.push(response!);
      });
  }

  const onMetaEkleCikartClicked = async () => {
    const response = await AlertHelper.ShowClosableContent<string[]>("Meta Seçimi", closer => <Rows>
      <SortableMetaListPopupEditor
        itemLabel="meta"
        label="Metalar"
        value={selectedMetaFieldNames}
        list={selectableMetaFields}
        labelGetter={e => e.isim}
        valueGetter={e => e.isim}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    </Rows>);

    if (response == null)
      return;

    setSelectedMetaFieldNames(response);
  }

  function emptyToDash(s: string): string {
    return s === "" ? "-" : (s ?? "-");
  }

  function tabloKopyala() {
    const columnsString = ["İsim", ...selectedMetaFieldNames]

    const dataRows = secilenSarkilar.map(sarki => {
      const cols = selectedMetaFieldNames.select(metaName =>
        emptyToDash(BusinessHelper.SarkiMeta.MetaPairs.single(e => e.isim === metaName).field(sarki, genelDurumHelper))
      );

      return [sarki.isim, ...cols]
    })

    const text = BusinessHelper.Tablo.CSVOlustur(columnsString, dataRows);

    navigator.clipboard.writeText(text);
    AlertHelper.ShowMessage("Dikkat", "Tablo kopyalandı");

    // const firstRowColumns = selectedMetaFieldNames.select(e => `<th>${e}</th>`).join("");
    // const dataRows = secilenSarkilar.map((sarki) => {
    //   const cols = selectedMetaFieldNames.select(metaName =>
    //     `<td>${emptyToDash(BusinessHelper.SarkiMeta.MetaPairs.single(e => e.isim === metaName).field(sarki, genelDurumHelper))}</td>`
    //   ).join("");
    //   return `<tr><th>${sarki.isim}</th>${cols}</tr>`
    // }).join("");
    // const text = `<table><tr><th>İsim</th>${firstRowColumns}</tr>${dataRows}</table>`;

    // navigator.clipboard.writeText(text);
    // AlertHelper.ShowMessage("Dikkat", "Tablo kopyalandı");
  }

  function setFilter(metaField: MetaSearchField, newValue: any): void {
    const newFilterValues = filterValues.slice();

    const foundFilter = newFilterValues.single(e => e.isim === metaField.isim);
    foundFilter.value = newValue;
    setFilterValues(newFilterValues);
  }

  const canEdit = genelDurumHelper.HasYetkiAll([EnmYetki["Şarkı Yönetim"]]);

  function handleSort(fieldName: string): void {
    if (sortByMeta === fieldName)
      setSortByMeta("");
    else
      setSortByMeta(fieldName);
  }

  return (
    <MainLayout
      location={mainScreenLocations.sarkiMetaAnaliz}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "100%",
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <VatanAccordionSimple
          style={{ flex: 1 }}
          title={genelDurumHelper.translate("Filtreler (NN Filtreleme)", { filtreCount: filterValues.where(e => !!e.value).length.toFixed() })}
          emptyNote=""
          children={() => <Columns flex wrap>
            {filterValues.map(metaField => <VatanMetaFilterEditor
              key={metaField.isim}
              metaField={metaField}
              setFilter={setFilter}
              genelDurumHelper={genelDurumHelper}
            />)}
          </Columns>}
        />

        <Columns style={{ marginBottom: "5px" }} behaveAsRows={isSmallScreen} >
          {isSmallOrMediumScreeen ?
            <>
              <MyText style={{ minWidth: "75px", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Gösterilecek Bilgiler")}: </MyText>
              <MyText style={{ minWidth: "75px", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }}>{`${selectableMetaFields.length} kolondan ${selectedMetaFieldNames.length} adedi seçili`}</MyText>
              <Button size="small" variant="contained" onClick={() => onMetaEkleCikartClicked()}>Kolon Seçimini/Sırasını güncelle</Button>
            </>
            :
            <VatanAccordionSimple
              style={{ flex: 1 }}
              title={genelDurumHelper.translate("Gösterilecek Bilgiler (NN bilgi seçildi)", { bilgiCount: selectedMetaFieldNames.length.toFixed() })}
              emptyNote=""
              children={() => <GenericTwoBoxListMultiSelectField
                itemLabel="meta"
                label="Metalar"
                value={selectedMetaFieldNames}
                list={selectableMetaFields}
                labelGetter={e => genelDurumHelper.translate(e.isim as any)}
                valueGetter={e => e.isim}
                onChange={e => setSelectedMetaFieldNames(e)}
                sortable
                genelDurumHelper={genelDurumHelper}
              />}
            />
          }
        </Columns>
        <Columns style={{ margin: "10px" }}>
          <Cell flex></Cell>
          <Button variant="contained" style={{ paddingBottom: "0px" }} onClick={() => tabloKopyala()}>
            <Columns>
              <Cell>{appIcons.CrudIcons.kopyala}</Cell>
              <Cell style={{ marginLeft: "5px" }}>{genelDurumHelper.translate("Tabloyu Kopyala")}</Cell>
            </Columns>
          </Button>
        </Columns>
        <VatanAccordionSimple
          style={{ flex: 1 }}
          title={genelDurumHelper.translate("Eserler (NN kayıt)", { sarkiSayisi: secilenSarkilar.length.toFixed() })}
          emptyNote=""
          children={() => <TableContainer>
            <Table size="small" sx={{ flex: 0, minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell style={{ fontWeight: "bold" }} >{genelDurumHelper.translate("Şarkı İsmi")}</TableCell>
                  {selectedMetaFieldNames.map(metaName =>
                    <TableCell key={metaName} style={{ fontWeight: "bold" }} align="left">
                      <Link onClick={() => handleSort(metaName)} style={{ cursor: "pointer" }}>
                        {genelDurumHelper.translate(metaName as any)}
                        {metaName === sortByMeta ? appIcons.Genel.asagi : null}
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {secilenSarkilar.map((sarki, orderNo) => {
                  return <TableRow
                    key={sarki.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Columns>
                        <IconButton onClick={() => sarkiPdfGoster(sarki)} >{appIcons.Sarki.pdf}</IconButton>
                        {!canEdit ? null : <IconButton onClick={() => sarkiGuncelleBaslat(sarki)} >{appIcons.CrudIcons.guncelle}</IconButton>}
                      </Columns>
                    </TableCell>
                    <TableCell component="th" scope="row">{`${orderNo + 1}. ${sarki.isim}`}</TableCell>
                    {selectedMetaFieldNames.map(metaName =>
                      <TableCell key={metaName} align="left">{emptyToDash(BusinessHelper.SarkiMeta.MetaPairs.single(e => e.isim === metaName).field(sarki, genelDurumHelper))}</TableCell>
                    )}
                  </TableRow>
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>}
          alwaysOpen
        />
      </List>
    </MainLayout>
  );
}

export default SarkiMetaAnalizScreen;
import EnumHelper from "../../helpers/EnumHelper";
import GenericListSelectField, { IGenericListSelectFieldProps } from "../common/GenericListSelectField";

type innerListType = { value: number, presentation: string };
export type IVatanCstFieldProps = Omit<IGenericListSelectFieldProps<innerListType, number>, "list" | "labelGetter" | "valueGetter"> & { cstType: Record<number, string> };

function VatanCstField(props: IVatanCstFieldProps) {
    const { cstType: enumType, ...rest } = props;
    return (
        <GenericListSelectField
            {...rest}
            list={EnumHelper.getSelectListAsArray<number, number>(enumType, e => enumType[e])}
            labelGetter={(e) => e.presentation}
            valueGetter={(e) => e.value}
        />
    )
}

export default VatanCstField;
/* eslint-disable react/no-children-prop */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { TarzModel } from "../../../models/businessModels/TarzModel";
import TarzPopupEditor from "./TarzPopupEditor";
import { GuidHelper } from "../../../helpers/GuidHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import Columns from "../../../components/layout/Columns";

function TarzYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <TarzYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function TarzYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const kullananSarkilarSayiGetir = (tarzId: string) => genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.tarzModelIdList.contains(tarzId));

  const yonetimYetkiVar = genelDurumHelper.HasYetkiAll([EnmYetki["Şarkı Yönetim"]]);

  const tarzRowGetir = (tarzList: TarzModel[]): JSX.Element | null => {
    if (tarzList.length === 0)
      return null;

    const items = tarzList.map((item) => {
      const sarkiCount = kullananSarkilarSayiGetir(item.id).length;
      const altBilgi = `${sarkiCount} şarkı`;

      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => tarzSil(item, yonetimYetkiVar) });
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => tarzGuncelle(item, yonetimYetkiVar) });

      return (
        <MyListItem hasLightBottomBorder width={300} leftButtons={rightButtons} key={item.id}>
          <Rows>
            <MyText style={{ alignSelf: "flex-start" }}>{item.isim}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi}</MyText>
          </Rows>
        </MyListItem>
      );
    });

    return <Columns wrap>{items}</Columns>
  }

  const tarzEkle = async () => {
    const tarzModel = new TarzModel();
    tarzModel.id = GuidHelper.NewGuid();
    tarzModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    tarzModel.isim = "";
    tarzModel.aciklama = "";
    tarzModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    tarzModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<TarzModel>("Tarz Ekle", (closer) => (
      <TarzPopupEditor
        tarzModel={tarzModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const tarzlar = seciliKoro.tumTarzlar;
        tarzlar.push(response!);
      });
  }

  const tarzSil = async (tarzModel: TarzModel, silinebilir: boolean): Promise<void> => {
    if (!silinebilir) {
      AlertHelper.ShowMessage("Dikkat", "Ön tanımlı tarzlar silinemez");
      return;
    }

    if (await AlertHelper.Confirm("Dikkat", `${tarzModel.isim} isimli gruubu silme istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Tarz.TarzSil(tarzModel.id);
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const tarzlar = seciliKoro.tumTarzlar;
        tarzlar.remove(tarzlar.single(e => e.id === tarzModel.id));
      });
  }

  const tarzGuncelle = async (tarzModel: TarzModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<TarzModel>("Tarz Güncelle", (closer) => (
      <TarzPopupEditor
        guncellenebilir={guncellenebilir}
        tarzModel={tarzModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const tarzlar = seciliKoro.tumTarzlar;
        tarzlar.remove(tarzlar.single(e => e.id === response!.id));
        tarzlar.push(response!);
      });
  }

  const tumTarzlar = genelDurumHelper.SeciliKoroModel.tumTarzlar.orderByTurkish(e => e.isim);

  const kullanilanTarzlar = tumTarzlar.where(e => kullananSarkilarSayiGetir(e.id).length > 0);
  const kullanilmayanTarzlar = tumTarzlar.where(e => kullananSarkilarSayiGetir(e.id).length === 0);

  return (
    <MainLayout
      location={mainScreenLocations.tarzYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <Rows>
          <ListItem>
            <Button onClick={() => tarzEkle()}>
              {appIcons.CrudIcons.ekle}
              <Typography>Yeni Tarz Ekle</Typography>
            </Button>
          </ListItem>
          <VatanAccordionSimple
            title="Kullanılan Tarzlar"
            children={tarzRowGetir(kullanilanTarzlar) ?? <MyText note>Kullanılan tarz bulunmamaktadır</MyText>}
            alwaysOpen
          />
          <VatanAccordionSimple
            title="Kullanılmayan Tarzlar"
            children={tarzRowGetir(kullanilmayanTarzlar) ?? <MyText note>Kullanılmayan tarz bulunmamaktadır</MyText>}
            alwaysOpen
          />
        </Rows>
      </List>
    </MainLayout>
  );
}

export default TarzYonetimScreen;
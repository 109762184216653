import Columns, { ColumnsCSSProperties } from "../layout/Columns";
import { MyText } from "../common/MyText";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import { DuyuruModel } from "../../models/businessModels/DuyuruModel";
import appColors from "../../app/appColors";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

interface IDuyuruAvatarListControl {
    duyurular: DuyuruModel[],
    style?: ColumnsCSSProperties,
    genelDurumHelper: GenelDurumHelper
}

function DuyuruAvatarListControl(props: IDuyuruAvatarListControl) {

    const getInnerContent = (duyuru: DuyuruModel) => {
        return (
            <div key={duyuru.id} style={{ borderRadius: 5, width: 165, padding: 10, margin: 5, borderColor: appColors.PRIMARY, borderWidth: 1, borderStyle: "solid" }}>
                <MyText style={{ textAlign: "center", fontSize: 13 }}>{duyuru.icerik}</MyText>
                <MyText style={{ textAlign: "center", fontSize: 11 }} note>{DateTimeHelper.toMonthDayAndYearAndDayOfWeekString(duyuru.tarihBaslangic, props.genelDurumHelper.translate)}</MyText>
            </div>
        );
    }

    return (
        <Columns wrap style={props.style}>
            {props.duyurular.map(item => getInnerContent(item))}
        </Columns>
    )
}

export default DuyuruAvatarListControl;
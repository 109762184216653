/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import VatanTextField from "../../../components/common/VatanTextField";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import KisiAvatarListControl from "../../../components/business/KisiAvatarListControl";
import CalismaAvatarListControl from "../../../components/business/CalismaAvatarListControl";
import DuyuruAvatarListControl from "../../../components/business/DuyuruAvatarListControl";
import { VatanAccordion, VatanAccordionGroup } from "../../../components/common/VatanAccordion";
import JsonHelper from "../../../helpers/JsonHelper";
import AnketAvatarListControl from "../../../components/business/AnketAvatarListControl";
import VatanNumericField from "../../../components/common/VatanNumericField";
import VatanCstField from "../../../components/common/VatanCstField";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";

interface ISesGrupPopupEditorProps {
    sesGrupModel: SesGrupModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: SesGrupModel | null) => void,
}

function SesGrupPopupEditor(props: ISesGrupPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [sesGrupModel, setSesGrupModel] = useState<SesGrupModel>(JsonHelper.deepCopy(props.sesGrupModel));

    const tumSesGruplar = genelDurumHelper.SeciliKoroModel.tumSesGruplari;

    const updateSesGrupModel = (action: (model: SesGrupModel) => void) => {
        const result = JsonHelper.deepCopy(sesGrupModel);
        action(result);
        setSesGrupModel(result);
    }

    const isimHata = tumSesGruplar.any(e => e.id !== sesGrupModel.id && e.isim === sesGrupModel.isim) ? "Bu isimde başka bir ses grubu bulunmaktadır" : null;
    const isimHataVar = !!isimHata || !sesGrupModel.isim;

    const sirayaSahipSesGrubu = tumSesGruplar.firstOrDefault(e => e.id !== sesGrupModel.id && e.sira === sesGrupModel.sira);
    const siraHata = sirayaSahipSesGrubu != null ? `Bu sıra numarasına sahip başka bir ses grubu (${sirayaSahipSesGrubu.isim}) bulunmaktadır` : null;
    const siraHataVar = !!siraHata || sesGrupModel.sira == null;

    const tipHataVar = sesGrupModel.sesGrupTip == null;

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.SesGrup.SesGrupGuncelle(sesGrupModel);
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.SesGrup.SesGrupEkle(sesGrupModel);
            if (!webResponse.successfull)
                return;
        }

        closer(sesGrupModel);
    }

    const kullananHesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.sesGrupIdList.contains(props.sesGrupModel.id));
    const kullananCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => e.sesGrupIdList.contains(props.sesGrupModel.id));
    const kullananDuyurular = genelDurumHelper.SeciliKoroModel.tumDuyurular.where(e => e.sesGrupIdList.contains(props.sesGrupModel.id));
    const kullananAnketler = genelDurumHelper.SeciliKoroModel.tumAnketler.where(e => e.sesGrupIdList.contains(props.sesGrupModel.id));

    return (
        <Rows height={guncellemeMi ? 550 : 170} style={{ minWidth: 850, paddingTop: 20, display: "flex" }}>
            <Columns>
                <VatanTextField
                    disabled={!guncellenebilir}
                    label="Ses Grup İsmi"
                    error={isimHataVar}
                    helperText={isimHata}
                    value={sesGrupModel.isim}
                    onChange={(e) => updateSesGrupModel(t => t.isim = e)} />
                <VatanNumericField
                    style={{ marginLeft: "10px" }}
                    disabled={!guncellenebilir}
                    label="Sırası"
                    error={siraHataVar}
                    helperText={siraHata}
                    value={sesGrupModel.sira}
                    onChange={(e) => updateSesGrupModel(t => t.sira = e ?? null as any)} />
                <VatanCstField
                    style={{ marginLeft: "10px" }}
                    disabled={guncellemeMi}
                    label="Ses Grup Tipi"
                    error={tipHataVar}
                    value={sesGrupModel.sesGrupTip}
                    cstType={EnmSesGrupTip}
                    onChange={(e) => updateSesGrupModel(t => t.sesGrupTip = e as any)}
                    id={sesGrupModel.id} />
            </Columns>
            <Rows flex style={{ marginTop: 10, overflowY: "auto" }}>
                {guncellemeMi &&
                    <VatanAccordionGroup initialExpandedKey="hesap" children={(onChange, expandedKey) => (
                        <>
                            {kullananHesaplar.length > 0 ?
                                <VatanAccordion expandedKey="hesap" title={`Ses Grubunu İçeren Hesaplar (${kullananHesaplar.length} hesap)`} expanded={expandedKey === "hesap"} onExpandedChanged={onChange} >
                                    <KisiAvatarListControl hesaplar={kullananHesaplar} size="normal" genelDurumHelper={genelDurumHelper} hideNames />
                                </VatanAccordion>
                                :
                                <MyText note italic>Grubu içeren hesap bulunmamaktadır</MyText>
                            }
                            {kullananCalismalar.length > 0 ?
                                <VatanAccordion expandedKey="calisma" title={`Ses Grubunu İçeren Çalışmalar (${kullananCalismalar.length} çalışma)`} expanded={expandedKey === "calisma"} onExpandedChanged={onChange}>
                                    <CalismaAvatarListControl calismalar={kullananCalismalar} genelDurumHelper={genelDurumHelper} />
                                </VatanAccordion>
                                :
                                <MyText note italic>Grubu içeren çalışma bulunmamaktadır</MyText>
                            }
                            {kullananDuyurular.length > 0 ?
                                <VatanAccordion expandedKey="duyuru" title={`Ses Grubunu İçeren Duyurular (${kullananDuyurular.length} duyuru)`} expanded={expandedKey === "duyuru"} onExpandedChanged={onChange}>
                                    <DuyuruAvatarListControl duyurular={kullananDuyurular} genelDurumHelper={genelDurumHelper} />
                                </VatanAccordion>
                                :
                                <MyText note italic>Grubu içeren duyuru bulunmamaktadır</MyText>
                            }
                            {kullananAnketler.length > 0 ?
                                <VatanAccordion expandedKey="anket" title={`Ses Grubunu İçeren Anketler (${kullananAnketler.length} anket)`} expanded={expandedKey === "anket"} onExpandedChanged={onChange}>
                                    <AnketAvatarListControl anketler={kullananAnketler} genelDurumHelper={genelDurumHelper} />
                                </VatanAccordion>
                                :
                                <MyText note italic>Grubu içeren duyuru bulunmamaktadır</MyText>
                            }

                        </>
                    )} />
                }
            </Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={isimHataVar || siraHataVar || tipHataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default SesGrupPopupEditor;
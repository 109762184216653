import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { KonserModel } from "../../../models/businessModels/KonserModel";
import SesGrupluKisiAvatarListControl from "../../../components/business/SesGrupluKisiAvatarListControl";
import KorocanImage from "../../../components/business/KorocanImage";
import { MyText } from "../../../components/common/MyText";
import appColors from "../../../app/appColors";
import IcerikHelper from "../../../helpers/IcerikHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import StringHelper from "../../../helpers/StringHelper";

interface IKonserPopupViewerProps {
    genelDurumHelper: GenelDurumHelper,
    konser: KonserModel,
    closer: (s: unknown) => void,
}

function KonserPopupViewer(props: IKonserPopupViewerProps) {
    const { closer, konser, genelDurumHelper } = props;
    const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;

    const sarkilar = konser.konserSarkiModelList.orderBy(e => e.siraNo).select(e => tumSarkilar.single(t => t.id === e.sarkiModelId));
    const kisiler = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(hesap => BusinessHelper.Kadro.HesapKadroyaDahilMi(hesap, konser.kadroModelId));

    const tumLinkler = konser.konserSarkiModelList.orderBy(e => e.siraNo).where(e => StringHelper.isNotEmpty(e.videoUrl)).select(e => {
        return {
            konserSarki: e,
            sarki: tumSarkilar.single(t => t.id === e.sarkiModelId),
        }
    });

    const getHeaderText = (title: string) => <MyText bold style={{ width: "100%", marginBottom: "15px", textAlign: "center", borderBottomColor: appColors.BLACK, borderBottomStyle: "solid", borderBottomWidth: "1px" }}>{title}</MyText>
    return (
        <Rows style={{ display: "flex" }}>
            <Columns>
                <MyText note>{konser.yer} / {konser.sehirUlke} - {DateTimeHelper.toMonthDayAndDayOfWeekString(konser.tarih, genelDurumHelper.translate)}</MyText>
                {StringHelper.isNotEmpty(konser.ortakKoro) && <MyText note italic>{` (${konser.ortakKoro} ile birliklte)`}</MyText>}
            </Columns>
            <Columns style={{ paddingTop: 20, }}>
                <Rows width={150} style={{ marginRight: "10px" }}>
                    {getHeaderText("Konser Afişi")}
                    <KorocanImage
                        title={`${konser.isim} Afişi`}
                        icerikId={konser.resimIcerikId}
                        genelDurumHelper={genelDurumHelper}
                        style={{ maxHeight: "400px", maxWidth: "150px" }} />
                </Rows>
                <Rows style={{ marginRight: "10px", minWidth: "250px" }}>
                    {getHeaderText("Konser Eserleri")}
                    {sarkilar.map((sarki, i) => <MyText key={i}>{`${(i + 1)}. ${sarki.isim}`}</MyText>)}
                </Rows>
                <Rows style={{ marginRight: "10px" }}>
                    {getHeaderText("Konser Kadrosu")}
                    <SesGrupluKisiAvatarListControl
                        darkSeperator
                        tumHesaplar={genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => BusinessHelper.Genel.KoristMi(e))}
                        gosterilecekHesaplar={kisiler}
                        sesGruplariniGetir={genelDurumHelper.SesGruplariniGetir}
                        hesapSesGrupGetir={BusinessHelper.SesGrubu.KadroyaGoreSesGruplariniGetir(konser.kadroModelId, genelDurumHelper)}
                        genelDurumHelper={genelDurumHelper} />
                </Rows>
            </Columns>
            {tumLinkler.length > 0 && <Rows>
                {getHeaderText("Konser Videoları")}
                <Columns wrap>
                    {tumLinkler.map(link =>
                        <Rows key={link.sarki.id} width={224} height={168} style={{ marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                            <div className="video-responsive">
                                <iframe
                                    width="224"
                                    height="168"
                                    src={IcerikHelper.findEmbedLink(link.konserSarki.videoUrl!)}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>
                            <MyText note>{link.konserSarki.siraNo.toFixed()}. {link.sarki.isim}</MyText>
                        </Rows>
                    )}
                </Columns>
            </Rows>
            }
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="info" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Tamam")}</Button>
            </Columns>
        </Rows>
    );

}

export default KonserPopupViewer;
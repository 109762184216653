import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { GuidHelper } from "../../../helpers/GuidHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import appColors from "../../../app/appColors";
import { TatilModel } from "../../../models/businessModels/TatilModel";
import TatilPopupEditor from "./TatilPopupEditor";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function TatilYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <TatilYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function TatilYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const yonetimYetkisi = true; //genelDurumHelper.HasYetkiAll([EnmYetki["Tatil Yönetim"]]);

  const tatilRowGetir = (tatilList: TatilModel[]): JSX.Element[] => {
    return tatilList.map((item) => {

      const altBilgi1 = item.sabitTarihTekrarEdiyorMu ? `${item.tarihler.first().tarihBaslangic.getDate()} ${DateTimeHelper.getMonthName(item.tarihler.first().tarihBaslangic.getMonth() + 1)}` : "-";
      const altBilgi2 = "";//`Hedef kitle: ${kadroIsim}${sesGrupIsim}`;
      const altBilgi3 = "";//`Tarih ${DateTimeHelper.toMonthDayStringOptionalYear(item.tarihBaslangic)} - ${DateTimeHelper.toMonthDayStringOptionalYear(item.tarihBitis)}`;

      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => tatilGuncelle(item, yonetimYetkisi) });

      if (yonetimYetkisi) {
        rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => tatilSil(item) });
      }

      return (
        <MyListItem leftButtons={rightButtons} key={item.id} style={{ width: "400px" }}>
          <Rows>
            <MyText style={{ alignSelf: "flex-start" }}>{item.isim}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi1}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi2}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi3}</MyText>
          </Rows>
        </MyListItem>
      );
    });
  }

  const tatilEkle = async () => {
    const tatilModel = new TatilModel();
    tatilModel.id = GuidHelper.NewGuid();
    tatilModel.isim = "";
    tatilModel.sabitTarihTekrarEdiyorMu = false;
    tatilModel.aktif = true;
    tatilModel.tarihler = [];
    tatilModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    tatilModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<TatilModel>("Tatil Ekle", (closer) => (
      <TatilPopupEditor
        tatilModel={tatilModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const tatiller = seciliKoro.tumTatiller;
        tatiller.push(response!);
      });
  }

  const tatilSil = async (tatilModel: TatilModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", `Tatili silmek istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Tatil.TatilSil(tatilModel.id);
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const tatiller = seciliKoro.tumTatiller;
        tatiller.remove(tatiller.single(e => e.id === tatilModel.id));
      });
  }

  const tatilGuncelle = async (tatilModel: TatilModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<TatilModel>("Tatil Güncelle", (closer) => (
      <TatilPopupEditor
        guncellenebilir={guncellenebilir}
        tatilModel={tatilModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const tatiller = seciliKoro.tumTatiller;
        tatiller.remove(tatiller.single(e => e.id === response!.id));
        tatiller.push(response!);
      });
  }

  const tumTatiller = genelDurumHelper.SeciliKoroModel.tumTatiller.orderBy(e => e.tarihler.first().tarihBaslangic);

  let tatillerIcerik: JSX.Element[] | JSX.Element = <ListItem><MyText note>Tatil bulunmamaktadır</MyText></ListItem>;

  if (tumTatiller.length > 0) {
    tatillerIcerik = tatilRowGetir(tumTatiller);
  }

  return (
    <MainLayout
      location={mainScreenLocations.tatilYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "500px",
        '&:last-child td, &:last-child th': { border: 0 },
        '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
      }}>
        <MyListItem itemDividerText="Tatiller" />
        {tatillerIcerik}
        <ListItem>
          <Button disabled={!yonetimYetkisi} onClick={() => tatilEkle()}>
            {appIcons.CrudIcons.ekle}
            <Typography>Yeni Tatil Ekle</Typography>
          </Button>
        </ListItem>
      </List>
    </MainLayout>
  );
}

export default TatilYonetimScreen;


import React, { CSSProperties } from "react";
import appColors from "../../../app/appColors"
import { MyText } from "../../../components/common/MyText"
import Cell from "../../../components/layout/Cell"
import Columns from "../../../components/layout/Columns"
import Rows from "../../../components/layout/Rows"
import ScrollDividedControl from "../../../components/layout/ScrollDividedControl"
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { Tooltip } from "@mui/material";
import { KoroAyarlariModel } from "../../../models/businessModels/KoroAyarlariModel";

export type CizelgeColumnInfo<TColumn> = {
    id: string,
    data: TColumn,
    backgroundColor: string | undefined,
    order: number,
    group: string,
}

export type CizelgeRowInfo<TRow> = {
    id: string,
    data: TRow,
    backColor: string | undefined,
    order: number,
    group: string,
}

export type CizelgeCellInfo<TColumn, TRow, TCell> = {
    column: CizelgeColumnInfo<TColumn>,
    row: CizelgeRowInfo<TRow>,
    data: TCell,
}

export type CizelgeData<TColumn, TRow, TCell> = {
    rows: CizelgeRowInfo<TRow>[], // isim
    columns: CizelgeColumnInfo<TColumn>[], // tarih veya şarkı
    cells: CizelgeCellInfo<TColumn, TRow, TCell>[],
    hasSummaryBottomRow: boolean,
    hasSummaryRightmostColumn: boolean,
    GetRowsFirstColumnString: (row: TRow) => string, // isim
    GetColumnsFirstRowContent: (column: TColumn) => string | React.ReactNode, // tarih veya şarkı
    GetColumnsFirstRowSummaryHeaderString: string,
    GetRowsFirstColumnSummaryHeaderString: string
    GetCellContent: (cell: CizelgeCellInfo<TColumn, TRow, TCell>) => { text: string | React.ReactNode, backcolor: string | undefined }, // hücre içeriği
    GetRowsLastSummaryColumnCellContent: (row: TRow) => { percent: number, backcolor: string | undefined, extraText: string }, // isim ortalama
    GetColumnsLastSummaryRowCellContent: (column: TColumn) => { percent: number, backcolor: string | undefined }, // isim ortalama
    GetGlobalSummaryCellContent: () => { percent: number, backcolor: string | undefined },
    GetLeftTopContent: React.ReactNode,
}

export interface ICizelgeStyles {
    cellHeight: number,
    cellWidth: number,
    rightmostColumnWidth: number,
    firstColumnWidth: number,
    firstRowHeight: number,
    firstRowGroupInnerHeight: number,
    leftHeaderColumnFontSize: string,
    topHeaderRowFontSize: string,
    bottomSummaryRowHeight: number,
}

export function GetColorForPercent(oran: number, koroayarlari: KoroAyarlariModel) {
    const newOran = Math.ceil(oran * 100) / 100;
    if (newOran < koroayarlari.devamsizlikAltSinir)
        return appColors.LIGHTRED;
    else if (newOran < koroayarlari.devamsizlikUstSinir)
        return appColors.LIGHTYELLOW;
    else
        return appColors.LIGHTGREEN;
}

export function GetColorForHesap(hesap: HesapBilgiVeKullaniciModel) {
    if (!hesap.aktif)
        return appColors.VERYLIGHTRED;
    else if (hesap.araVerdi)
        return appColors.VERYLIGHTYELLOW;
    else
        return undefined;
}

interface ICizelgeTableProps<TColumn, TRow, TCell> {
    styles: ICizelgeStyles,
    data: CizelgeData<TColumn, TRow, TCell>,
}

const genericStyles: { [key: string]: CSSProperties } = {
    col01RightBorder: {
        borderRightWidth: 1,
        borderRightColor: appColors.BLACK,
        borderRightStyle: "solid",
    },
    col01text: {
        fontSize: 8,
        flex: 1,
        transform: "rotate(90deg)", //[{ rotate: '-90deg' }],
        lineHeight: 1,
        whiteSpace: "nowrap"
    },
    col10Style: {
        borderBottomWidth: 1,
        borderColor: appColors.GRAY_LIGHT4,
        borderBottomStyle: "solid",
    },
    col10HeaderStyle: {
        backgroundColor: appColors.GRAY_LIGHT2,
        borderBottomWidth: 1,
        borderColor: appColors.BACKGROUND,
        borderBottomStyle: "solid",
        fontWeight: "bold",
    },
    col10TextStyle: {
        fontSize: "10px",
    },
    defaultCell11Style: {
        borderBottomWidth: 1,
        borderBottomColor: appColors.GRAY_LIGHT4,
        borderBottomStyle: "solid",
        borderRightWidth: 1,
        borderRightColor: appColors.GRAY_LIGHT4,
        borderRightStyle: "solid",
        fontSize: 8,
    },
    bottomLightBorder: {
        borderBottomWidth: 1,
        borderBottomColor: appColors.GRAY_LIGHT4,
        borderBottomStyle: "solid",
    },
    fullBorder: {
        borderWidth: 1,
        borderColor: appColors.BLACK,
        borderStyle: "solid",
    }
}
export function TekilCizelgeTable<TColumn, TRow, TCell>(props: ICizelgeTableProps<TColumn, TRow, TCell>) {
    if (props.data.rows.length === 0)
        return <MyText italic>Gösterilecek veri bulunamamıştır</MyText>

    return <Rows style={{ alignItems: "flex-start", overflow: "auto" }}>
        <Cell style={{ marginTop: "10px" }}>{props.data.GetLeftTopContent}</Cell>

        <MyText header style={{ marginTop: "10px" }}>{props.data.GetRowsFirstColumnString(props.data.rows.first().data)}</MyText>
        <Rows style={{ ...genericStyles.fullBorder, marginTop: "10px" }}>
            {props.data.cells.map((cell, i) => {
                const columnContent = props.data.GetColumnsFirstRowContent(cell.column.data);
                const cellContent = props.data.GetCellContent(cell);

                return <Columns width={350} key={i} style={{ ...genericStyles.bottomLightBorder, }}>
                    <Cell flex><MyText style={{ marginLeft: "5px" }}>{columnContent}</MyText></Cell>
                    <Cell width={24} style={{ minWidth: 24, background: cellContent.backcolor }}>{cellContent.text}</Cell>
                </Columns>
            })}
        </Rows>
    </Rows>
}

export function CizelgeTable<TColumn, TRow, TCell>(props: ICizelgeTableProps<TColumn, TRow, TCell>) {
    function cellMouseEnter(rowId: string | null, columnId: string | null): void {
        if (rowId)
            document.querySelectorAll(`[customrowdata="${rowId}"]`).forEach(e => addClassToItem(e, "seciliCizelgeItem"));
        if (columnId)
            document.querySelectorAll(`[customcolumndata="${columnId}"]`).forEach(e => addClassToItem(e, "seciliCizelgeItem"));
    }

    function cellMouseLeave(rowId: string | null, columnId: string | null): void {
        if (rowId)
            document.querySelectorAll(`[customrowdata="${rowId}"]`).forEach(e => removeClassFromItem(e, "seciliCizelgeItem"));
        if (columnId)
            document.querySelectorAll(`[customcolumndata="${columnId}"]`).forEach(e => removeClassFromItem(e, "seciliCizelgeItem"));
    }

    // GenerateTopRowContent
    const contentUst = GenerateTopRowContent(props.styles, props.data, cellMouseEnter, cellMouseLeave);

    // GenerateMiddleContent
    const contentOrta = GenerateMiddleContent(props.styles, props.data, cellMouseEnter, cellMouseLeave);

    // GenerateLeftColumnContent
    const contentSol = GenerateLeftContent(props.styles, props.data, cellMouseEnter, cellMouseLeave);

    return (<ScrollDividedControl innerHeight={props.styles.firstRowHeight} innerWidth={props.styles.firstColumnWidth}
        contentSolUst={props.data.GetLeftTopContent}
        contentSol={contentSol}
        contentUst={contentUst}
        contentOrta={contentOrta}
    />
    );
}

function GenerateMiddleRow<TColumn, TRow, TCell>(rowItem: CizelgeRowInfo<TRow>, styles: ICizelgeStyles, data: CizelgeData<TColumn, TRow, TCell>, index1: number, index2: number, cellMouseEnter: (rowId: string | null, columnId: string | null) => void, cellMouseLeave: (rowId: string | null, columnId: string | null) => void): React.ReactNode {
    // ORTADAKİ HÜCRELER
    const middleCells = data.cells.where(e => e.row === rowItem).map((cellData, i) => {
        const columnDataIndex = data.columns.indexOf(cellData.column);

        let rightBorder = {};
        if (columnDataIndex === data.columns.length - 1 || cellData.column.group !== data.columns[columnDataIndex + 1].group)
            rightBorder = genericStyles.col01RightBorder;

        const cellResult = data.GetCellContent(cellData);
        const myAttr = { 'customcolumndata': cellData.column.id, 'customrowdata': cellData.row.id };

        return <Cell {...myAttr} width={styles.cellWidth + 1} key={i} onMouseEnter={() => cellMouseEnter(cellData.row.id, cellData.column.id)} onMouseLeave={() => cellMouseLeave(cellData.row.id, cellData.column.id)}>
            <Cell flex style={{ ...genericStyles.defaultCell11Style, ...rightBorder }} >
                <Cell flex height={styles.cellHeight} width={styles.cellWidth} style={{ background: cellResult.backcolor }}>{cellResult.text}</Cell>
            </Cell>
        </Cell>;
    });

    // ORTA-SAĞDAKİ HÜCRELER
    const summaryAttr = { 'customrowdata': rowItem.id };
    const summaryData = data.GetRowsLastSummaryColumnCellContent(rowItem.data);
    const summaryCell = !data.hasSummaryRightmostColumn ? null :
        <Cell {...summaryAttr} width={styles.rightmostColumnWidth} key={"Summary"} height={styles.cellHeight} style={{ ...genericStyles.defaultCell11Style, justifyContent: "center" }} onMouseEnter={() => cellMouseEnter(rowItem.id, null)} onMouseLeave={() => cellMouseLeave(rowItem.id, null)}>
            <Cell width={styles.rightmostColumnWidth - 3} style={{ paddingTop: "3px", paddingLeft: "3px", background: summaryData.backcolor }}>
                <MyText style={{ fontSize: 8 }}>{`%${(summaryData.percent * 100).toFixed()} ${summaryData.extraText}`}</MyText>
            </Cell>
        </Cell>

    return <Columns key={index1.toFixed() + " " + index2.toFixed()}>
        {!data.hasSummaryRightmostColumn ? middleCells : [...middleCells, summaryCell!]}
    </Columns>
}

function GenerateMiddleContent<TColumn, TRow, TCell>(styles: ICizelgeStyles, data: CizelgeData<TColumn, TRow, TCell>, cellMouseEnter: (rowId: string | null, columnId: string | null) => void, cellMouseLeave: (rowId: string | null, columnId: string | null) => void) {
    const headers = data.rows.select(e => e.group).distinct();
    const useHeaders = headers.length > 1;

    // ORTA SATIRLAR
    const allRows = headers.flatMap((header, i) => {
        const innerRows = data.rows.where(e => e.group === header).map((rowItem, j) => GenerateMiddleRow(rowItem, styles, data, i, j, cellMouseEnter, cellMouseLeave));

        const headerRow = !useHeaders ? null :
            <Columns key={header + "_header"} height={styles.cellHeight + 1} >
                <Cell>
                </Cell>
            </Columns>

        return !useHeaders ? innerRows : [headerRow!, ...innerRows];
    });

    const bottomSummaryRow = !data.hasSummaryBottomRow ? null : GenerateBottomSummaryRow(styles, data);

    return (
        <div>
            {allRows}
            {bottomSummaryRow}
        </div>
    );
}

function GenerateLeftContent<TColumn, TRow, TCell>(styles: ICizelgeStyles, data: CizelgeData<TColumn, TRow, TCell>, cellMouseEnter: (rowId: string | null, columnId: string | null) => void, cellMouseLeave: (rowId: string | null, columnId: string | null) => void) {
    const headers = data.rows.select(e => e.group).distinct();
    const useHeaders = headers.length > 1;

    // SOL SATIRLAR
    const allRows = headers.flatMap(header => {
        const innerRows = data.rows.where(e => e.group === header).map((rowItem, i) => {
            const myAttr = { 'customrowdata': rowItem.id };

            return <Columns {...myAttr} key={header + "_" + i.toString()} height={styles.cellHeight} style={{ ...genericStyles.col10Style }} onMouseEnter={() => cellMouseEnter(rowItem.id, null)} onMouseLeave={() => cellMouseLeave(rowItem.id, null)}>
                <Cell width={styles.firstColumnWidth} style={{ background: rowItem.backColor }} >
                    <MyText style={genericStyles.col10TextStyle}>{data.GetRowsFirstColumnString(rowItem.data)}</MyText>
                </Cell>
            </Columns>
        }
        );

        const headerRow = !useHeaders ? null :
            <Columns key={header + "_header"} height={styles.cellHeight} style={{ ...genericStyles.col10HeaderStyle }}>
                <Cell width={styles.firstColumnWidth} >
                    <MyText style={{ ...genericStyles.col10TextStyle }}>{header}</MyText>
                </Cell>
            </Columns>

        return !useHeaders ? innerRows : [headerRow!, ...innerRows];
    });

    // SOL-ALT HÜCRE
    const bottomRow = (!data.hasSummaryBottomRow || styles.bottomSummaryRowHeight === 0) ? null :
        <Cell width={styles.firstColumnWidth} height={styles.bottomSummaryRowHeight} style={genericStyles.col10HeaderStyle}>
            <MyText style={{ ...genericStyles.col10TextStyle, fontWeight: "bold" }}>{data.GetRowsFirstColumnSummaryHeaderString}</MyText>
        </Cell>

    return (
        <div>
            {allRows}
            {bottomRow}
        </div>
    );
}

function GenerateTopRowContent<TColumn, TRow, TCell>(styles: ICizelgeStyles, data: CizelgeData<TColumn, TRow, TCell>, cellMouseEnter: (rowId: string | null, columnId: string | null) => void, cellMouseLeave: (rowId: string | null, columnId: string | null) => void) {
    const bigHeight = styles.firstRowHeight;
    const smallWidth = styles.cellWidth;

    const headers = data.columns.select(e => e.group).distinct();

    // AY İSİMLERİ
    const headerColumns = headers.map((currentHeader, i) => {
        const adet = data.columns.count(e => e.group === currentHeader);
        const width = (adet * (smallWidth + 1)) - 1;

        const fontSize = (adet >= 7) ? 16 : (adet <= 3) ? 8 : (adet * 2 + 1);

        // 8 16---
        // 7 16
        // 6 13
        // 5 11
        // 4 8 
        // 3 8---

        const result = (
            <div key={i} style={{ width: width, height: bigHeight, minWidth: width, minHeight: bigHeight, ...genericStyles.col01RightBorder }}>
                <MyText style={{ textAlign: "center", fontSize: `${fontSize}px` }}>{currentHeader}</MyText>
            </div>
        );

        return result;
    });

    // ÜSTTEKİ YATAY YAZILAR
    const dataCols = data.columns.map((columnItem, i) => {
        const hasRightBorder = (i === data.columns.length - 1 || data.columns[i + 1].group !== columnItem.group);

        const rightBorder = hasRightBorder ? genericStyles.col01RightBorder : {};
        const myAttr = { 'customcolumndata': columnItem.id };

        const columnsFirstRowString = data.GetColumnsFirstRowContent(columnItem.data);
        return (
            <Cell {...myAttr} key={i} width={smallWidth + (hasRightBorder ? 0 : 1)} height={bigHeight - styles.firstRowGroupInnerHeight} style={{ minWidth: smallWidth + (hasRightBorder ? 0 : 1), minHeight: bigHeight, ...rightBorder, background: columnItem.backgroundColor }} onMouseEnter={() => cellMouseEnter(null, columnItem.id)} onMouseLeave={() => cellMouseLeave(null, columnItem.id)}>
                <Tooltip title={columnsFirstRowString}>
                    <div style={{ flex: 1, height: bigHeight - styles.firstRowGroupInnerHeight, maxWidth: smallWidth, borderColor: appColors.BACKGROUND, borderRightWidth: 1, overflow: "hidden" }}>
                        <MyText style={{
                            ...genericStyles.col01text,
                            fontSize: styles.topHeaderRowFontSize,
                            marginTop: `${Math.floor((styles.cellWidth / 2 - 4)).toFixed()}px`,
                        }}>
                            {columnsFirstRowString}
                        </MyText>
                    </div>
                </Tooltip>
            </Cell>
        );
    });

    // SAĞ ÜST HÜCRE
    const summaryCol = !data.hasSummaryRightmostColumn ? null :
        (
            <Cell key={-1} width={styles.rightmostColumnWidth} height={bigHeight} style={{ minWidth: styles.rightmostColumnWidth }} >
                <div style={{ flex: 1, height: bigHeight - styles.firstRowGroupInnerHeight, maxWidth: 12, borderColor: appColors.BACKGROUND, borderRightWidth: 1, overflow: "hidden" }}>
                    <MyText style={{
                        ...genericStyles.col01text,
                        fontSize: styles.topHeaderRowFontSize,
                        marginTop: "3px",
                    }}>{data.GetColumnsFirstRowSummaryHeaderString}
                    </MyText>
                </div>
            </Cell>
        );

    return (
        <Rows>
            <Columns height={styles.firstRowGroupInnerHeight}>
                {headerColumns}
            </Columns>
            <Columns height={styles.firstRowHeight - styles.firstRowGroupInnerHeight}>
                {!summaryCol ? dataCols : [...dataCols, summaryCol]}
            </Columns>
        </Rows>
    );
}

function GenerateBottomSummaryRow<TColumn, TRow, TCell>(styles: ICizelgeStyles, data: CizelgeData<TColumn, TRow, TCell>) {
    // EN ALT KISIMLAR
    const cols = data.columns.map((columnItem, i) => {

        const summaryData = data.GetColumnsLastSummaryRowCellContent(columnItem.data);

        const gelendivHeight = Number((summaryData.percent * styles.bottomSummaryRowHeight).toFixed());
        const gelmeyendivHeight = Number(((1 - summaryData.percent) * styles.bottomSummaryRowHeight).toFixed());
        const text = `%${(summaryData.percent * 100).toFixed()}`;

        return (
            <Cell width={styles.cellWidth + 1} key={i}>
                <Cell style={{ ...genericStyles.defaultCell11Style, paddingTop: 1 }}>
                    <div style={{ width: styles.cellWidth, height: styles.bottomSummaryRowHeight, flex: 1 }}>
                        <div style={{ height: gelmeyendivHeight, width: styles.cellWidth, minWidth: styles.cellWidth, backgroundColor: appColors.RED_MAIN }}>
                        </div>
                        <div style={{ height: gelendivHeight, width: styles.cellWidth, minWidth: styles.cellWidth, backgroundColor: appColors.GREEN_MAIN }}>
                        </div>
                    </div>
                    <MyText style={{
                        ...genericStyles.col01text,
                        marginTop: (Math.floor((styles.cellWidth/2 - 3)) - styles.bottomSummaryRowHeight).toFixed() + "px",
                        color: appColors.BACKGROUND
                    }}>{text}</MyText>
                </Cell>
            </Cell>
        );
    });

    const overallSummaryData = data.GetGlobalSummaryCellContent();

    // SAĞ-ALT HÜCRE
    const lastColumn = (
        <Cell width={styles.rightmostColumnWidth} key={-1} height={styles.bottomSummaryRowHeight} style={{ ...genericStyles.defaultCell11Style, minWidth: styles.rightmostColumnWidth, justifyContent: "center" }}>
            <MyText style={{ fontSize: 11 }}>{`%${(overallSummaryData.percent * 100).toFixed()}`}</MyText>
        </Cell>
    );

    return styles.bottomSummaryRowHeight === 0 ? null : (
        <Columns height={styles.bottomSummaryRowHeight} key={-1}>
            {cols}
            {lastColumn}
        </Columns>
    );
}

function addClassToItem(e: Element, className: string): void {
    if (!e.classList.contains(className))
        e.classList.add(className);
}

function removeClassFromItem(e: Element, className: string): void {
    if (e.classList.contains(className))
        e.classList.remove(className);
}

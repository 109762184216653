import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { HesapBilgiModel } from "../../models/businessModels/HesapBilgiModel";
import OzelGrupluKisiAvatarListControl from "./OzelGrupluKisiAvatarListControl";

interface ISesGrupluKisiAvatarListControlProps {
    onClick?: (hesap: HesapBilgiVeKullaniciModel) => void,
    gosterilecekHesaplar: HesapBilgiVeKullaniciModel[],
    tumHesaplar: HesapBilgiVeKullaniciModel[],
    sesGruplariniGetir: (sesGrupIdList: string[]) => SesGrupModel[],
    thumbnailSize?: "small" | "normal" | "large" | "xlarge",
    altSesGruplariniGoster?: boolean,
    badgeCountGetter?: (hesap: HesapBilgiVeKullaniciModel) => { error: number, info: number },
    darkSeperator?: boolean,
    hesapColorizeMethod?: "Özgeçmiş" | "Durum",

    hesapSesGrupGetir?: (hesap: HesapBilgiModel) => string[],
    genelDurumHelper: GenelDurumHelper
}


function SesGrupluKisiAvatarListControl(props: ISesGrupluKisiAvatarListControlProps) {
    const { sesGruplariniGetir, altSesGruplariniGoster, hesapSesGrupGetir, tumHesaplar, gosterilecekHesaplar, ...rest } = props;
    const hesapSesGrupListGetir = hesapSesGrupGetir ?? ((e: HesapBilgiModel) => e.sesGrupIdList);

    const tumKoristHesaplar = tumHesaplar.where(e => e.sesGrupIdList.length > 0);
    const gosterilecekKoristHesaplar = gosterilecekHesaplar.where(e => e.sesGrupIdList.length > 0);
    if (altSesGruplariniGoster)
        return <OzelGrupluKisiAvatarListControl
            {...rest}
            gosterilecekHesaplar={gosterilecekKoristHesaplar}
            tumHesaplar={tumKoristHesaplar}
            hesapGrupGetir={e => BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(sesGruplariniGetir(hesapSesGrupListGetir(e)))!}
            grupIsimGetir={(e: SesGrupModel) => e.isim}
            hesapGrupSortFunc={(e: SesGrupModel) => e.sira}
        />
    else
        return <OzelGrupluKisiAvatarListControl
            {...rest}
            gosterilecekHesaplar={gosterilecekKoristHesaplar}
            tumHesaplar={tumKoristHesaplar}
            hesapGrupGetir={e => BusinessHelper.SesGrubu.HesabinAnaSesGrubunuGetir(sesGruplariniGetir(hesapSesGrupListGetir(e)))!}
            grupIsimGetir={(e: SesGrupModel) => e.isim}
            hesapGrupSortFunc={(e: SesGrupModel) => e.sira}
        />


    // const bulunanTumSesGruplari = props.altSesGruplariniGoster ?
    //     props.tumHesaplar.select(e => BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(props.sesGruplariniGetir(hesapSesGrupListGetir(e)))) :
    //     props.tumHesaplar.select(e => BusinessHelper.SesGrubu.HesabinAnaSesGrubunuGetir(props.sesGruplariniGetir(hesapSesGrupListGetir(e))));

    // const temelSesGruplari = bulunanTumSesGruplari.distinct().orderBy(e => e?.sira);
    // const { innerWidth, innerHeight, fontSize } = getThumbnailSize(props.thumbnailSize ?? "small");
    // const isSmall = (props.thumbnailSize ?? "small") === "small";

    // function hesapBackgroundOverrideWithState(hesap: HesapBilgiVeKullaniciModel): CSSProperties | undefined {
    //     return !hesap.aktif ? {
    //         background: alpha(appColors.LIGHTRED, 0.3)
    //     } : hesap.araVerdi ? {
    //         background: alpha(appColors.LIGHTYELLOW, 0.3)
    //     } : undefined;
    // }

    // function hesapBackgroundOverrideWithOzgecmis(hesap: HesapBilgiVeKullaniciModel): CSSProperties | undefined {
    //     const len = StringHelper.nullToEmpty(hesap.ozgecmis).length;
    //     return len === 0 ? undefined : len < 20 ? {
    //         background: alpha(appColors.LIGHTYELLOW, 0.3)
    //     } : {
    //         background: alpha(appColors.LIGHTGREEN, 0.3)
    //     };
    // }

    // const GetInnerContent = (hesap: HesapBilgiVeKullaniciModel): React.ReactNode => {
    //     const badgeCount: { error: number, info: number } | undefined = props.badgeCountGetter ? props.badgeCountGetter(hesap) : undefined;

    //     return (
    //         <div key={hesap.id} style={{ height: innerHeight, width: innerWidth, padding: 0, alignSelf: "center" }}>
    //             <div style={{ position: "absolute", right: 0, marginTop: "-5px" }}>
    //                 <Badge sx={{ marginLeft: "15px" }} color="info" badgeContent={badgeCount?.info ?? 0}>
    //                     <div style={{ width: "20px" }}></div>
    //                 </Badge>
    //                 <Badge color="error" badgeContent={badgeCount?.error ?? 0}>
    //                     <div style={{ width: "20px" }}></div>
    //                 </Badge>
    //             </div>
    //             <KorocanThumbnailForHesap hesap={hesap} size={props.thumbnailSize ?? "small"} style={{ alignSelf: "center", marginTop: "3px" }} genelDurumHelper={props.genelDurumHelper} />
    //             <MyText style={{ flex: 1, marginTop: isSmall ? "-5px" : undefined, textAlign: "center", fontSize: fontSize, lineHeight: 1 }} note>{hesap.kullaniciModel.ad} {hesap.kullaniciModel.soyad}</MyText>
    //         </div>
    //     );
    // }

    // const anaContent = temelSesGruplari.map((sesGrup) => {
    //     const sesGrubununHesaplari = props.gosterilecekHesaplar.where(e => hesapSesGrupListGetir(e).contains(sesGrup!.id)).orderBy(e => e.kullaniciModel.ad + " " + e.kullaniciModel.soyad);
    //     const viewList = sesGrubununHesaplari.map((hesap) => {
    //         const background = props.colorizeMethod === undefined ? undefined :
    //             props.colorizeMethod === "hesapDurum" ?
    //                 hesapBackgroundOverrideWithState(hesap) :
    //                 hesapBackgroundOverrideWithOzgecmis(hesap);

    //         return (
    //             <IconButton style={background} key={hesap?.id ?? "ilk"} onClick={() => props.onClick ? props.onClick!(hesap) : {}}>
    //                 {GetInnerContent(hesap)}
    //             </IconButton>
    //         );
    //     });

    //     return (
    //         <Cell flex key={sesGrup?.id ?? "ilk"} style={{ borderRightWidth: 1, borderRightColor: props.darkSeperator ? appColors.GRAY_LIGHT2 : appColors.GRAY_LIGHT3, borderRightStyle: "solid" }}>
    //             <MyText style={{ textAlign: "center", fontSize: fontSize + 4 }}>{`${sesGrup!.isim} (${sesGrubununHesaplari.length.toString()})`}</MyText>
    //             <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
    //                 {viewList}
    //             </div>
    //         </Cell>
    //     );
    // });

    // return (
    //     <Rows flex>
    //         <Columns wrap>
    //             {anaContent}
    //         </Columns>
    //     </Rows>
    // );
}

// function getThumbnailSize(thumbnailSize: "small" | "normal" | "large" | "xlarge"): { innerWidth: number; innerHeight: number; fontSize: number } {
//     switch (thumbnailSize) {
//         case "small": return { innerHeight: 65, innerWidth: 45, fontSize: 10, };
//         case "normal": return { innerHeight: 85, innerWidth: 65, fontSize: 12, };
//         case "large": return { innerHeight: 110, innerWidth: 80, fontSize: 14, };
//         case "xlarge": return { innerHeight: 180, innerWidth: 150, fontSize: 16, };
//     }
// }

export default SesGrupluKisiAvatarListControl;
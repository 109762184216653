/* eslint-disable react/no-children-prop */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { SarkiModel } from "../../models/businessModels/SarkiModel";
import { mainScreenLocations } from "../../app/mainScreens";
import { MyText } from "../../components/common/MyText";
import VatanAccordionSimple from "../../components/vatan/VatanAccordionSimple";
import SarkiKutuDisplayer from "./sarki/SarkiKutuDisplayer";
import { useObserveElementWidthGroup } from "../../components/core/useObserveElementWidthGroup";
import { useTranslation } from "react-i18next";
import TranslationHelper from '../../translations/TranslationHelper';

function SarkilarScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SarkilarScreenInner genelDurumHelper={genelDurumHelper} />
}

function SarkilarScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const { sizeName, ref } = useObserveElementWidthGroup<HTMLDivElement, "none" | "small" | "large">({ 1: "none", 711: "small", 10000: "large" });

  const sarkiRowsGetir = (sarkiList: SarkiModel[], guncelMi: boolean, siraNoGoster: boolean): JSX.Element[] | null => {
    if (sarkiList == null || sarkiList.length === 0)
      return null;

    return sarkiList.map((sarki) => <SarkiKutuDisplayer
      key={sarki.id}
      sarki={sarki}
      guncelMi={guncelMi}
      siraNoGoster={siraNoGoster}
      genelDurumHelper={genelDurumHelper}
      useExpand={sizeName === "small"}
    />);
  }

  const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.orderByTurkish(e => e.isim);
  const sezonSarkilari = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id));
  const sezonDisiSarkilar = tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id) === false);

  const guncelSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === true).orderBy(e => e.sira);
  const bekleyenSarkilar = sezonSarkilari.where(e => e.kayitGondermeSurecinde === false).orderBy(e => e.sira);

  const eskiSarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length > 0).orderByTurkish(e => e.isim);
  const adaySarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length === 0).orderByTurkish(e => e.isim);

  return (
    <MainLayout
      location={mainScreenLocations.sarkilar}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <div ref={ref} style={{ width: "100%", visibility: sizeName === "none" ? "hidden" : undefined }}>
        <VatanAccordionSimple
          bigCenteredTitle
          title={genelDurumHelper.translate("Güncel Şarkılar")}
          children={sarkiRowsGetir(guncelSarkilar, true, true) ?? <MyText note>{genelDurumHelper.translate("Güncel şarkı bulunmamaktadır")}</MyText>}
          alwaysOpen
        />
        <VatanAccordionSimple
          bigCenteredTitle
          title={genelDurumHelper.translate("Bekleyen Şarkılar")}
          children={sarkiRowsGetir(bekleyenSarkilar, false, true) ?? <MyText note>{genelDurumHelper.translate("Bekleyen şarkı bulunmamaktadır")}</MyText>}
          alwaysOpen
        />
        {eskiSarkilar.length === 0 ? null :
          <VatanAccordionSimple
            bigCenteredTitle
            title={genelDurumHelper.translate("Eski Şarkılar")}
            children={() => (sarkiRowsGetir(eskiSarkilar, false, false) ?? <MyText note>{genelDurumHelper.translate("Eski şarkı bulunmamaktadır")}</MyText>)}
          />}
        {adaySarkilar.length === 0 ? null :
          <VatanAccordionSimple
            title={genelDurumHelper.translate("Diğer Şarkılar")}
            children={sarkiRowsGetir(adaySarkilar, false, false) ?? <MyText note>{genelDurumHelper.translate("Diğer şarkı bulunmamaktadır")}</MyText>}
            bigCenteredTitle
          />
        }
      </div>
    </MainLayout>
  );
}

export default SarkilarScreen;
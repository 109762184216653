import { FocusEvent, useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField, TextFieldProps, ThemeProvider, Typography } from "@mui/material";
import { narrowTheme } from "../../app/theme";
import { appIcons } from "../../app/appIcons";
import StringHelper from "../../helpers/StringHelper";

type selfProps = {
    onChange: (e: string) => void,
    value: string | null | undefined,
    showStats?: boolean,
    showClear?: boolean,
    narrow?: boolean,
    appendMaxlenOnLabel?: number,
};
export type IVatanTextFieldProps = Omit<TextFieldProps, "onChange" | "type" | "value"> & selfProps;

function VatanTextField(props: IVatanTextFieldProps & { eventOnKeyPress?: boolean }) {

    const { narrow, eventOnKeyPress, ...rest } = props;

    const result = eventOnKeyPress ?
        <VatanTextFieldSlow {...rest} />
        :
        <VatanTextFieldFast {...rest} />

    if (narrow)
        return <ThemeProvider theme={narrowTheme}>{result}</ThemeProvider>
    else
        return result;
}

function kelimeSay(icerik: string | null | undefined) {
    const count = icerik?.length === 0 ? 0 : icerik?.trim().split(/\s+/).length ?? 0;
    return count === 0 ? "-" : count.toString();
}

function harfSay(icerik: string | null | undefined) {
    const count = icerik?.length ?? 0;
    return count === 0 ? "-" : count.toString();
}

function VatanTextFieldFast(props: IVatanTextFieldProps) {

    const { appendMaxlenOnLabel, showStats, showClear, fullWidth, onChange, value, label, ...rest } = props;
    const [stateValue, setStateValue] = useState<string | null | undefined>(value);

    useEffect(() => {
        setStateValue(() => { return props.value })
    }, [props.value]);

    const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        e.target.value = String(stringValue);
        onChange(stringValue);
    };

    const labelToUse = !appendMaxlenOnLabel ? label : (StringHelper.nullToEmpty(stateValue).length <= appendMaxlenOnLabel ? label : `${label} (max ${appendMaxlenOnLabel})`);

    const innerContent = <TextField
        label={labelToUse}
        value={stateValue}
        type="text"
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        onChange={(e) => setStateValue(() => { return e.target.value })}
        onBlur={onBlur}
        InputProps={!showClear ? undefined : {
            endAdornment: <InputAdornment position="end">
                <IconButton
                    onClick={() => onChange("")}
                    onMouseDown={e => e.preventDefault()}
                    edge="end">
                    {appIcons.Genel.backspaceLight}
                </IconButton>
            </InputAdornment>
        }}                
        {...rest}
    />

    if (showStats === true)
        return <>
            {innerContent}
            <Typography
                style={{ marginTop: -14, marginRight: 5 }}
                fontStyle="italic"
                fontSize={9}
                textAlign="right">{`${kelimeSay(stateValue)} kelime, ${harfSay(stateValue)} harf`}
            </Typography>
        </>
    else
        return innerContent;

}

function VatanTextFieldSlow(props: IVatanTextFieldProps) {

    const onChangeInternal = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        e.target.value = String(stringValue);
        onChange(stringValue);
    };

    const { appendMaxlenOnLabel, label, showStats, showClear, fullWidth, onChange, value, ...rest } = props;

    const labelToUse = !appendMaxlenOnLabel ? label : (StringHelper.nullToEmpty(value).length <= appendMaxlenOnLabel ? label : `${label} (max ${appendMaxlenOnLabel})`);

    const innerContent = <TextField
        label={labelToUse}
        value={value}
        type="text"
        fullWidth={fullWidth !== undefined ? fullWidth : true}
        onChange={onChangeInternal}
        InputProps={!showClear ? undefined : {
            endAdornment: <InputAdornment position="end">
                <IconButton
                    onClick={() => onChange("")}
                    onMouseDown={e => e.preventDefault()}
                    edge="end">
                    {appIcons.Genel.backspaceLight}
                </IconButton>
            </InputAdornment>
        }}                
        {...rest}
    />;

    if (showStats === true)
        return <>
            {innerContent}
            <Typography
                style={{ marginTop: -14, marginRight: 5 }}
                fontStyle="italic"
                fontSize={9}
                textAlign="right">{`${kelimeSay(value)} kelime, ${harfSay(value)} harf`}
            </Typography>
        </>
    else
        return innerContent;

}

export default VatanTextField;
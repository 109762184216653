/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { Chip, FormControl, SxProps, Theme } from "@mui/material";
import React, { CSSProperties, useState } from "react";
import appColors from "../../app/appColors";
import Columns from "../layout/Columns";
import Cell from "../layout/Cell";
import Rows from "../layout/Rows";
import { MyText } from "./MyText";
import "drag-drop-touch";
import { StringOrNumber } from "../../helpers/StringOrNumber";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

export interface IGenericOrderedListMultiSelectField<T, U extends StringOrNumber> {
    id?: string,
    value: U[] | null,
    label: string,
    itemLabel: string,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    onChange: (e: U[]) => void,
    defaultOrderFunc?: (e: U[]) => U[],
    sortable?: boolean,
    error?: boolean,
    fontSize?: number,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
    isItemInactive?: (e: T) => boolean,
    isItemDisabled?: (e: U) => boolean,
    genelDurumHelper: GenelDurumHelper,
}

export default function GenericTwoBoxListMultiSelectField<T, U extends StringOrNumber>(props: IGenericOrderedListMultiSelectField<T, U>) {
    const { defaultOrderFunc, sortable, isItemDisabled, isItemInactive, id, value, label, itemLabel, list, labelGetter, valueGetter, onChange, genelDurumHelper, error, fontSize, sx, disabled, style } = props;

    const [movingValue, setMovingValue] = useState<U | null>(null);
    const [movingOrderedList, setMovingOrderedList] = useState<U[]>(value?.slice() ?? []);

    function calculateNewOrderedList2(list: U[], source: U, dest: U) {
        const newList = list.slice();
        const sourceIndex = list.indexOf(source);
        const destIndex = list.indexOf(dest);

        if (newList.length === 0)
            return [];

        const d = newList[sourceIndex];
        newList.splice(sourceIndex, 1);
        newList.splice(destIndex, 0, d);
        return newList;
    }

    const chipClicked = (newValue: U) => {
        const newIdList = value?.slice() ?? [];
        if (isSelected(newValue))
            newIdList.remove(newValue);
        else
            newIdList.push(newValue);

        onChange(newIdList);
        setMovingOrderedList(newIdList);
    }

    const onChipDrop = (value: U, event: any) => {
        // taşıma işlemi, bu kontrolün üzerinde tamamlanınca tetiklenir
        event.preventDefault();
        setMovingValue(null);
        onChange(movingOrderedList);
    }

    const onDragEnter = (value: U, event: any) => {
        // taşıma işlemi, bu controlün üzerinden geçmeye başladığında tetiklenir
        if (value !== movingValue && !!movingValue) {
            setMovingOrderedList(calculateNewOrderedList2(movingOrderedList, movingValue!, value));
        }
        event.preventDefault();
    }

    const onDragOver = (value: U, event: any) => {
        // taşıma işlemi, bu controlün üzerinden geçerken (pek çok kere) tetiklenir
        event.preventDefault();
    }

    const onDragStart = (value: U, event: any) => {
        // taşıma işlemi bu control üzerinden tutularak başlayınca tetiklenir
        setMovingValue(value);
    }

    const onDragEnd = (value: U, event: any) => {
        // taşıma işleminden vazgeçilince (veya tamamlanınca) tetiklenir
        setMovingValue(null);
    }

    const isSelected = (e: U) => (value?.contains(e) ?? false);
    const isDisabled = (e: U) => (isItemDisabled ? isItemDisabled(e) : false);
    const isLight = (e: T) => isItemInactive ? isItemInactive(e) : false;

    function act(arg0: string, arg1: () => void): void {
        arg1();
    }

    const getSelectedItemLabel = (index: number, itemValue: StringOrNumber) => {
        return sortable ?
            (index + 1).toFixed(0) + ". " + labelGetter(list.single(e => valueGetter(e) === itemValue))?.toString().trim()
            :
            labelGetter(list.single(e => valueGetter(e) === itemValue))?.toString().trim();
    }

    function orderIfNecessary(items: U[]): U[] {
        return sortable ? items : !defaultOrderFunc ? items : defaultOrderFunc(items);
    }

    return (
        <FormControl disabled={disabled} fullWidth style={{ marginTop: 0, ...style }} >
            <fieldset style={{ borderRadius: "4px", borderColor: error ? appColors.RED_MAIN : appColors.BLACK023, borderWidth: "1px" }}>
                <legend style={{ fontSize: "12px", fontWeight: 400, color: error ? appColors.RED_MAIN : appColors.BLACK060, }}>{label}</legend>
                <Columns>
                    <Rows flex style={{ minWidth: "0px", borderRightColor: appColors.BLACK023, borderRightWidth: "1px", borderRightStyle: "solid", marginTop: "-13px", marginBottom: "-11px", paddingTop: "13px", paddingBottom: "11px" }}>
                        <Cell style={{ borderBottomColor: appColors.BLACK023, borderBottomWidth: "1px", borderBottomStyle: "solid", marginTop: "-10px", marginLeft: "-13px" }}>
                            <Columns style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                                <MyText style={{ textAlign: "center" }}>{genelDurumHelper.translate("Seçilmeyenler")}</MyText>
                                <MyText smallNote style={{ marginLeft: "5px" }}>{`${list.length - movingOrderedList.length} ${itemLabel}`}</MyText>
                            </Columns>
                        </Cell>
                        <Cell style={{ padding: "10px" }}>
                            <Columns wrap>
                                {list.where(e => !isSelected(valueGetter(e))).select(e => valueGetter(e)).map(itemValue =>
                                    <Chip disabled={isDisabled(itemValue)} onClick={_ => chipClicked(itemValue)} key={itemValue} style={{ margin: "5px" }} label={labelGetter(list.single(e => valueGetter(e) === itemValue))?.toString().trim()} />
                                )}
                            </Columns>
                        </Cell>
                        <Cell flex></Cell>
                        <Columns style={{ marginBottom: "-12px", marginLeft: "-12px" }}>
                            <MyText smallNote italic style={{ marginLeft: "5px", color: appColors.GRAY_LIGHT2 }}>{genelDurumHelper.translate("Listeye eklemek için tıklayabilirsiniz")}</MyText>
                        </Columns>
                    </Rows>
                    <Rows flex style={{ minWidth: "0px" }}>
                        <Cell style={{ borderBottomColor: appColors.BLACK023, borderBottomWidth: "1px", borderBottomStyle: "solid", marginTop: "-10px", marginRight: "-13px" }}>
                            <Columns style={{ alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                                <MyText style={{ textAlign: "center" }}>{genelDurumHelper.translate("Seçilenler")}</MyText>
                                <MyText smallNote style={{ marginLeft: "5px" }}>{`${movingOrderedList.length} ${itemLabel}`}</MyText>
                            </Columns>
                        </Cell>
                        <Cell style={{ padding: "10px" }}>
                            <Columns wrap>
                                {orderIfNecessary(movingOrderedList).map((itemValue, index) =>
                                    <Chip draggable={!!sortable} id={itemValue.toString()}
                                        onDragStart={!sortable ? undefined : e => act("onDragStart", () => onDragStart(itemValue, e))}
                                        onDragOver={!sortable ? undefined : e => act("onDragOver", () => onDragOver(itemValue, e))}
                                        onDragEnter={!sortable ? undefined : e => act("onDragEnter", () => onDragEnter(itemValue, e))}
                                        onDragEnd={!sortable ? undefined : e => act("onDragEnd", () => onDragEnd(itemValue, e))}
                                        onDrop={!sortable ? undefined : e => act("onDrop", () => onChipDrop(itemValue, e))}
                                        onClick={_ => chipClicked(itemValue)}
                                        key={itemValue}
                                        style={{ margin: "5px", backgroundColor: movingValue === itemValue ? appColors.PRIMARY_LIGHT : undefined }}
                                        label={getSelectedItemLabel(index, itemValue)} />
                                )}
                            </Columns>
                        </Cell>
                        <Cell flex></Cell>
                        <Columns style={{ marginBottom: "-12px" }}>
                            <MyText smallNote italic style={{ marginLeft: "5px", color: appColors.GRAY_LIGHT2 }}>{genelDurumHelper.translate("Sıralarını değiştirmek için taşıyabilirsiniz. Listeden çıkartmak için tıklayabilirsiniz")}</MyText>
                        </Columns>
                    </Rows>
                </Columns>
            </fieldset>
        </FormControl>
    );
}
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react';
import IcerikHelper, { KorocanBase64DosyaModel } from '../../helpers/IcerikHelper';

let draggingCount = 0;
type Params = {
    labelRef: any;
    multiple?: boolean;
    validateFiles: (arg0: File[]) => boolean;
    onDrop?: (arg0: File[]) => void;
    onDropBase64Async?: (items: KorocanBase64DosyaModel[]) => void;
};

/**
 *
 * @param data - labelRef, inputRef, handleChanges, onDrop
 * @returns boolean - the state.
 *
 * @internal
 */
export default function useDragging({
    labelRef,
    multiple,
    validateFiles,
    onDrop,
    onDropBase64Async
}: Params): boolean {
    const [dragging, setDragging] = useState(false);

    const handleDropBase64Async = async (fileList: File[]) => {
        const responses = await IcerikHelper.ConvertFilesToIcerikBase64(fileList);
        onDropBase64Async!(responses);
    }

    const handleDragIn = useCallback((ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();
        draggingCount++;
        if (ev.dataTransfer.items && ev.dataTransfer.items.length !== 0) {
            setDragging(true);
        }
    }, []);

    const handleDragOut = useCallback((ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();
        draggingCount--;
        if (draggingCount > 0) return;
        setDragging(false);
    }, []);

    const handleDrag = useCallback((ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();
    }, []);

    const handleDrop = useCallback(
        (ev: any) => {
            ev.preventDefault();
            ev.stopPropagation();
            setDragging(false);
            draggingCount = 0;

            const eventFiles = ev.dataTransfer.files;
            if (eventFiles && eventFiles.length > 0) {
                const files = multiple ? eventFiles : [eventFiles[0]];

                const fileList: File[] = [];
                for (let i = 0; i < files.length; i++)
                    fileList.push(files[i]);

                const success = validateFiles(fileList);
                if (!success)
                    return;

                if (onDrop) onDrop(fileList);
                if (onDropBase64Async)
                    handleDropBase64Async(fileList);
            }
        },
        [validateFiles]
    );

    useEffect(() => {
        const ele = labelRef.current;

        if (!ele) return;
        //ele.addEventListener('click', handleClick);
        ele.addEventListener('dragenter', handleDragIn);
        ele.addEventListener('dragleave', handleDragOut);
        ele.addEventListener('dragover', handleDrag);
        ele.addEventListener('drop', handleDrop);

        return () => {
            //ele.removeEventListener('click', handleClick);
            ele.removeEventListener('dragenter', handleDragIn);
            ele.removeEventListener('dragleave', handleDragOut);
            ele.removeEventListener('dragover', handleDrag);
            ele.removeEventListener('drop', handleDrop);
        };
    }, [
        //handleClick,
        handleDragIn,
        handleDragOut,
        handleDrag,
        handleDrop,
        labelRef
    ]);

    return dragging;
}
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { KadroModel } from "../../models/businessModels/KadroModel";
import GenericListMultiSelectField, { IGenericListMultiSelectFieldProps } from "../common/GenericListMultiSelectField";

export type IVatanKadroMultiSelectFieldProps = Omit<IGenericListMultiSelectFieldProps<KadroModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumKadroList: KadroModel[] };

function VatanKadroMultiSelectField(props: IVatanKadroMultiSelectFieldProps) {
    const { tumKadroList, ...rest } = props;
    const orderedKadroList = tumKadroList.orderBy(BusinessHelper.Kadro.SortFunc);

    return (
        <GenericListMultiSelectField
            {...rest}
            list={orderedKadroList}
            labelGetter={e => e.isim}
            valueGetter={e => e.id}
            isItemInactive={e => !e.aktif}
        />
    )
}

export default VatanKadroMultiSelectField;
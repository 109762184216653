import React, { useState } from "react";
import { IconButton } from "@mui/material";
import Columns from "../layout/Columns";
import { appIcons } from "../../app/appIcons";
import { CSSProperties } from "@mui/material/styles/createMixins";
import appColors from "../../app/appColors";
import StringHelper from "../../helpers/StringHelper";
import Cell from "../layout/Cell";
import Rows, { RowsProps } from "../layout/Rows";

export interface IVatanLightCollapsableContainerProps extends Omit<RowsProps, "children"> {
    collapsedTitle?: string,
    expandedTitle?: string,
    children: React.ReactNode,
    initialOpen?: boolean,
    innerContainerStyle?: CSSProperties,
}

export default function VatanLightCollapsableContainer(props: IVatanLightCollapsableContainerProps) {
    const { collapsedTitle, expandedTitle, children, initialOpen, innerContainerStyle, ...others } = props;

    const [expanded, setExpanded] = useState<boolean>(initialOpen === false ? true : false);

    return <Rows {...others}>
        <Columns height={24} style={{ overflow: "hidden", marginLeft: "-5px", marginRight: "-5px", marginTop: "-5px", fontStyle: "italic", fontSize: "12px", color: appColors.TEXT_SECONDARY, fontWeight: "200" }}>
            <Columns flex style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, background: appColors.GRAY_LIGHT4, borderBottom: !expanded ? "1px solid gray" : undefined, overflow: "hidden" }} dontGrowForChild>
                <Columns style={{ marginLeft: "5px", marginTop: "4px" }}>
                    <div style={{ whiteSpace: "nowrap" }}>{expanded ? StringHelper.nullToEmpty(expandedTitle) : StringHelper.nullToEmpty(collapsedTitle)}</div>
                </Columns>
                <Cell flex style={innerContainerStyle}></Cell>
                <IconButton onClick={() => setExpanded(!expanded)}>{expanded ? appIcons.Genel.expandMore : appIcons.Genel.expandLess}</IconButton>
            </Columns>
        </Columns>
        <div style={{ display: !expanded ? "none" : undefined, ...innerContainerStyle }}>
            {children}
        </div>
    </Rows>
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { CalismaModel } from "../../../models/businessModels/CalismaModel";
import { HesapCalismaModel } from "../../../models/businessModels/HesapCalismaModel";
import Cell from "../../../components/layout/Cell";
import Columns from "../../../components/layout/Columns";
import appColors from "../../../app/appColors";
import { EnmKatilimBildirmeDurum } from "../../../models/enums/EnmKatilimBildirmeDurum";
import { mainScreenLocations } from "../../../app/mainScreens";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { EnmKatilimDurum } from "../../../models/enums/EnmKatilimDurum";
import { Button, FormControl, IconButton, Switch, Typography, alpha } from "@mui/material";
import CizelgeLegend from "./CizelgeLegend";
import { CizelgeCellInfo, CizelgeColumnInfo, CizelgeData, CizelgeRowInfo, CizelgeTable, GetColorForHesap, GetColorForPercent, ICizelgeStyles } from "./CizelgeTable";
import Rows from "../../../components/layout/Rows";
import VatanKadroSelectField from "../../../components/vatan/VatanKadroSelectField";
import { MyText } from "../../../components/common/MyText";
import VatanAltSezonMultiSelectField from "../../../components/vatan/VatanAltSezonMultiSelectField";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import VatanNumericField from "../../../components/common/VatanNumericField";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import AlertHelper from "../../../helpers/AlertHelper";
import StringHelper from "../../../helpers/StringHelper";
import { appIcons } from "../../../app/appIcons";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import VatanLightCollapsableContainer from "../../../components/vatan/VatanLightCollapsableContainer";

const mainStyles: ICizelgeStyles = {
    cellHeight: 15,
    cellWidth: 15,
    rightmostColumnWidth: 70,
    firstColumnWidth: 135,
    firstRowHeight: 160,
    leftHeaderColumnFontSize: "10px",
    topHeaderRowFontSize: "8px",
    firstRowGroupInnerHeight: 30, // ay başlıkları
    bottomSummaryRowHeight: 150,
}

const mainStylesTekKisi: ICizelgeStyles = {
    cellHeight: mainStyles.cellHeight,
    cellWidth: mainStyles.cellWidth,
    rightmostColumnWidth: mainStyles.rightmostColumnWidth,
    firstColumnWidth: mainStyles.firstColumnWidth,
    firstRowHeight: 250,
    leftHeaderColumnFontSize: mainStyles.leftHeaderColumnFontSize,
    topHeaderRowFontSize: mainStyles.topHeaderRowFontSize,
    firstRowGroupInnerHeight: mainStyles.firstRowGroupInnerHeight, // ay başlıkları
    bottomSummaryRowHeight: 0,
}


type YoklamaCellInfo = CizelgeCellInfo<CalismaModel, HesapBilgiVeKullaniciModel, HesapCalismaModel | null>;
type YoklamaData = CizelgeData<CalismaModel, HesapBilgiVeKullaniciModel, HesapCalismaModel | null>;
type YoklamaColumnInfo = CizelgeColumnInfo<CalismaModel>;
type YoklamaRowInfo = CizelgeRowInfo<HesapBilgiVeKullaniciModel>;

function YoklamaCizelgeScreen() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <YoklamaCizelgeScreenInner genelDurumHelper={genelDurumHelper} />
}

function showCalismaData(calismaModel: CalismaModel, altSezonlariFiltrele: boolean, seciliAltSezonIdList: string[]) {
    return (
        !altSezonlariFiltrele ||
        seciliAltSezonIdList.length === 0 ||
        (calismaModel.altSezonModelId == null && seciliAltSezonIdList.contains("-")) ||
        (calismaModel.altSezonModelId != null && seciliAltSezonIdList.contains(calismaModel.altSezonModelId))
    );
}

function altSezonRenkGetir(altSezonId: string | null, altSezonlariFiltrele: boolean, tumSezonlar: SezonModel[]) {
    if (!altSezonlariFiltrele || !altSezonId) return undefined;

    const color = BusinessHelper.Sezon.GetAltSezonColor(altSezonId, tumSezonlar)
    return color ? alpha(color, 0.3) : undefined;
}


function YoklamaCizelgeScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const altSezonlarKullanilsin = genelDurumHelper.SeciliKoroModel.koroAyarlari.altSezonlarKullanilsin;
    const yoklamaYonetimYetkisi = genelDurumHelper.HasYetkiAny([EnmYetki["Yoklama Yönetim"]]);

    const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);
    const [seciliAltSezonIdList, setSeciliAltSezonIdList] = useState<string[]>(["-", ...genelDurumHelper.AktifSezon.altSezonlar.select(e => e.id)]);
    const [eskiKoristGoster, setEskiKoristGoster] = useState<boolean>(false);
    const [araVerenKoristGoster, setAraVerenKoristGoster] = useState<boolean>(false);
    const [altSezonlariFiltrele, setAltSezonlariFiltrele] = useState<boolean>(false);
    const [filtrelenenCalismalariGoster, setFiltrelenenCalismalariGoster] = useState<boolean>(true);
    const [detayVeWhatIfGoster, setDetayVeWhatIfGoster] = useState<boolean>(false);
    const [whatIfGunSayisi, setWhatIfGunSayisi] = useState<number>(0);
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const tumSezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar;
    const seciliSezon = tumSezonlar.singleOrDefault(e => e.id === seciliSezonId);

    const koroAyarlari = genelDurumHelper.SeciliKoroModel.koroAyarlari;
    const tumCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar;
    const tumSezonCalismalar = tumCalismalar.where(e => e.sezonModelId === seciliSezonId);
    const tumSezonCalismaIdler = tumSezonCalismalar.select(e => e.id);
    const tumSezonHesapCalismalar = genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.where(e => tumSezonCalismaIdler.contains(e.calismaModelId));
    // showCalismaData metodu ile filtrelenmis liste
    const filteredSezonHesapCalismalar = tumSezonHesapCalismalar.where(e => showCalismaData(tumCalismalar.single(t => t.id === e.calismaModelId), altSezonlariFiltrele, seciliAltSezonIdList));
    const filteredSezonCalismalar = tumSezonCalismalar.where(e => showCalismaData(e, altSezonlariFiltrele, seciliAltSezonIdList));

    const sadeceKendisiMi = genelDurumHelper.SeciliHesapBilgiModel.kadroVeSesGrubuBilgiModelList.length > 0 && tumSezonHesapCalismalar.select(e => e.hesapModelId).distinct().length === 1;
    const secilebilirKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === seciliSezonId).where(e => !sadeceKendisiMi || BusinessHelper.Kadro.HesapKadroyaDahilMi(genelDurumHelper.SeciliHesapBilgiModel, e.id));

    const kisininBulunduguKadrolar = secilebilirKadrolar.where(e => genelDurumHelper.SeciliHesapBilgiModel.kadroVeSesGrubuBilgiModelList.any(t => t.kadroModelId === e.id)).orderBy(e => e.olusturmaTarih);
    const listToUseForInitialKAdro = (kisininBulunduguKadrolar.length > 0 ? kisininBulunduguKadrolar : secilebilirKadrolar).orderBy(e => e.olusturmaTarih);
    const [seciliKadroId, setSeciliKadroId] = useState<string>(
        StringHelper.nullToEmpty(listToUseForInitialKAdro.firstOrDefault()?.id));

    const secilebilirSezonlar = sadeceKendisiMi ? BusinessHelper.Genel.KoristinKatildigiSezonlariGetir(genelDurumHelper) : tumSezonlar;
    const tumBildirimYapılanHesapIdLer = tumSezonHesapCalismalar.select(e => e.hesapModelId).distinct();


    const koristList = genelDurumHelper
        .SeciliKoroModel
        .hesapBilgiList
        .where(e => tumBildirimYapılanHesapIdLer.contains(e.id) && BusinessHelper.Genel.KoristMi(e))
        .where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, seciliKadroId))
        .where(e => !e.araVerdi || araVerenKoristGoster)
        .where(e => e.aktif || eskiKoristGoster)
        .orderByTurkish(e => `${e.kullaniciModel.ad} ${e.kullaniciModel.soyad}`);

    const contentSolUst = <CizelgeLegend
        miniWidth={mainStyles.cellWidth}
        miniHeight={mainStyles.cellHeight}
        textWidth={90}
        fontSize={8}
        items={[
            { text: "Geçmiş Çalışmalar", color: "" },
            { text: "Gelinen Çalışma", color: appColors.LIGHTGREEN },
            { text: "Gelinmeyen Çalışma", color: appColors.LIGHTRED },
            { text: "Geç Kalınan Çalışma", color: appColors.LIGHTYELLOW },
            { text: "İleri Tarihli Çalışmalar", color: "" },
            { text: "Gelinecek Çalışma", color: appColors.VERYLIGHTGREEN },
            { text: "Gelinemeyecek Çalışma", color: appColors.VERYLIGHTRED },
            { text: "Belirsiz Çalışma", color: appColors.VERYLIGHTYELLOW },
        ]}
    />

    /*--*/

    const myCalismaInfoList: YoklamaColumnInfo[] = (filtrelenenCalismalariGoster ? tumSezonCalismalar : filteredSezonCalismalar).orderBy(e => e.tarihBaslangic).map((item, i) => {
        return {
            id: item.id,
            backgroundColor: altSezonRenkGetir(item.altSezonModelId, altSezonlariFiltrele, tumSezonlar),
            data: item,
            group: DateTimeHelper.toMonthAndYearString(item.tarihBaslangic, genelDurumHelper.translate),
            order: i,
        }
    });

    const hesabinSesAltGrubunuGetir = (hesap: HesapBilgiVeKullaniciModel) => {
        const bulunanGrupId = hesap.kadroVeSesGrubuBilgiModelList.singleOrDefault(e => e.kadroModelId === seciliKadroId)?.altSesGrupModelId;
        return bulunanGrupId ? genelDurumHelper.SeciliKoroModel.tumSesGruplari.single(e => e.id === bulunanGrupId) : BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => hesap.sesGrupIdList.contains(e.id)))!;
    };

    const sesGruplar = koristList.select(korist => hesabinSesAltGrubunuGetir(korist)).distinct().orderBy(e => e.sira);
    const myKoristInfoList: YoklamaRowInfo[] = sesGruplar.flatMap(sesGrup => koristList.where(e => hesabinSesAltGrubunuGetir(e).id === sesGrup.id).map((korist, i) => {
        return {
            id: korist.id,
            data: korist,
            backColor: GetColorForHesap(korist),
            group: sesGrup!.isim,
            order: i,
        }
    }));

    const myData: YoklamaCellInfo[] = myKoristInfoList.flatMap(korist => myCalismaInfoList.map(calisma => {
        return {
            column: calisma,
            row: korist,
            data: !showCalismaData(calisma.data, altSezonlariFiltrele, seciliAltSezonIdList) ? null : tumSezonHesapCalismalar.singleOrDefault(e => e.calismaModelId === calisma.data.id && e.hesapModelId === korist.data.id),
        }
    }));

    const mainData: YoklamaData = {
        cells: myData,
        columns: myCalismaInfoList,
        rows: myKoristInfoList,
        hasSummaryBottomRow: true,
        hasSummaryRightmostColumn: true,
        GetRowsFirstColumnString: (data: HesapBilgiVeKullaniciModel) => (data.kullaniciModel.ad + " " + data.kullaniciModel.soyad),
        GetColumnsFirstRowContent: (data: CalismaModel) => `${DateTimeHelper.toDayAndDayOfWeekString(data.tarihBaslangic, genelDurumHelper.translate)} - ${data.icerik}`,
        GetColumnsFirstRowSummaryHeaderString: "Kişi Ağırlıklı Ortalaması",
        GetRowsFirstColumnSummaryHeaderString: "Çalışma Ortalaması",
        GetCellContent: GenerateKisiCalismaKutusu,
        GetRowsLastSummaryColumnCellContent: GenerateKisininOrtalamasiKutusu,
        GetColumnsLastSummaryRowCellContent: GenerateCalismaninOrtalamasiKutusu,
        GetGlobalSummaryCellContent: GenerateGlobalOrtalamaKutu,
        GetLeftTopContent: contentSolUst,
    };

    function GenerateKisiCalismaKutusu(cellInfo: YoklamaCellInfo) {
        const calisma = cellInfo.column.data;
        const hesap = cellInfo.row.data;

        let backgroundColor: string | undefined = undefined;

        // uninvited bildirim color
        if (DateTimeHelper.getDateWithoutTime(calisma.tarihBaslangic) < DateTimeHelper.today())
            backgroundColor = appColors.WHITEALPHA_01;

        if (cellInfo.data != null) {
            if (cellInfo.data.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı)
                backgroundColor = appColors.LIGHTGREEN;
            else if (cellInfo.data.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"])
                backgroundColor = appColors.LIGHTYELLOW;
            else if (cellInfo.data.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı)
                backgroundColor = appColors.LIGHTRED;
            else if (cellInfo.data.katilimDurum === EnmKatilimBildirmeDurum.Katılacağım)
                backgroundColor = appColors.VERYLIGHTGREEN;
            else if (cellInfo.data.katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum)
                backgroundColor = appColors.VERYLIGHTRED;
            else if (cellInfo.data.katilimDurum === EnmKatilimBildirmeDurum.Belirsiz)
                backgroundColor = appColors.VERYLIGHTYELLOW;
        }
        else {
            // ileri tarihli bildirimsiz color
            if (DateTimeHelper.getDateWithoutTime(calisma.tarihBaslangic) >= DateTimeHelper.today()) {
                if (BusinessHelper.Calisma.CalismaKisiyeUygunMu(calisma, hesap, []))
                    backgroundColor = appColors.GREYALPHA_02;
                else
                    backgroundColor = appColors.WHITEALPHA_01;
            }
        }

        return { text: "", backcolor: backgroundColor }
    }

    function GenerateKisininOrtalamasiKutusu(row: HesapBilgiVeKullaniciModel) {
        const hesapcalismaList = filteredSezonHesapCalismalar.where(e => e.hesapModelId === row.id);
        const kisiGelen = hesapcalismaList.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı)
        const kisiGecGelen = hesapcalismaList.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"]);
        const kisiGelmeyen = hesapcalismaList.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı);

        const toplam = kisiGelen + kisiGecGelen + kisiGelmeyen;
        const ek = detayVeWhatIfGoster ? whatIfGunSayisi : 0;
        const oran = (kisiGelen + ek + kisiGecGelen * koroAyarlari.gecikmeEtki) / (toplam + ek);

        const extraText = !detayVeWhatIfGoster ? "" : whatIfGunSayisi === 0 ? `(${kisiGelen + kisiGecGelen}/${toplam})` : `(${kisiGelen + kisiGecGelen + ek}/${toplam + ek} + ${ek})`;
        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari), extraText: extraText };
    }

    function GenerateCalismaninOrtalamasiKutusu(column: CalismaModel) {
        const alakaliHesapCalismalar = tumSezonHesapCalismalar.where(e => e.calismaModelId === column.id)
        const kisiGelen = alakaliHesapCalismalar.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı || e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"]);
        const kisiGelmeyen = alakaliHesapCalismalar.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı);
        const oran = kisiGelen + kisiGelmeyen > 0 ? kisiGelen / (kisiGelen + kisiGelmeyen) : 0;

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari) };
    }

    function GenerateGlobalOrtalamaKutu() {
        const kisiGelen = tumSezonHesapCalismalar.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı || e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"]);
        const kisiGelmeyen = tumSezonHesapCalismalar.count(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı);
        const oran = kisiGelen + kisiGelmeyen > 0 ? kisiGelen / (kisiGelen + kisiGelmeyen) : 0;

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari) };
    }

    function kopyala() {
        const subData = mainData.rows.select(e => { return { isim: e.data.kullaniciModel.ad + " " + e.data.kullaniciModel.soyad, number: GenerateKisininOrtalamasiKutusu(e.data).percent * 100 } });

        navigator.clipboard.writeText(subData.orderByDesc(e => e.number).select(e => e.isim + " - %" + e.number.toFixed()).join("\n"));
        AlertHelper.ShowMessage("Dikkat", "Tablo Kopyalandı")
    }

    VatanAccordionSimple
    const filtreSummaryBar = <Columns height={24} style={{ overflow: "hidden", marginLeft: "-5px", marginRight: "-5px", marginTop: "-5px", fontStyle: "italic", fontSize: "12px", color: appColors.TEXT_SECONDARY, fontWeight: "200" }}>
        <Columns flex style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, background: appColors.GRAY_LIGHT4, borderBottom: collapsed ? "1px solid gray" : undefined, overflow: "hidden" }} dontGrowForChild>
            <Columns style={{ marginLeft: "5px", marginTop: "4px", visibility: collapsed ? "visible" : "hidden" }}>
                <div style={{ whiteSpace: "nowrap" }}>Filtreler</div>
            </Columns>
            <Cell flex></Cell>
            <IconButton onClick={() => setCollapsed(!collapsed)}>{collapsed ? appIcons.Genel.expandMore : appIcons.Genel.expandLess}</IconButton>
        </Columns>
    </Columns>

    return (
        <MainLayout
            location={mainScreenLocations.yoklamaCizelge}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}
            disallowScroll={true}>

            <Rows dontGrowForChild style={{ marginLeft: "10px", marginTop: "10px", minHeight: 0 }}>
                <Rows>
                    <VatanLightCollapsableContainer collapsedTitle="Filtreler"> 
                        <Columns hasLightBottomBorder wrap style={{ marginTop: "10px", marginBottom: "10px" }}>
                            {secilebilirSezonlar.length === 1 ? null :
                                <Cell style={{ flexGrow: 0, minWidth: "250px", marginBottom: "10px" }}>
                                    <VatanSezonSelectField
                                        value={seciliSezonId}
                                        label="Seçili Sezon"
                                        tumSezonlar={secilebilirSezonlar}
                                        onChange={value => {
                                            setSeciliSezonId(value);
                                            setSeciliAltSezonIdList(["-", ...tumSezonlar.single(e => e.id === value).altSezonlar.select(e => e.id)]);
                                        }}
                                    />
                                </Cell>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ width: "250px", marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "10px" }}>
                                    <VatanKadroSelectField
                                        value={secilebilirKadrolar.any(e => e.id === seciliKadroId) ? seciliKadroId : ""}
                                        label="Seçili Kadro"
                                        tumKadrolar={secilebilirKadrolar}
                                        onChange={value => setSeciliKadroId(value)}
                                    />
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "10px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={araVerenKoristGoster} onChange={(e) => setAraVerenKoristGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{araVerenKoristGoster ? "Ara Veren Koristleri Göster" : "Ara Veren Koristleri Gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {sadeceKendisiMi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "10px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={eskiKoristGoster} onChange={(e) => setEskiKoristGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{eskiKoristGoster ? "Eski Koristleri Göster" : "Eski Koristleri Gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {(sadeceKendisiMi || !altSezonlarKullanilsin) ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "10px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={altSezonlariFiltrele} onChange={(e) => setAltSezonlariFiltrele(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{altSezonlariFiltrele ? "Alt Sezonlara göre filtreleme yap" : "Alt Sezonlara göre filtreleme yapma"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {(!altSezonlariFiltrele || !seciliSezon || seciliSezon.altSezonlar.length === 0) ? null :
                                <Cell style={{ flexGrow: 0, minWidth: "250px", marginBottom: "10px" }}>
                                    <VatanAltSezonMultiSelectField
                                        sticky
                                        style={{ height: "57px" }}
                                        isItemInactive={() => true}
                                        showDiger
                                        chipColorOverride={e => BusinessHelper.Sezon.GetAltSezonColor(e.id, tumSezonlar)}
                                        value={seciliAltSezonIdList}
                                        label="Alt Sezon"
                                        tumAltSezonlar={seciliSezon.altSezonlar}
                                        onChange={value => setSeciliAltSezonIdList(value.orderBy(e => e === "-" ? "" : seciliSezon.altSezonlar.single(t => t.id === e).isim))}
                                    />
                                </Cell>
                            }
                            {(!altSezonlariFiltrele || !seciliSezon || seciliSezon.altSezonlar.length === 0) ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "10px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={filtrelenenCalismalariGoster} onChange={(e) => setFiltrelenenCalismalariGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{filtrelenenCalismalariGoster ? "Filtrelenen çalışmaları göster" : "Filtrelenen çalışmaları gizle"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {!yoklamaYonetimYetkisi ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "10px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Switch checked={detayVeWhatIfGoster} onChange={(e) => setDetayVeWhatIfGoster(e.target.checked)} />
                                        <Typography sx={{ fontSize: 12, width: "100px" }}>{detayVeWhatIfGoster ? "Detay ve Ek Hesap göster" : "Detay ve Ek Hesap gösterme"}</Typography>
                                    </Columns>
                                </FormControl>
                            }
                            {(!yoklamaYonetimYetkisi || !detayVeWhatIfGoster) ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", width: "120px", marginBottom: "10px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <VatanNumericField label="Eklenecek Gün" value={whatIfGunSayisi} onChange={e => setWhatIfGunSayisi(e ?? 0)} />
                                    </Columns>
                                </FormControl>
                            }
                            <Cell flex></Cell>
                            {(!yoklamaYonetimYetkisi) ? null :
                                <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px", width: "120px", marginBottom: "10px" }}>
                                    <Columns style={{ alignItems: "center" }}>
                                        <Button onClick={() => kopyala()}>{genelDurumHelper.translate("Kopyala")}</Button>
                                    </Columns>
                                </FormControl>
                            }
                        </Columns>
                    </VatanLightCollapsableContainer>
                </Rows>

                {mainData.rows.length === 0 ? <MyText header italic style={{ marginLeft: "10px", marginTop: "10px", minHeight: 0 }} >{genelDurumHelper.translate("Gösterilecek veri bulunmamaktadır")}</MyText> :
                    <CizelgeTable data={mainData} styles={sadeceKendisiMi ? mainStylesTekKisi : mainStyles} />
                }
            </Rows>

        </MainLayout>
    );
}

export default YoklamaCizelgeScreen;

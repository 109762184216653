/* eslint-disable @typescript-eslint/no-empty-function */
// cihan
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface ICustomAlertDialogProps {
  titleMessage: string;
  contentMessage: string;
  open: boolean;
  onClose: (() => void) | null;
}

export default function CustomAlertDialog(props: ICustomAlertDialogProps) {
  return props.open === true ? (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={props.onClose != null ? props.onClose : (() => { })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.titleMessage}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.contentMessage.split("\n").map((e, i) => (
            <span key={i}>{e}<br /></span>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose != null ? props.onClose : (() => { })} color="primary" autoFocus>
          TAMAM
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IcerikMetaViewModel } from '../models/viewModels/IcerikMetaViewModel';
import { KullaniciKoroViewModel } from '../models/viewModels/KullaniciKoroViewModel';
import { BusinessHelper } from './BusinessHelper';
import GenelDurumHelper from './GenelDurumHelper';
import { GuidHelper } from './GuidHelper';
import JsonHelper from './JsonHelper';
import { IRequestHelper } from './RequestHelper';
import StorageHelper from './StorageHelper';

interface IIcerikDurum {
    uzanti: string,
    boyut: number,
}

export type KorocanBase64DosyaModel = { isim: string, uzanti: string, base64Icerik: string };

export default class IcerikHelper {

    private static IcerikCache: { [id: string]: IIcerikDurum; } = {}

    public static findEmbedLink(linkUrl: string): string | undefined {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = linkUrl.match(regExp);

        const result = (match && match[2].length === 11)
            ? match[2]
            : null;

        if (result === null)
            return linkUrl;
        else
            return `https://www.youtube.com/embed/${result}`;
    }

    public static UzantiGetir(filename: string) {
        const lastDotIndex = filename.lastIndexOf(".");
        const uzanti = (lastDotIndex === -1 || lastDotIndex === filename.length) ? "." : filename.substring(lastDotIndex);
        return uzanti.toLocaleLowerCase();
    }

    public static async ConvertFilesToIcerikBase64(givenFiles: FileList | File[]): Promise<KorocanBase64DosyaModel[]> {
        const fileList: File[] = [];
        for (let i = 0; i < givenFiles.length; i++)
            fileList.push(givenFiles[i]);

        const promises = fileList.select(targetFile =>
            new Promise<KorocanBase64DosyaModel>((accept, reject) => {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    let base64Content = (e.target!.result) as string;

                    const lastDotIndex = targetFile.name.lastIndexOf(".");
                    const uzanti = (lastDotIndex === -1 || lastDotIndex === targetFile.name.length) ? "." : targetFile.name.substring(lastDotIndex);

                    base64Content = base64Content.substring(base64Content.indexOf(",") + 1);
                    accept({ isim: targetFile.name, base64Icerik: base64Content, uzanti: uzanti });
                };
                reader.readAsDataURL(targetFile);
            }));

        const responses = await Promise.all(promises);

        return responses;
    }

    public static DownloadToClient(fileName: string, linkSource: string) {
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public static async IcerikGetirDirekt(guidValue: string, genelDurumHelper: GenelDurumHelper): Promise<string> {
        const metaResponse = await genelDurumHelper.requestHelper.Genel.IcerikGetir(guidValue, "");
        if (!metaResponse.successfull)
            return "";

        return metaResponse.data!.icerik!;
    }

    static IcerikMetalariHafizayaAl() {
        const infoDosyalari = StorageHelper.GetFiles(".info");
        const infoIdLer = infoDosyalari.select(e => e.substring(0, e.length - 5));

        // dosyası olanların info dosyaları ve içerikleri oku ve cache'e yaz
        for (const infoId of infoIdLer) {
            const dosyaContent = StorageHelper.LoadFile(infoId + ".info");
            const yeniIcerikModel = JsonHelper.parse<IIcerikDurum>(dosyaContent)!;

            // if (StorageHelper.FileExists(infoId + yeniIcerikModel.uzanti))
            //     yeniIcerikModel.indirildiMi = true;
            IcerikHelper.IcerikCache[infoId] = yeniIcerikModel;
        }
    }

    public static async DownloadIcerikMetaAsync(metaIdList: string[], requestHelper: IRequestHelper): Promise<boolean> {
        metaIdList = metaIdList.where(e => StorageHelper.FileExists(e + ".info") === false);
        if (metaIdList.length === 0)
            return true;

        const metaResponse = await requestHelper.Genel.IcerikMetaListGetir(metaIdList);
        if (!metaResponse.successfull)
            return false;

        metaResponse.data!.forEach(e => {
            const meta: IIcerikDurum = {
                uzanti: e.uzanti,
                //aciklama: e.aciklama,
                boyut: e.boyut,
            }

            IcerikHelper.IcerikCache[e.id] = meta;

            StorageHelper.SaveFile(e.id + ".info", JsonHelper.stringify(meta));
        });

        return true;
    }

    public static async GetAudioSourceFromBlob(content: Blob, extension: string) {
        const base64Content = await IcerikHelper.GetBase64FromBlob(content);
        return IcerikHelper.GetAudioSourceFromBase64Content(base64Content, extension);
    }

    public static async GetImageSourceFromBlob(content: Blob) {
        const base64Content = await IcerikHelper.GetBase64FromBlob(content);
        return IcerikHelper.GetImageSourceFromBase64Content(base64Content)
    }

    public static async GetBase64FromBlob(content: Blob) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(content);
            reader.onload = function () {
                const result = reader.result as string;
                const commaIndex = result.indexOf(",");
                const actualBase64 = result.substring(commaIndex + 1);
                resolve(actualBase64);
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    }

    public static GetAudioSourceFromBase64Content(base64Content: string, extension: string) {
        const audioType = extension.startsWith(".mid") ? "midi" : extension.substring(1);
        return `data:audio/${audioType};base64,${base64Content}`;
    }

    public static GetPdfSourceFromBase64Content(base64Content: string) {
        return "data:application/pdf;base64," + base64Content;
    }

    public static GetImageSourceFromBase64Content(base64Content: string) {
        if (GuidHelper.IsEmpty(base64Content) === true)
            return null;

        return { uri: "data:image/png;base64," + base64Content }
    }

    public static async IcerikSil(id: string): Promise<void> {
        if (GuidHelper.IsEmpty(id))
            return;

        await StorageHelper.DeleteFile(id + IcerikHelper.IcerikCache[id].uzanti);
        await StorageHelper.DeleteFile(id + ".info");

        delete IcerikHelper.IcerikCache[id];
    }

    public static async AddNewIcerik(guidValue: string, uzanti: string, icerik: string) { // aciklama: string
        const sadeModel: IIcerikDurum = {
            uzanti: uzanti,
            //aciklama: aciklama,
            boyut: icerik.length,
            //indirildiMi: true,
        }

        const stringified = JsonHelper.stringify(sadeModel);
        await StorageHelper.SaveFile(guidValue + ".info", stringified);
        await StorageHelper.SaveFileBase64(guidValue + uzanti, icerik);
        IcerikHelper.IcerikCache[guidValue] = sadeModel;
    }

    // Private Methods

    // private static async ErisimTarihGuncelle(guidValue: string): Promise<any> {
    //     let filename = guidValue + ".info";
    //     let dosyaContent = await StorageHelper.LoadFile(filename);
    //     let yeniIcerikModel = JsonHelper.parse<IIcerikDurum>(dosyaContent)!;

    //     yeniIcerikModel.erisimTarih = new Date();
    //     await StorageHelper.SaveFile(filename, JsonHelper.stringify(yeniIcerikModel));
    // }

    public static IcerikDurumGetir(guidValue: string): IIcerikDurum {
        return IcerikHelper.IcerikCache[guidValue];
    }

    public static IcerikMetaGetir(guidValue: string): IcerikMetaViewModel {
        const meta = IcerikHelper.IcerikCache[guidValue];
        const result: IcerikMetaViewModel = {
            //aciklama: meta.aciklama,
            boyut: meta.boyut,
            id: guidValue,
            uzanti: meta.uzanti,
        }

        return result;
    }

    // Boyutsallar

    public static async TumDosyalarBoyutGetir(korolar: KullaniciKoroViewModel[]) {
        const infos = await StorageHelper.GetAllFileInfos();

        const result = {
            kullanilanToplamHafiza: 0,
            gereksizIcerik: 0,
            sonBirAyErisilmeyenIcerik: 0,
            sesKayitIcerik: 0
        };

        const kullanilanIcerikIdLer = BusinessHelper.Icerik.KullanilanIcerikIdleriGetir(korolar);
        const kullanilanSesKayitIcerikIdLer = BusinessHelper.Icerik.SesKayitIcerikleriGetir(korolar);

        for (const item of infos) {
            const filename = StorageHelper.GetFileNameWithoutExtesion(item.name);

            if (GuidHelper.IsValidGuid(filename) === false)
                continue;

            result.kullanilanToplamHafiza += Number(item.size);

            if (kullanilanIcerikIdLer.contains(filename) === false)
                result.gereksizIcerik += Number(item.size);
            if (kullanilanSesKayitIcerikIdLer.contains(filename) === false)
                result.sesKayitIcerik += Number(item.size);
            // if (IcerikHelper.IcerikDurumGetir(filename).erisimTarih < DateTimeHelper.addMinutes(new Date(), -30))
            //     result.sonBirAyErisilmeyenIcerik += Number(item.size);
        }

        return result;
    }

    public static ConvertNumberToSizeString(value: number): string {
        if (value < 1024)
            return `${Math.floor(value).toLocaleString('en-US', { maximumFractionDigits: 0 })} bytes`;
        else
            return `${(value / 1024).toLocaleString('en-US', { maximumFractionDigits: 0 })} Kb`;
    }

    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    public static ResizeDataURL(baseUri: string, wantedWidth: number) {
        const response = new Promise<string>((resolve, reject) => {
            const img = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = function () {
                // We create a canvas and get its context.
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const ratio = img.height / img.width;
                const wantedHeight = Math.floor(wantedWidth * ratio);
                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx!.drawImage(img, 0, 0, wantedWidth, wantedHeight);

                const dataURI = canvas.toDataURL();
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = baseUri;
        })
        // We create an image to receive the Data URI

        return response;
    }
}